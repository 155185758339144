import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import DateConverter from './DateConverter';
import Wine from './Wine';

@JsonObject
export default class WineListItem extends Assignable<WineListItem> implements JsonSerializable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('wineId', Number, false)
    wineId?: number = undefined;

    @JsonProperty('wineListId', Number, true)
    wineListId?: number = undefined;

    @JsonProperty('comment', String, true)
    comment?: string = undefined;

    @JsonProperty('created', DateConverter, true)
    created?: Date = undefined;

    @JsonProperty('modified', DateConverter, true)
    modified?: Date = undefined;

    @JsonProperty('sortOrder', Number, true)
    sortOrder?: number = undefined;

    @JsonProperty('wine', Wine, true)
    wine?: Wine = undefined;

    static fromJson(data: any): WineListItem {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, WineListItem) as WineListItem;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

}
