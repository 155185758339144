// @ts-nocheck 
import AsyncStorage from './AsyncStorage';
import { UserTokenKey } from './BaseService';

export function RequireAuthentication<T extends any>(target: any,
                                                     propertyKey: string | symbol,
                                                     descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<T>>)
    : TypedPropertyDescriptor<(...args: any[]) => Promise<T>> {

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                await AsyncStorage.getItem(UserTokenKey);
                try {
                    const result = await originalMethod!.apply(this, args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
                resolve();
            } catch (_) {
                reject(new Error('Unauthorized'));
            }
        });
    };

    return descriptor;
}