import * as React from 'react';
import '../../styles/StockButton.scss';
import '../../styles/ActionButton.scss';
import { StockIcon2 } from '../icons/Icons';

type Props = {
    isSelected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    count?: number;
};

export default class StockButton extends React.PureComponent<Props> {

    render () {
        const {count = 0, onClick, ...rest} = this.props;
       
        return (
            <button {...rest} 
                className={`action-button stock-button ${(count > 0) ? 'selected' : ''}`} 
                onClick={() => onClick && onClick()}>
                <figure className={'stock-button--icon'}>
                    <span>{count}</span>
                    <StockIcon2  color={(count === 0) ? 'transparent' : '#E95003'}/>
                </figure>
    
                <span className={'action-button--label'}>{'Har hemma'}</span>
            </button>
        );
    }
}