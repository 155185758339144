import WineNote from './WineNote';
import WineListViewModel from './WineListViewModel';
import User from './User';
import Wine from './Wine';
import WineRating from './WineRating';
import WineStock from './WineStock';
import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import WineListItem from './WineListItem';

@JsonObject
export default class WineCombined extends Assignable<WineCombined> implements JsonSerializable {
    @JsonProperty('wine', Wine, true)
    wine?: Wine = undefined;

    @JsonProperty('user', User, true)
    user?: User = undefined;

    @JsonProperty('winelists', WineListViewModel, true)
    winelists?: WineListViewModel = undefined;

    @JsonProperty('note', WineNote, true)
    note?: WineNote = undefined;

    @JsonProperty('rating', WineRating, true)
    rating?: WineRating = undefined;

    @JsonProperty('stock', WineStock, true)
    stock?: WineStock = undefined;

    @JsonProperty('isSaved', Boolean, true)
    isSaved?: boolean = false;

    @JsonProperty('isRated', Boolean, true)
    isRated?: boolean = false;

    @JsonProperty('isStocked', Boolean, true)
    isStocked?: boolean = false;

    @JsonProperty('savedListItem', WineListItem, true)
    savedListItem?: WineListItem = undefined;

    @JsonProperty('stockedListItem', WineListItem, true)
    stockedListItem?: WineListItem = undefined;

    @JsonProperty('ratedListItem', WineListItem, true)
    ratedListItem?: WineListItem = undefined;

    static fromJson(data: any): WineCombined {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, WineCombined) as WineCombined;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}
