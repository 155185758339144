import * as React from 'react';
import '../../styles/HamburgerMenu.scss';

type Props = {
    onClick: () => void;
    isActive: boolean;
};

export default class HamburgerMenu extends React.PureComponent<Props> {
    render() {
        const {onClick, isActive} = this.props;
        return (
            <div className={`hamburger-menu ${isActive ? 'is-active' : ''}`}
                 id={'hamburger-navigation'}
                 onClick={() => onClick()}>
                <div className="hamburger-menu--box">
                    <div className="hamburger-menu--box--inner"/>
                </div>
            </div>
        );
    }
}