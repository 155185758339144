//@ts-nocheck
import * as React from "react";
import User from "./models/User";
import withAuthentication, { AuthenticationProps } from "./WithAuthentication";
import Button from "./components/buttons/Button";
import userService from "./services/UserService";
import AccountModal from "./account/AccountModal";
import { RouteComponentProps, withRouter } from "react-router";
import Routes from "./Routes";
import { Link } from "react-router-dom";
// @ts-ignore
import URLSearchParams from "@ungap/url-search-params";
import { AccountModalScreens } from "./account/AccountModalContext";

export interface AuthUserContextProps {
  user: User | null;
  accountModalVisible: boolean;
  accountModalScreen: AccountModalScreens;
  accountModalData?: any;
  showAccountModal: (screen: AccountModalScreens, data?: any) => void;
  hideAccountModal: () => void;
}

const AuthUserContext = React.createContext<AuthUserContextProps>({
  user: null,
  accountModalVisible: false,
  accountModalScreen: "login",
  showAccountModal: (screen: AccountModalScreens) => {
    /**/
  },
  hideAccountModal: () => {
    /**/
  },
});

export default AuthUserContext;

export class LoginButton extends React.Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUserContext) => (
          <Button
            label={"Logga in"}
            theme={"white"}
            trim={true}
            onClick={() => authUserContext.showAccountModal("login")}
          />
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export class LogoutButton extends React.Component {
  onLogout = () => {
    userService.logout();
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUserContext) => (
          <Button
            label={"Logga ut"}
            theme={"pink"}
            trim={true}
            onClick={() => {
              authUserContext.hideAccountModal();
              this.onLogout();
            }}
          />
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export const AccountNavigation = withRouter(
  class extends React.PureComponent<RouteComponentProps> {
    isActive = (route: string): boolean => {
      const { location } = this.props;

      if (route === Routes.HOME) {
        return location.pathname === route;
      }

      return location.pathname.startsWith(route);
    };

    render() {
      // Fishy bug! If the siblings for navigations has the same tag names, react seems to break it by
      // making duplicates of the same tag. Therefore this is a span and the main nav is a <nav>
      return (
        <span className={"App-nav App-nav-account"} key={"account-navigation"}>
          <AuthUserContext.Consumer>
            {(authUserContext) =>
              authUserContext.user ? (
                <Link
                  to={Routes.SETTINGS}
                  className={`link--caret-down ${
                    this.isActive(Routes.SETTINGS) ? "active" : ""
                  }`}
                >
                  {authUserContext.user.email!.match(/.+(?=@)/)}
                </Link>
              ) : (
                <LoginButton />
              )
            }
          </AuthUserContext.Consumer>
        </span>
      );
    }
  }
);

export const AuthUserContextProvider = withAuthentication(
  withRouter(
    class extends React.Component<
      AuthenticationProps & RouteComponentProps,
      Partial<AuthUserContextProps>
    > {
      constructor(props: AuthenticationProps & RouteComponentProps) {
        super(props);

        const params = new URLSearchParams(props.location.search);

        this.state = {
          user: props.authUser,
          accountModalVisible: params.get("accountModal") !== null,
          accountModalScreen:
            (params.get("accountModal") as AccountModalScreens) || "login",
        };
      }

      componentWillReceiveProps(
        nextProps: Readonly<AuthenticationProps & RouteComponentProps>,
        nextContext: any
      ): void {
        if (nextProps.authUser) {
          this.hideAccountModal();
        }

        this.setState({
          user: nextProps.authUser,
        });
      }

      showAccountModal = (
        screen: AccountModalScreens = "login",
        data?: any
      ) => {
        this.setState({
          accountModalData: data,
          accountModalVisible: true,
          accountModalScreen: screen,
        });
      };

      hideAccountModal = () => {
        this.setState({ accountModalVisible: false });
      };

      render() {
        const { children } = this.props;
        const {
          user,
          accountModalVisible = false,
          accountModalScreen = "login",
          accountModalData,
        } = this.state;

        return (
          <AuthUserContext.Provider
            value={{
              user: user || null,
              accountModalVisible: accountModalVisible,
              accountModalScreen: accountModalScreen,
              accountModalData: accountModalData,
              showAccountModal: this.showAccountModal,
              hideAccountModal: this.hideAccountModal,
            }}
          >
            {children}

            <AccountModal
              mode={accountModalScreen}
              data={accountModalData}
              visible={accountModalVisible}
              onCancel={this.hideAccountModal}
            />
          </AuthUserContext.Provider>
        );
      }
    }
  ),
  false
);
