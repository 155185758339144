import * as React from 'react';
import Modal from '../components/modal/Modal';
import { StarIcon } from '../components/icons/Icons';

interface Props {
    isOpen: boolean;
    score?: number;
    onSave: (score: number | undefined) => void;
}

interface State {
    score?: number;
}

class RateModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            score: props.score
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.state.score !== this.props.score) {
            this.setState({
                score: this.props.score
            });
        }
    }

    onSetScore = (score: number) => {
        this.setState(prevState => ({
            score: score === 1 && prevState.score === 1 ? 0 : score
        }));
    }

    render() {
        const {isOpen, onSave} = this.props;
        const {score} = this.state;

        return (
            <Modal isOpen={isOpen} buttons={[
                {label: 'Spara', style: 'primary', onClick: () => onSave(score)}
            ]}>
                <div style={{
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    padding: '30px 40px 40px',
                    marginBottom: 10,
                    borderRadius: 4
                }}>
                    <h3>Vad tycker du om vinet?</h3>
                    <div style={{display: 'inline-block'}}>
                        {this._renderScore(score)}
                    </div>
                </div>
            </Modal>
        );
    }

    _renderScore = (count: number = 0, color: string = '#FF691E') => {
        const maxCount: number = 5;
        const list: any[] = [];

        for (let i = 0; i < count; i++) {
            list.push(
                // eslint-disable-next-line no-loop-func
                <div onClick={() => this.onSetScore(i + 1)}
                     key={`star-${i}`}>
                    <StarIcon
                        filled={true}
                        color={color}/>
                </div>
            );
        }
        for (let i = count; i < maxCount; i++) {
            list.push(
                // eslint-disable-next-line no-loop-func
                <div onClick={() => this.onSetScore(i + 1)}
                     key={`star-${i}`}>
                    <StarIcon
                        filled={true}
                        color={'#c8c8c8'}/>
                </div>
            );
        }
        return (<div style={{display: 'flex', flexDirection: 'row'}}>{list}</div>);
    }
}

export default RateModal;
