import * as React from 'react';
import '../../../styles/WineType.scss';
import Bubbles from './bubbles.svg';

type Props = {
    type?: string;
    style?: string;
    size?: number;
};

export function wineColor(type?: string): string | undefined {
    switch (type) {
        case 'Rött vin':
        case 'Rött':
        case 'Röda':
            return 'red';
        case 'Vitt vin':
        case 'Vitt':
        case 'Vita':
        case 'Vitt Sött':
        case 'Vitt Halvtorrt':
        case 'Vitt Torrt':
            return 'white';
        case 'Bubbel':
        case 'Blå mousserande':
        case 'Mousserande vin':
            return 'sparkling';
        case 'Rosé':
        case 'Rosévin':
            return 'rose';
        case 'Aperitif och dessert':
        case 'Aperitif':
        case 'Mingel':
            return 'aperitif';
        default:
            return undefined;
    }
}

export function wineColorHex(type?: string): string | undefined {
    switch (type) {
        case 'Rött vin':
        case 'Rött':
        case 'Röda':
            return '#961e1e';
        case 'Vitt vin':
        case 'Vitt':
        case 'Vita':
        case 'Vitt Sött':
        case 'Vitt Halvtorrt':
        case 'Vitt Torrt':
            return '#ffd700';
        case 'Blå mousserande':
        case 'Mousserande vin':
        case 'Bubbel':
            return '#ffd700';
        case 'Rosé':
        case 'Rosévin':
            return '#ffc8af';
        case 'Aperitif och dessert':
        case 'Aperitif':
        case 'Starkvin':
        case 'Mingel':
            return '#E97D4C';
        default:
            return undefined;
    }
}

const WineTypeIcon = (props: Props) => {
    const {type, style, size} = props;
    let colorClass = wineColor(type);
    let isSparkling: boolean = colorClass === 'sparkling';

    if (isSparkling && !!style) {
        colorClass = wineColor(style);
    }

    return (<figure className={`icon-wine-type type-${colorClass}`}
                    style={size ? {width: size, height: size} : undefined}>
        {isSparkling &&
        <img src={Bubbles} alt={'Bubblor'}/>
        }

    </figure>);
};

export default WineTypeIcon;