import * as React from 'react';
import Chevron from '../../Chevron';
import '../../styles/Button.scss';

type ThemeType = 'white' | 'pink' | 'green' | 'orange';
type TextAlignType = 'right' | 'center' | 'left';
type SizeType = 'default' | 'small' | 'tiny';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string;
    icon?: any;
    useCaret?: boolean;
    theme?: ThemeType;
    textAlign?: TextAlignType;
    trim?: boolean;
    // small?: boolean;
    size?: SizeType;
    disabled?: boolean;
    className?: any;
    badge?: number;
    onClick?: (e: any) => void;
}

const Button = (props: ButtonProps) => {
    let {
        label, icon, useCaret, theme = 'white', textAlign = 'center', onClick, trim = false, className, size = 'default',
        badge,
        //  small = false,
        ...rest
    } = props;

    const caretColor: any = {
        white: '#E95003',
        pink: '#fff',
        green: '#fff',
    };

    return (
        <button {...rest}
                className={
                    `button theme-${theme} align-${textAlign} ${(trim) ? ' trim' : ''} ${(size === 'default') ? ' ' : size}
            ${(className) ? className : ''}`}
                onClick={(e) => onClick && onClick(e)}>
            {icon &&
            <figure className={'button--icon'}>
                {React.cloneElement(
                    icon,
                    {color: caretColor[theme]}
                )}
            </figure>
            }
            <span className={'button--label'}>{label ? label : props.children}</span>
            {(badge !== undefined) &&
            <span className={'button--badge'}>{badge}</span>
            }
            {useCaret &&
            <span className={'button--caret'}><Chevron color={caretColor[theme]}/></span>
            }
        </button>
    );
};
export default Button;