import * as React from 'react';
import Modal from '../modal/Modal';
import { EmailMessage, shareService } from '../../services/ShareService';
import RoundButton from '../buttons/RoundButton';
import { CopyIcon, EmailIcon, FBIcon } from '../icons/Icons';
import '../../styles/ShareModal.scss';
import Tooltip from 'reactstrap/lib/Tooltip';
import ReactGA from 'react-ga';

type Props = {
    isOpen: boolean;
    onClosed?: () => void;
    // children?: React.ReactNode;
    emailMessage?: EmailMessage;
    shareUrl?: string;
    title?: string;
};

type State = {
    showTooltip: boolean;
};

class ShareModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showTooltip: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.isOpen && !prevProps.isOpen) {
            ReactGA.modalview('/share/');
        }
    }

    copyLink() {
        shareService.clipboard(this.props.shareUrl);
        this.setState({showTooltip: true}, () => {
            setTimeout(() => {
                this.setState({showTooltip: false});
            }, 1000);
        });
    }

    render() {
        const {
            isOpen, title = 'Dela', onClosed, emailMessage = {
                subject: 'Vintips från Vinkompassen',
                message: 'Någon vill tipsa dig... ',
            }, shareUrl } = this.props;

        const {showTooltip} = this.state;
        return (

            <Modal
                isOpen={isOpen}
                className={'share-modal'}
                buttons={[
                    {label: 'Stäng', style: 'primary', onClick: () => onClosed && onClosed()},
                ]}>
                <div className={'share-modal--container'}>
                    <h2>{title}</h2>
                    <div style={{
                        justifyContent: 'space-around',
                        display: 'flex',
                    }}>
                        <RoundButton icon={FBIcon} label={'Facebook'} onClick={() => shareService.facebook(shareUrl)}/>
                        <RoundButton icon={EmailIcon} label={'E-post'} onClick={() =>
                            shareService.email(emailMessage, shareUrl)}/>
                        <span id={'share-clipboard-button'}>
                            <RoundButton icon={CopyIcon} label={'Kopiera länk'} onClick={() => this.copyLink()}/>
                        </span>
                        <Tooltip placement="top"
                                 isOpen={showTooltip}
                            // delay={{ show: 3000, hide: 500 }}
                                 target="share-clipboard-button">
                            Länken är kopierad.
                        </Tooltip>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ShareModal;