import * as React from 'react';
import '../../styles/Badge.scss';

type Props = {
    value?: number | string;
    invert?: boolean;
};

const Badge = ({value = undefined, invert = false}: Props) => {
    if (!value) {
        return null;
    }

    return (
        <div className={`badge ${(invert) ? 'inverted' : ''}`} >
            <span className={'badge--text'} style={{color: (!invert) ?  '#fff' : '#FF691E'}}>{value.toString()}</span>
        </div>);
};

export default Badge;