import * as React from 'react';
import Chevron from '../../Chevron';
import '../../styles/SimpleButton.scss';

export type CaretType = 'none' | 'right' | 'left' | 'up' | 'down';

type Props = {
    label: string;
    caret?: CaretType;
    onClick?: (e: any) => void;
};

const SimpleButton = (props: Props) => {
    const {label, caret = 'right', onClick} = props;

    return (
        <button className={`simple-button caret-type-${caret}`}
                onClick={(e) => onClick && onClick(e)}>
            {label}
            <Chevron/>
        </button>);
};
export default SimpleButton;