import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import { Ownable } from './Ownable';
import User from './User';
import DateConverter from './DateConverter';
import { buildWebPath } from '../vinkompassen-config';
import { createWineListLink } from '../Routes';
import { WineListType } from '../services/WineListService';

@JsonObject
export default class WineList extends Assignable<WineList> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('userId', Number, true)
    userId?: number = undefined;

    @JsonProperty('type', String, true)
    type?: WineListType = undefined;

    @JsonProperty('name', String, false)
    name?: string = undefined;

    @JsonProperty('description', String, true)
    description?: string = undefined;

    @JsonProperty('isPublic', Boolean, true)
    isPublic?: boolean = false;

    @JsonProperty('created', DateConverter, true)
    created?: Date = undefined;

    @JsonProperty('modified', DateConverter, true)
    modified?: Date = undefined;

    @JsonProperty('publishDate', DateConverter, true)
    publishDate?: Date | null = undefined;

    @JsonProperty('unpublishDate', DateConverter, true)
    unpublishDate?: Date | null = undefined;

    @JsonProperty('wineCount', Number, true)
    wineCount?: number = 0;

    @JsonProperty('followers', Number, true)
    followers?: number = 0;

    @JsonProperty('likes', Number, true)
    likes?: number = 0;

    @JsonProperty('sortOrder', Number, true)
    sortOrder?: number = 0;

    @JsonProperty('imageId', String, true)
    imageId?: string = undefined;

    @JsonProperty('imageUrl', String, true)
    imageUrl?: string = undefined;

    @JsonProperty('largeImageId', String, true)
    largeImageId?: string = undefined;

    @JsonProperty('largeImageUrl', String, true)
    largeImageUrl?: string = undefined;

    @JsonProperty('virtualShareUrl', String, true)
    virtualShareUrl?: string = undefined;

    static fromJson(data: any): WineList {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, WineList) as WineList;
    }

    public get isPublished(): boolean {
        if (!!this.publishDate) {
            const now = new Date();
            return this.publishDate <= now && (!this.unpublishDate || this.unpublishDate >= now);
        }

        return false;
    }

    public get hasExpired(): boolean {
        if (!!this.unpublishDate) {
            const now = new Date();
            return this.unpublishDate < now;
        }

        return false;
    }

    public get isScheduled(): boolean {
        if (!!this.publishDate) {
            const now = new Date();
            return this.publishDate > now;
        }

        return false;
    }

    shareUrl(): string {
        return buildWebPath(createWineListLink(this));
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

    isOwner(user?: User | null): boolean {
        return (user && (this.userId === user.id)) || false;
    }
}
