import * as React from 'react';
import { BigFlagIcon } from '../components/icons/flags/Flags';
import RegionTextsService from '../services/CountryRegionService';
import CountryRegion from '../models/CountryRegion';
import '../styles/CountryInfo.scss';

type Props = {
    country?: string;
};
type State = {
    countryList: CountryRegion[];
    content?: string;
};

class CountryInfo extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            countryList: [],
        };
    }

    componentDidMount() {
        this.fetchCountryAndRegion();
    }

    fetchCountryAndRegion = async () => {
        try {
            const data = await RegionTextsService.getContent(this.props.country, undefined);
            this.setState({
                countryList: data
            });
        } catch (error) {
            console.log('error ', error);
        }
    }

    _renderText(content?: string) {
        if (content) {
            return content.split('\n\n').map((p, index) => {
                return (<p key={index}>{p}</p>);
            });
        }
    }

    render() {
        const {country = 'default'} = this.props;
        const {countryList} = this.state;
        let content = countryList.find(item => item.country === country);

        if (countryList.length === 0) {
            return null;
        }

        if (!content) {
            content = countryList[0];
        }
        
        const backgroundImage = content.landscapeImageUrls && content.landscapeImageUrls[0];

        return (<section className={'country--info'} style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className={'country--info--content'}>
                <BigFlagIcon country={country}/>
                <h4>{country || ''}</h4>
                {this._renderText(content.content)}
            </div>
        </section>);
    }
}

export default CountryInfo;