import { Any, JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import Wine from './Wine';

export enum WineDataRanking {
    ignored = -2,
    bad = -1,
    ok = 0,
    good = 1
}

export function rankingColor(ranking: WineDataRanking): string {
    let color: string = '';

    switch (ranking) {
        case WineDataRanking.bad:
            color = '#ff6666';
            break;
        case WineDataRanking.good:
            color = '#66ff66';
            break;
        case WineDataRanking.ignored:
            color = '#999999';
            break;
        default:
            color = '#FFEB3B';
            break;
    }

    return color;
}

@JsonObject
export default class WineStatistic extends Assignable<WineStatistic> implements JsonSerializable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('name', String, false)
    name?: string = undefined;

    @JsonProperty('name2', String, true)
    name2?: string = undefined;

    @JsonProperty('importer', String, true)
    importer?: string = undefined;

    @JsonProperty('scraper', String, true)
    scraper?: string = undefined;

    @JsonProperty('wine', Any, true)
    wine?: Wine = undefined;

    @JsonProperty('hasImage', Boolean, true)
    hasImage?: boolean = undefined;

    @JsonProperty('hasLargeImage', Boolean, true)
    hasLargeImage?: boolean = undefined;

    @JsonProperty('hasPairings', Boolean, true)
    hasPairings?: boolean = undefined;

    @JsonProperty('hasPackaging', Boolean, true)
    hasPackaging?: boolean = undefined;

    @JsonProperty('hasGrapes', Boolean, true)
    hasGrapes?: boolean = undefined;

    @JsonProperty('hasAlcoholContent', Boolean, true)
    hasAlcoholContent?: boolean = undefined;

    @JsonProperty('hasCharacteristics', Boolean, true)
    hasCharacteristics?: boolean = undefined;

    @JsonProperty('hasAdditionalInfo', Boolean, true)
    hasAdditionalInfo?: boolean = undefined;

    @JsonProperty('hasStyle', Boolean, true)
    hasStyle?: boolean = undefined;

    @JsonProperty('hasType', Boolean, true)
    hasType?: boolean = undefined;

    @JsonProperty('hasLabel', Boolean, true)
    hasLabel?: boolean = undefined;

    @JsonProperty('ranking', Number, true)
    ranking?: WineDataRanking = undefined;

    // public get ranking(): WineDataRanking {
    //     if (this.isIgnored) {
    //         return WineDataRanking.ignored;
    //     }
    //
    //     if (this.hasLargeImage &&
    //         this.hasPackaging &&
    //         this.hasPairings &&
    //         this.hasGrapes &&
    //         this.hasCharacteristics &&
    //         this.hasType) {
    //         return WineDataRanking.good;
    //     }
    //
    //     if (this.hasImage && this.hasType && this.hasPairings) {
    //         return WineDataRanking.ok;
    //     }
    //
    //     return WineDataRanking.bad;
    // }

    static fromJson(data: any): WineStatistic {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, WineStatistic) as WineStatistic;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}