import * as React from 'react';
import '../../../styles/Flags.scss';

type Props = {
    country?: string;
};
export const SmallFlagIcon = (props: Props) => {
    const {country} = props;
    return (
        <img src={flagImage(country)} alt={country} className={'icon-small-flag'}/>
    );
};

export const BigFlagIcon = (props: Props) => {
    const {country} = props;
    return (
        <img src={flagImage(country)} alt={country} className={'icon-big-flag'}/>
    );
};

export function flagImage(country?: string): any {
    switch (country) {
        case 'Argentina':
            return require(`./images/Argentina.png`);
        case 'Australien':
            return require(`./images/Australia.png`);
        case 'Österrike':
            return require(`./images/Austria.png`);
        case 'Brasilien':
            return require(`./images/Brazil.png`);
        case 'Kanada':
            return require(`./images/Canada.png`);
        case 'Chile':
            return require(`./images/Chile.png`);
        case 'Tjeckien':
            return require(`./images/Czech-republic.png`);
        case 'Frankrike':
            return require(`./images/France.png`);
        case 'Tyskland':
            return require(`./images/Germany.png`);
        case 'Grekland':
            return require(`./images/Greece.png`);
        case 'Israel':
            return require(`./images/Israel.png`);
        case 'Italien':
            return require(`./images/Italy.png`);
        case 'Portugal':
            return require(`./images/Portugal.png`);
        case 'Rumänien':
            return require(`./images/Romania.png`);
        case 'Spanien':
            return require(`./images/Spain.png`);
        case 'Schweiz':
            return require(`./images/Switzerland.png`);
        case 'USA':
            return require(`./images/United-States.png`);

        // New ones...
        case 'Sydafrika':
            return require(`./images/South-Africa.png`);
        case 'Nya Zeeland':
            return require(`./images/New-Zealand.png`);
        case 'Ungern':
            return require(`./images/Hungary.png`);
        case 'Sverige':
            return require(`./images/Sweden.png`);
        case 'Kroatien':
            return require(`./images/Croatia.png`);
        case 'Ryssland':
            return require(`./images/Russia.png`);
        case 'Republiken Makedonien':
            return require(`./images/Republic-of-Macedonia.png`);
        case 'Bulgarien':
            return require(`./images/Bulgaria.png`);
        case 'Libanon':
            return require(`./images/Lebanon.png`);
        case 'Georgien':
            return require(`./images/Georgia.png`);
        case 'Storbritannien':
            return require(`./images/United-Kingdom.png`);
        case 'Armenien':
            return require(`./images/Armenia.png`);
        case 'Moldavien':
            return require(`./images/Moldova.png`);
        case 'EU':
            return require(`./images/European-union.png`);
        case 'Slovenien':
            return require(`./images/Slovenia.png`);
        case 'Serbien':
            return require(`./images/Serbia.png`);
        case 'Mexiko':
            return require(`./images/Mexico.png`);
        case 'Uruguay':
            return require(`./images/Uruguay.png`);
        case 'Danmark':
            return require(`./images/Denmark.png`);
        case 'Indien':
            return require(`./images/India.png`);
        case 'Luxemburg':
            return require(`./images/Luxembourg.png`);
        case 'Montenegro':
            return require(`./images/Montenegro.png`);
        case 'Tunisien':
            return require(`./images/Tunisia.png`);
        case 'Cypern':
            return require(`./images/Cyprus.png`);
        case 'Japan':
            return require(`./images/Japan.png`);
        case 'Lettland':
            return require(`./images/Latvia.png`);
        case 'Marocko':
            return require(`./images/Morocco.png`);
        case 'Slovakien':
            return require(`./images/Slovakia.png`);
        case 'Turkiet':
            return require(`./images/Turkey.png`);
        case 'Irland':
            return require(`./images/Ireland.png`);
        case 'Finland':
            return require(`./images/Finland.png`);
        case 'Folkrepubliken Kina':
            return require(`./images/China.png`);
        case 'Övriga ursprung':
            return require(`./images/Other-Origins.png`);

        // Outhers
        case 'Varierande ursprung':
            return require(`./images/Varying-origins.png`);
        case 'Internationellt märke':
            return require(`./images/international.png`);
        // 
         // added 2019-05-22
        case 'Litauen':
            return require(`./images/Lithuania.png`);
        case 'Nederländerna':
            return require(`./images/Netherlands.png`);
        case 'Nordmakedonien':
            return require(`./images/North-Macedonia.png`);
        case 'Norge':
            return require(`./images/Norway.png`);
        case 'Polen':
            return require(`./images/Poland.png`);
        case 'Serbien och Montenegro':
            return require(`./images/Montenegro.png`);
        case 'Ukraina':
            return require(`./images/Ukraine.png`);
        default:
            return require(`./images/Placeholder.png`);
    }
}