import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {
    serialize(date: Date | null): any {
        if (!date) {
            return 0;
        }
        return date!.valueOf();
    }

    deserialize(value: any): Date {
        if (value < Date.parse('1980-01-01')) {
            value = value * 1000;
        }

        return new Date(value);
    }
}

export default DateConverter;