import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Chevron from './Chevron';
import EnterKeyHandler from './EnterKeyHandler';

import './styles/ChevronLink.scss';

interface Props extends LinkProps {
    onClick?: () => void;
}

class ChevronLink extends React.PureComponent<Props> {
    render() {
        const {onClick, children, to} = this.props;

        let link: any;

        if (onClick) {
            link = (
                <span className={'chevron-link--text'}
                      role={'button'}
                      tabIndex={0}
                      onKeyPress={(e) => EnterKeyHandler(e, () => onClick && onClick())}
                      onClick={onClick}>
                {children}
            </span>
            );
        } else if (to.toString().indexOf('://') === -1) {
            link = (
                <a href={to.toString()}
                   className={'chevron-link--text'}>
                    {children}
                </a>
            );
        } else {
            link = (
                <Link {...this.props}
                      className={'chevron-link--text'}/>
            );
        }

        return (
            <span className={'chevron-link'}>
                {link}
                {' '}
                <Chevron/>
            </span>
        );
    }
}

export default ChevronLink;
