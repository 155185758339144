import * as React from 'react';
import { FormGroup, Modal as BootstrapModal, ModalBody } from 'reactstrap';
import Button from '../buttons/Button';
import '../../styles/Modal.scss';

export interface ModalButton {
    label?: string;
    onClick?: () => void;
    style?: 'default' | 'primary';
}

export interface ModalProps {
    buttons?: ModalButton[];
    isOpen: boolean;
    className?: any;
    centered?: boolean;
    children: React.ReactNode;
}

const Modal = (props: ModalProps) => {
    const {isOpen = false, centered = false, buttons = [], className, children} = props;

    const _renderButtons = buttons.map((item, index) => {
        return (
            <FormGroup key={index}>
                <Button
                    label={item.label || ''}
                    theme={(item.style === 'primary') ? 'pink' : 'white'}
                    textAlign={'center'}
                    onClick={() => item.onClick && item.onClick()}/>
            </FormGroup>
        );
    });

    return (
        <BootstrapModal
            isOpen={isOpen}
            centered={centered}
            className={className}
            aria-modal={true}
            aria-hidden={!isOpen}
            aria-labelledby={'modal-title'}
            aria-describedby={'modal-description'}>
            <ModalBody>
                {children}
                {_renderButtons}
            </ModalBody>
        </BootstrapModal>
    );
};

export default Modal;