import * as React from 'react';
import '../../styles/VideoPlayer.scss';

interface Props {
    youtubeId: string;
}

const VideoPlayer: React.FC<Props> = ({youtubeId}) => {
    return (
        <div className={'embed-container'}>
            <iframe src={`https://www.youtube.com/embed/${youtubeId}`}
                    title={'Videospelare'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </div>
    );
}

export default VideoPlayer;
