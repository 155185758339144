import WineListViewModel from './WineListViewModel';
import User from './User';
import {JsonConvert, JsonObject, JsonProperty, ValueCheckingMode} from 'json2typescript';
import {Assignable, JsonSerializable} from './Serializable';
import WineList from './WineList';

@JsonObject
export default class WineListCombined extends Assignable<WineListCombined> implements JsonSerializable {
    @JsonProperty('savedWineList', WineList, true)
    savedWineList?: WineList = undefined;

    @JsonProperty('ratedWineList', WineList, true)
    ratedWineList?: WineList = undefined;

    @JsonProperty('stockedWineList', WineList, true)
    stockedWineList?: WineList = undefined;

    @JsonProperty('scannedWineList', WineList, true)
    scannedWineList?: WineList = undefined;

    @JsonProperty('winelists', WineListViewModel, true)
    winelists?: WineListViewModel = undefined;

    @JsonProperty('followedWinelists', WineListViewModel, true)
    followedWinelists?: WineListViewModel = undefined;

    @JsonProperty('followedUsers', [User], true)
    followedUsers?: User[] = undefined;

    static fromJson(data: any): WineListCombined {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, WineListCombined) as WineListCombined;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}
