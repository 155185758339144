import * as React from 'react';
import Badge from '../components/badge/Badge';
import RoundButton from '../components/buttons/RoundButton';

type Props = {
    label: string;
    icon: any;
    value: number | string | undefined;
    onPress: () => void; 
    selected?: boolean; 
};

export default class ActionButton extends React.PureComponent<Props> {
    render() {
        const {label, icon, value, onPress, selected= false} = this.props;
        return (
            <div style={{position: 'relative', display: 'inline-block'}}>
                {value !== undefined && value !== '' && value !== 0 && <Badge value={value} invert={selected}/>}
                <RoundButton invert={selected} icon={icon} label={label} onClick={() => onPress()} />
            </div>
        );
    }
}
