import * as React from 'react';
import { withRouter } from 'react-router';
import { frontloadConnect } from 'react-frontload';
import withAuthentication from '../WithAuthentication';
import { getStateManagerState, withStateManager } from '../StateManager';
import { MyVinosProps } from './MyVinosLayout';
import AddEditWineListModal from '../winelists/add-edit-modal/AddEditWineListModal';
import WineList from '../models/WineList';
import WineListItem from '../models/WineListItem';
import WineListItemService from '../services/WineListItemService';
import EventEmitter from '../services/EventEmitter';
import WineListService from '../services/WineListService';
import { createWineLink } from '../Routes';
import { WineViewRouteState } from '../wines/WineView';
import '../styles/PrivateWineListView.scss';
import WineListItemWidget from '../winelists/WineListItemWidget';
import Wine from '../models/Wine';
import ShareModal from '../components/share-modal/ShareModal';
import DefaultMeta from '../DefaultMeta';
import { buildWebPath } from '../vinkompassen-config';

export interface WineListViewRouteState {
    winelist?: WineList;
}

type State = {
    isEditMode: boolean;
    shareModalVisible: boolean;
    winelist?: WineList;
    items?: WineListItem[];
};

export const EVENT_WINE_LIST_UPDATED: string = 'eventWineListUpdated';
export const EVENT_WINE_LIST_DELETED: string = 'eventWineListDeleted';

async function frontload(props: MyVinosProps): Promise<void> {
    //
}

class PrivateWineListView extends React.PureComponent<MyVinosProps, State> {
    constructor(props: MyVinosProps) {
        super(props);
        this.state = {
            isEditMode: false,
            shareModalVisible: false,
        };
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        const {combined} = this.props;
        if (combined && combined.winelists && combined.winelists.lists) {
            if (!id) {
                return;
            }
            this.fetchData(combined.winelists.lists, Number(id));
        }
    }

    componentWillReceiveProps(newProps: MyVinosProps) {
        const {combined} = newProps;
        const {winelist} = this.state;
        const {id} = this.props.match.params;

        // props exists
        if (combined && combined.winelists && combined.winelists.lists) {

            // no id...
            if (!id) {
                return;
            }

            // The same list...
            if (winelist && winelist.id === Number(id)) {
                return;
            }

            this.fetchData(combined.winelists.lists, Number(id));
            // this.forceUpdate();
        }
    }

    fetchData = async (propLists: WineList[], id: number) => {
        try {
            let list = propLists.find(x => x.id === Number(id));
            const items = await WineListItemService.getItems(Number(id));

            this.setState({
                winelist: list,
                items
            });
        } catch (error) {
            console.log(error);
        }
    }

    onEditClick = () => {
        this.setState({isEditMode: true});
    }

    onCancelAddEditModal = () => {
        console.log('onCancel');
        this.setState({isEditMode: false});
    }

    onSaveAddEditModal = async (updatedWineList: WineList, wineItems?: WineListItem[]) => {
        try {
            this.setState({
                isEditMode: false
            });
            // update the hole list if it has ben altered with. 
            // console.log('onSaveAddEditModal-> ', wineItems);
            if (wineItems) {
                wineItems = await WineListItemService.updateItems(updatedWineList.id!, wineItems);
            }
            const winelist = await WineListService.updateSingle(updatedWineList as WineList);

            EventEmitter.emit(EVENT_WINE_LIST_UPDATED, winelist);
            this.setState({
                winelist,
                items: (wineItems) ? wineItems : this.state.items,
            });
        } catch (error) {
            console.log(error);
        }
    }

    onPublishStateChanged = (updatedWineList: WineList) => {
        this.setState({
            winelist: updatedWineList
        });
    }

    onDeleteWinelist = async (list: WineList) => {

        try {
            this.setState({
                isEditMode: false
            });
            await WineListService.removeSingle(list);
            EventEmitter.emit(EVENT_WINE_LIST_DELETED, list.id);
            // this.props.history.push(WineListRoutes.WINELISTS_START);
        } catch (error) {
            console.log(error);
        }
    }

    onWineClick(wine: Wine) {
        this.props.history.push(
            createWineLink(wine), {
                wine: wine
            } as WineViewRouteState
        );
    }

    render() {
        const {authUser} = this.props;
        const {winelist, isEditMode, items, shareModalVisible} = this.state;

        if (!authUser) {
            return null;
        }
        if (!winelist) {
            return (<p>Laddar...</p>);
        }
        return (
            <React.Fragment>
                <DefaultMeta title={winelist.name}
                             description={winelist.description}
                             image={winelist.largeImageUrl}
                             canonical={winelist.virtualShareUrl && buildWebPath(winelist.virtualShareUrl)}
                />

                <WineListItemWidget
                    linkToProfile={true}
                    forceOpen={true}
                    showEditMode={true}
                    model={winelist}
                    items={items}
                    user={authUser}
                    onWineClick={(w) => this.onWineClick(w)}
                    onEditClick={this.onEditClick}
                    onShareClick={() => this.setState({shareModalVisible: true})}
                    onDeleteClick={(list: WineList) => this.onDeleteWinelist(list)}
                />

                <AddEditWineListModal
                    isOpen={isEditMode}
                    winelist={winelist}
                    wineItems={items}
                    onCancel={() => this.onCancelAddEditModal()}
                    onSave={(list: WineList, wineItems?: WineListItem[]) => this.onSaveAddEditModal(list, wineItems)}
                    onPublish={this.onPublishStateChanged}
                    onUnPublish={this.onPublishStateChanged}
                    // onDelete={(list: WineList) => this.onDeleteWinelist(list)}
                />

                <ShareModal
                    isOpen={shareModalVisible}
                    shareUrl={winelist.virtualShareUrl && buildWebPath(winelist.virtualShareUrl)}
                    onClosed={() => this.setState({shareModalVisible: false})}
                    title={'Dela din lista'}
                    emailMessage={{
                        subject: `Min lista i Vinos`,
                        message: `Här kommer en länk till min lista ${winelist.name} i Vinos:`
                    }}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(
    withAuthentication(
        withStateManager(
            frontloadConnect(frontload)(props => (
                    <PrivateWineListView {...getStateManagerState(props)}/>
                )
            )
        )
    )
);