import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import WineListViewModel from './WineListViewModel';
import WineListItem from './WineListItem';
import { WineNotes, WineNotesConverter } from './WineNote';
import { WineRatings, WineRatingsConverter } from './WineRating';
import { WineStocks, WineStocksConverter } from './WineStock';

@JsonObject
export default class WineListItemsViewModel extends Assignable<WineListItemsViewModel> implements JsonSerializable {
    @JsonProperty('winelist', WineListViewModel, true)
    winelist?: WineListViewModel = undefined;

    @JsonProperty('items', [WineListItem], true)
    items?: WineListItem[] = undefined;

    @JsonProperty('notes', WineNotesConverter, true)
    notes?: WineNotes = {};

    @JsonProperty('ratings', WineRatingsConverter, true)
    ratings?: WineRatings = {};

    @JsonProperty('stocks', WineStocksConverter, true)
    stocks?: WineStocks = {};

    static fromJson(data: any): WineListItemsViewModel {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, WineListItemsViewModel) as WineListItemsViewModel;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}