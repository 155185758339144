import * as React from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import Loadable from "react-loadable";
import Routes from "./Routes";
import Loading from "./Loading";
import { AccountNavigation, AuthUserContextProvider } from "./AuthUserContext";
import AgeModal from "./components/age-modal/AgeModal";
import SettingsLayout from "./settings/SettingsLayout";
import Navigation from "./Navigation";
import Footer from "./components/footer/Footer";
import { IStateManager, StateManagerContext } from "./StateManager";

import "bootstrap/dist/css/bootstrap.min.css";
import "./bootstrap-overrides.css";
import "./styles/GlobalStyles.scss";
import "./styles/App.scss";
import { AuthenticationProps } from "./WithAuthentication";
import WineView from "./wines/WineView";
import DefaultMeta from "./DefaultMeta";
import WinelistsLayout from "./winelists/WinelistsLayout";
import MyVinosLayout from "./myvinos/MyVinosLayout";
import "intersection-observer";
// Simple fix to include styles for Home page.
import "./styles/Hero.scss";
import "./styles/WineListCreatorWidget.scss";
import "./styles/ContentItemView.scss";
import "./styles/ActionCard.scss";
import UserLogger from "./account/UserLogger";
// import TagManager from './services/GoogleTagManager';

// TagManager.dataLayer({
//     dataLayer: {
//         event: 'Init'
//     }
// })
// const renderWithState = (loaded: any, props: any): React.ReactNode => {
//     let Component = loaded.default;
//     return <Component {...props.location.state} {...props}/>;
// };

const LoadableHome: any = Loadable({
  loader: () => import("./home/Home"),
  loading: () => <Loading />,
});

const LoadableFeedView: any = Loadable({
  loader: () => import("./feeds/FeedView"),
  loading: () => <Loading />,
});

const LoadableMemberView: any = Loadable({
  loader: () => import("./members/MembersView"),
  loading: () => <Loading />,
});

const LoadableAppOnly: any = Loadable({
  loader: () => import("./home/AppOnly"),
  loading: () => <Loading />,
});

const LoadableVerifiedSuccess: any = Loadable({
  loader: () => import("./account/VerifiedSuccessful"),
  loading: () => <Loading />,
});

const LoadableContentItem = Loadable({
  loader: () => import("./home/ContentItemFullView"),
  loading: () => <Loading />,
});

const LoadablePublicWinelist = Loadable({
  loader: () => import("./winelists/PublicWineListView"),
  loading: () => <Loading />,
});

const LoadableSpecialWinelist = Loadable({
  loader: () => import("./winelists/WineListGroupedView"),
  loading: () => <Loading />,
});

// const LoadableWinelistIndex = Loadable({
//     loader: () => import('./winelists/WineListPublicIndexView'),
//     loading: () => <Loading/>
// });

const LoadableProfilesIndex = Loadable({
  loader: () => import("./profiles/ProfilesIndex"),
  loading: () => <Loading />,
});

const LoadableProfilesLists = Loadable({
  loader: () => import("./profiles/ProfilesWinelists"),
  loading: () => <Loading />,
});

const LoadableThemesIndex = Loadable({
  loader: () => import("./profiles/ThemesIndex"),
  loading: () => <Loading />,
});

const LoadableThemesLists = Loadable({
  loader: () => import("./profiles/ThemesWinelists"),
  loading: () => <Loading />,
});

const LoadableCreatorView = Loadable({
  loader: () => import("./winelists/CreatorView"),
  loading: () => <Loading />,
});

const LoadableCampaignView = Loadable({
  loader: () => import("./campaign/CampaignView"),
  loading: () => <Loading />,
});

const LoadableMyVinosStart = Loadable({
  loader: () => import("./myvinos/StartView"),
  loading: () => <Loading />,
});
const LoadableMyVinosProfilesOrThemes = Loadable({
  loader: () => import("./myvinos/ProfilesOrThemeView"),
  loading: () => <Loading />,
});
const LoadableMyVinosFollowedWineLists = Loadable({
  loader: () => import("./myvinos/FollowedWineListsView"),
  loading: () => <Loading />,
});
const LoadableMyVinosOwnWineLists = Loadable({
  loader: () => import("./myvinos/PrivateWineListsView"),
  loading: () => <Loading />,
});
const LoadableMyVinosOwnWineList = Loadable({
  loader: () => import("./myvinos/PrivateWineListView"),
  loading: () => <Loading />,
});
const LoadableMyVinosCreateFeeds = Loadable({
  loader: () => import("./myvinos/CreateEditFeedView"),
  loading: () => <Loading />,
});
const LoadableMyVinosEditFeeds = Loadable({
  loader: () => import("./myvinos/CreateEditFeedView"),
  loading: () => <Loading />,
});

const LoadablePublic = Loadable({
  loader: () => import("./public/PublicIndexView"),
  loading: () => <Loading />,
});

// const LoadableMyVinos = Loadable({
//     loader: () => import('./myvinos/_MyVinosView'),
//     loading: () => <Loading/>
// });

// const LoadableWineView = Loadable({
//     loader: () => import('./wines/WineView'),
//     loading: () => <Loading/>,
//     render: renderWithState
// });

const LoadableWineSearch = Loadable({
  loader: () => import("./search/WineSearch"),
  loading: () => <Loading />,
});

const LoadableSettingsChangeEmail = Loadable({
  loader: () => import("./settings/ChangeEmail"),
  loading: () => <Loading />,
});

const LoadableSettingsChangePassword = Loadable({
  loader: () => import("./settings/ChangePassword"),
  loading: () => <Loading />,
});

const LoadableSettingsCloseAccount = Loadable({
  loader: () => import("./settings/CloseAccount"),
  loading: () => <Loading />,
});

const LoadableSettingsProfileTexts = Loadable({
  loader: () => import("./settings/ProfileTexts"),
  loading: () => <Loading />,
});

const LoadableSettingsProfileImages = Loadable({
  loader: () => import("./settings/ProfileImages"),
  loading: () => <Loading />,
});

const LoadableSettingsFavoriteStores = Loadable({
  loader: () => import("./settings/FavoriteStores"),
  loading: () => <Loading />,
});
const LoadableTermsOfService = Loadable({
  loader: () => import("./TermsOfService"),
  loading: () => <Loading />,
});

interface AppProps {
  stateManager?: IStateManager;
  initialState?: any;
}

const App = (props: AuthenticationProps & AppProps) => {
  var url = ""

  if (typeof window !== "undefined") {
    url = window.location.href
  }
  

  return (
    <StateManagerContext.Provider value={{ stateManager: props.stateManager }}>
      <div className="App">
        <DefaultMeta />
        <AuthUserContextProvider>
          <header className="App-header">
            <div className="App-nav-container container">
              <Navigation path={url} />
              <figure className={"App-logo-container"} id={"vinos-logo"}>
                <Link to={"/"}>
                  <img
                    src={require("./images/vinkompassen-logo.svg")}
                    alt={"Logotyp"}
                    className={"App-logo"}
                  />
                </Link>
              </figure>
              <AccountNavigation />
              <AgeModal />
            </div>
          </header>
          <main>
            <Switch>
              <Route path={Routes.HOME} exact component={LoadableHome} />

              <Route path={Routes.FEED_VIEW} component={LoadableFeedView} />

              <Route path={Routes.APPONLY} exact component={LoadableAppOnly} />

              <Route
                path={Routes.CONTENTITEM}
                component={LoadableContentItem}
              />

              <Route path={Routes.PROFILE} component={LoadableCreatorView} />

              <Route path={Routes.CAMPAIGN} component={LoadableCampaignView} />

              <Route
                path={Routes.MEMBERS}
                exact
                component={LoadableMemberView}
              />

              <MyVinosLayout
                path={Routes.WINELIST_SPECIAL}
                component={LoadableSpecialWinelist}
              />

              <Route
                path={Routes.WINELIST_PUBLIC}
                component={LoadablePublicWinelist}
              />

              <WinelistsLayout
                path={Routes.WINELIST_PROFILES}
                exact
                component={LoadableProfilesIndex}
              />

              <WinelistsLayout
                path={Routes.WINELIST_INDEX}
                exact
                component={LoadableProfilesLists}
              />

              <WinelistsLayout
                path={Routes.THEMES_INDEX}
                exact
                component={LoadableThemesIndex}
              />

              <WinelistsLayout
                path={Routes.THEMES_LISTS}
                exact
                component={LoadableThemesLists}
              />

              <Route path={Routes.WINE_VIEW} component={WineView} />

              <Route
                path={Routes.WINE_SEARCH}
                exact
                component={LoadableWineSearch}
              />

              <SettingsLayout
                path={Routes.SETTINGS_CHANGEEMAIL}
                exact
                component={LoadableSettingsChangeEmail}
              />

              <SettingsLayout
                path={Routes.SETTINGS_CHANGEPASSWORD}
                exact
                component={LoadableSettingsChangePassword}
              />

              <SettingsLayout
                path={Routes.SETTINGS_CLOSEACCOUNT}
                exact
                component={LoadableSettingsCloseAccount}
              />

              <SettingsLayout
                path={Routes.SETTINGS_PROFILE_TEXTS}
                exact
                component={LoadableSettingsProfileTexts}
              />

              <SettingsLayout
                path={Routes.SETTINGS_PROFILE_IMAGES}
                exact
                component={LoadableSettingsProfileImages}
              />

              <SettingsLayout
                path={Routes.SETTINGS_STORES}
                exact
                component={LoadableSettingsFavoriteStores}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_START}
                exact
                component={LoadableMyVinosStart}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_PROFILES}
                exact
                component={LoadableMyVinosProfilesOrThemes}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_THEMES}
                exact
                component={LoadableMyVinosProfilesOrThemes}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_SAVED_WINE_LISTS}
                exact
                component={LoadableMyVinosFollowedWineLists}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_OWN_WINE_LISTS}
                exact
                component={LoadableMyVinosOwnWineLists}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_OWN_WINE_LIST}
                exact
                component={LoadableMyVinosOwnWineList}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_OWN_WINE_LIST}
                exact
                component={LoadableMyVinosOwnWineList}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_CREATE_FEEDS}
                exact
                component={LoadableMyVinosCreateFeeds}
              />

              <MyVinosLayout
                path={Routes.MYVINOS_EDIT_FEEDS}
                exact
                component={LoadableMyVinosEditFeeds}
              />

              <Route path={Routes.PUBLIC_LIST} component={LoadablePublic} />
              <Route path={Routes.PUBLIC} component={LoadablePublic} />

              <Route
                path={Routes.TERMS_OF_SERVICE}
                component={LoadableTermsOfService}
              />
              <Route
                path={Routes.VERIFIED_SUCCESS}
                exact
                component={LoadableVerifiedSuccess}
              />
            </Switch>
          </main>
          <Footer path={url} />
          <UserLogger />
        </AuthUserContextProvider>
      </div>
    </StateManagerContext.Provider>
  );
};

export default App;
