import Wine from './models/Wine';
import slugify from 'slugify';
import WineList from './models/WineList';
import User from './models/User';
import Feed from './models/Feed';

const routes = {
    HOME: '/',
    APPONLY: '/appen',
    CONTENTITEM: '/blog/:id(\\d+)',
    LOGIN: '/login',
    LOGOUT: '/logout',
    CAMPAIGN: '/kampanj/:campaign',

    WINELIST_INDEX: '/listor',
    WINELIST_PROFILES: '/listor/profiler',
    // WINELISTS: '/winelists/:id',
    WINELIST_PUBLIC: '/listor/:id(\\d+)/:slug',
    WINELIST_SPECIAL: `/mitt-vinkompassen/statistik/:id(\\d+)`,
    // PUBLIC_WINELIST_CREATORS: '/winelists/creators',
    // PROFILES_INDEX: '/profiler',
    // PROFILES_LISTS: '/profiler/listor',
    PROFILE: '/profiler/:id(\\d+)/:slug',

    THEMES_INDEX: '/teman',
    THEMES_LISTS: '/teman/listor',
    THEME: '/teman/:id(\\d+)/:slug',

    RECIPE_SEARCH: '/recept',
    RECIPE_VIEW: '/recept/:id(\\d+)',

    MEMBERS: '/profiler',
    MYVINOS_START: '/mitt-vinkompassen',
    MYVINOS_PROFILES: '/mitt-vinkompassen/profiler',
    MYVINOS_THEMES: '/mitt-vinkompassen/teman',
    MYVINOS_SAVED_WINE_LISTS: '/mitt-vinkompassen/sparade-vinlistor',
    MYVINOS_OWN_WINE_LISTS: '/mitt-vinkompassen/egna-vinlistor',
    MYVINOS_OWN_WINE_LIST: '/mitt-vinkompassen/egna-vinlistor/:id(\\d+)/:slug',
    MYVINOS_FEEDS: '/mitt-vinkompassen/feeds',
    MYVINOS_CREATE_FEEDS: '/mitt-vinkompassen/feeds/ny',
    MYVINOS_EDIT_FEEDS: '/mitt-vinkompassen/feeds/redigera/:id(\\d+)',

    SIGNUP: '/skapa-konto',
    VERIFIED_SUCCESS: '/skapa-konto/verifierat',
    UNAUTHORIZED: '/unauthorized',

    WINE_VIEW: '/viner/:id(\\d+)/:slug',
    WINE_SEARCH: '/viner',

    FEED_VIEW: '/feed/:id(\\d+)',
    
    SETTINGS: '/konto',
    SETTINGS_CHANGEEMAIL: '/konto',
    SETTINGS_CHANGEPASSWORD: '/konto/byt-losenord',
    SETTINGS_CLOSEACCOUNT: '/konto/avsluta-konto',
    SETTINGS_PROFILE_TEXTS: '/konto/profil/texter',
    SETTINGS_PROFILE_IMAGES: '/konto/profil/bilder',
    SETTINGS_STORES: '/konto/butiker',

    PUBLIC: '/publikt',
    PUBLIC_LIST: '/publikt/:id(\\d+)',

    TERMS_OF_SERVICE: '/anvandarvillkor'
};

export default routes;

export function createRouteLink(path: string, params?: any): string {
    let s = path;

    if (params) {
        Object.keys(params!).forEach(key => {
            s = s.replace(new RegExp(`:${key}\\((.*)\\)`), params![key]);
            s = s.replace(`:${key}`, params![key]);
        });
    }

    return s;
}

export function createFeedLink(post: Feed): string {
    return createRouteLink(routes.FEED_VIEW, {
        id: post.id
    });
}

export function createWineLink(wine: Wine): string {
    const slug = wine.name2 ? `${wine.name2} ${wine.name}` : wine.name || '';

    return createRouteLink(routes.WINE_VIEW, {
        id: wine.id,
        slug: slugify(
            slug,
            {
                lower: true
            })
    });
}

export function createWineListLink(winelist: WineList): string {
    return createRouteLink(routes.WINELIST_PUBLIC, {
        id: winelist.id,
        slug: slugify(winelist.name || '', {
            lower: true
        })
    });
}

export function createSpecialWineListLink(winelist: WineList): string {
    return createRouteLink(routes.WINELIST_SPECIAL, {
        id: winelist.id
    });
}

export function createOwnWineListLink(winelist: WineList): string {
    return createRouteLink(routes.MYVINOS_OWN_WINE_LIST, {
        id: winelist.id,
        slug: slugify(winelist.name || '', {
            lower: true
        })
    });
}

export function createProfileLink(user: User): string {
    return createRouteLink(routes.PROFILE, {
        id: user.id,
        slug: slugify(user.fullName || '', {
            lower: true
        })
    });
}
