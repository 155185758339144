import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import AuthUserContext from '../AuthUserContext';
import userService from '../services/UserService';
import User from '../models/User';

interface Props {
}

const UserLogger: React.FC<Props> = (props) => {
    const {user} = useContext(AuthUserContext);
    let [lastUserId, setLastUserId] = useState<number>((user && user.id) || 0);

    useEffect(() => {
        const userId = (user && user.id) || 0;
        if (lastUserId !== userId) {
            logSession(user);
        }

        setLastUserId(userId);

        userService.addAuthenticationStateListener(logSession);

        return () => {
            userService.removeAuthenticationStateListener(logSession)
        }
        // eslint-disable-next-line
    }, [user]);

    async function logSession(user: User | null) {
        try {
            if (user) {
                setLastUserId(user.id!);
                await userService.logSession();
            }
        } catch (e) {
            // dont care about the error
        }
    }

    return null;
}

export default UserLogger;
