import * as React from 'react';
import '../styles/Section.scss';
type Props = {
    label: string;
    children: React.ReactElement[];
    image: any;
};
export default class Section extends React.PureComponent<Props> {

    render() {

        const {label, image, children} = this.props;

        return(
        <div className={'section'}>
            <div className={'section--header'} style={{backgroundImage: `url(${image})`}}>
                <div className="section--header--overlay">
                    <span className={'section--header--text'}>{label}</span>
                </div>
            </div>
            <div className={'section--content'}>
                {children}
            </div>

        </div>);
    }
}