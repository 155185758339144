import * as React from 'react';
import * as Connectors from 'react-instantsearch/connectors';
import WineItemView from '../../wines/WineItemView';
import Wine from '../../models/Wine';
import Stats from './Stats';
import { AddIcon } from '../../components/icons/Icons';
import '../../styles/WinelistHits.scss';
import '../../styles/Fonts.scss';

interface HitsProps {
    hasSearched: boolean;
    hits: any;
    onAdd: (wine: Wine) => void;
    HasWineNoteChecker?: (wine: Wine) => boolean;
    InWineListChecker?: (wine: Wine) => boolean;
}

const NoResults = () => (
    <div className={'hits no-results'}>
        <h3 className={'heading-green-bigger'}>Inga träffar</h3>
        <div className={'no-result-content'}>Sökningen gav inga träffar…</div>
    </div>
);

const Hits = Connectors.connectHits(({hits, onAdd, hasSearched, HasWineNoteChecker, InWineListChecker}: HitsProps) => {
        return hits && hits.length > 0 ? (
            <div className={'hits--container'}>
                {hasSearched ?
                    <Stats/> :
                    <h3 className={'heading-green-bigger'}>Populära viner just nu</h3>}

                {hits.map((hit: any) => (
                    <div key={`hits-hit-${hit.id}`} className={'hits--item'}>
                        <WineItemView
                            hasWineNote={(HasWineNoteChecker)
                                ? HasWineNoteChecker(Wine.fromJson(hit))
                                : false}
                            inWineList={(InWineListChecker)
                                ? InWineListChecker(Wine.fromJson(hit))
                                : false}
                            wine={Wine.fromJson(hit)}/>

                        <span className={'hits--item--add'} onClick={() => onAdd(Wine.fromJson(hit))}>
                                <AddIcon/>
                            </span>
                    </div>
                ))}
            </div>
        ) : (
            <NoResults/>
        );
    }
);

export default Hits;