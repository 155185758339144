import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import Button from '../../components/buttons/Button';
import userService from '../../services/UserService';
import WineList from '../../models/WineList';
import WineListItem from '../../models/WineListItem';

import '../../styles/AddEditWineListModal.scss';
// TABS CONTENT
import { TabInfoText } from './TabInfoText';
import TabWines from './TabWines';
import TabBigImage from './TabBigimage';
import TabLinks from './TabLinks';
import { TabPublish } from './TabPublish';
import { TrashIcon } from '../../components/icons/Icons';
import User from '../../models/User';
import WineListService from '../../services/WineListService';

export type TabNameTypes = 'infoText' | 'images' | 'imageBig' | 'wines' | 'publish' | 'links';

export const TabNames = {
    INFO_TEXT: 'infoText' as TabNameTypes,
    IMAGES: 'images' as TabNameTypes,
    IMAGES_BIG: 'imageBig' as TabNameTypes,
    WINES: 'wines' as TabNameTypes,
    PUBLISH: 'publish' as TabNameTypes,
    LINKS: 'links' as TabNameTypes
};

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onSave: (winelist: WineList, wineItems?: WineListItem[], linkUsers?: User[]) => void;
    onDelete?: (winelist: WineList) => void;
    onPublish?: (winelist: WineList) => void;
    onUnPublish?: (winelist: WineList) => void;

    winelist?: WineList;
    wineItems?: WineListItem[];
    linkedUsers?: User[];
};

type State = {
    winelist: WineList;
    wineItems: WineListItem[];
    searchState: any;
    activeTab: TabNameTypes;
    isDirty: boolean;
    isSaving: boolean;
    isAdmin: boolean;
    editLinkedUsers?: User[];
};

class AddEditWineListModal extends React.Component<Props, State> {
    private isWineItemsDirty: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            activeTab: TabNames.INFO_TEXT,
            searchState: {},
            wineItems: [],
            winelist: this.generateNewList(),
            isDirty: false,
            isSaving: false,
            isAdmin: false,
        };
    }

    // componentWillReceiveProps(newProps: Props) {
    //     this.setState({
    //         wineItems: (newProps.wineItems) ? newProps.wineItems : [],
    //         winelist: (newProps.winelist) ?
    //             WineList.fromJson(newProps.winelist.toJson()) :
    //             this.generateNewList(),
    //         activeTab: (newProps.isOpen && !this.props.isOpen) ? TabNames.INFO_TEXT : this.state.activeTab,
    //         isDirty: false,
    //         isSaving: false,
    //     }, this.checkAdmin);
    // }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const {winelist, wineItems, isOpen} = this.props;
        if (winelist && winelist.id !== this.state.winelist.id) {
            this.setState({
                wineItems: wineItems || [],
                winelist: winelist ?
                    WineList.fromJson(winelist.toJson()) :
                    this.generateNewList(),
                activeTab: (!prevProps.isOpen && isOpen) ? TabNames.INFO_TEXT : this.state.activeTab,
                isDirty: false,
                isSaving: false,
            }, this.checkAdmin);
        }
    }

    checkAdmin = async () => {
        const user = await userService.current(true);
        if (user) {
            this.setState({
                isAdmin: user.isAdministrator
            });
        }
    }

    onTabClick = (val: TabNameTypes) => {
        this.setState({activeTab: val});
    }

    onSave = (winelist: WineList, wineItems: WineListItem[], linkUsers?: User[]) => {

        this.setState({isSaving: true});
        if (this.isWineItemsDirty) {
            this.props.onSave(winelist, wineItems, linkUsers);
        } else {
            this.props.onSave(winelist, undefined, linkUsers);
        }
    }

    onDelete = (winelist: WineList) => {
        const anwser = window.confirm('Är du verkligen säker på att du vill radera vinlistan?');
        if (anwser && this.props.onDelete) {
            this.props.onDelete(this.state.winelist);
        }
    }

    onPublish = async (list: WineList, publish: boolean) => {
        try {
            const winelist: WineList = (publish) ?
                await WineListService.publish(list) :
                await WineListService.unpublish(list);

            this.setState({
                winelist
            });

            if (publish) {
                this.props.onPublish && this.props.onPublish(winelist);
            } else {
                this.props.onUnPublish && this.props.onUnPublish(winelist);
            }
        } catch (e) {
            alert(`Misslyckades med att publicera vinlistan.\n\n${e.message}`);
            console.log(e);
        }
    }

    generateNewList = (): WineList => {
        const w = new WineList();
        w.name = 'Namnlös';
        return w;
    }

    render() {

        const {isOpen, onCancel, linkedUsers} = this.props;
        const {winelist, wineItems = [], activeTab, isDirty, isSaving, isAdmin, editLinkedUsers} = this.state;

        if (!winelist) {
            return;
        }

        return (
            <Modal isOpen={isOpen} className={'add-edit-wine-list-modal'}>
                <ModalHeader>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={(activeTab === TabNames.INFO_TEXT) ? 'active' : ''}
                                     onClick={() => this.onTabClick(TabNames.INFO_TEXT)}>
                                Texter
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink className={(activeTab === TabNames.IMAGES_BIG) ? 'active' : ''}
                                     onClick={() => this.onTabClick(TabNames.IMAGES_BIG)}>
                                Bild
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink className={(activeTab === TabNames.WINES) ? 'active' : ''}
                                     onClick={() => this.onTabClick(TabNames.WINES)}>
                                Viner
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink className={(activeTab === TabNames.PUBLISH) ? 'active' : ''}
                                     onClick={() => this.onTabClick(TabNames.PUBLISH)}>
                                Publicering
                            </NavLink>
                        </NavItem>


                        {(isAdmin && winelist.id) && (
                            <NavItem>
                                <NavLink className={(activeTab === TabNames.LINKS) ? 'active' : ''}
                                         onClick={() => this.onTabClick(TabNames.LINKS)}>
                                    Länkningar
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </ModalHeader>
                <ModalBody>
                    <TabContent activeTab={activeTab}>
                        {/* Text */}
                        <TabInfoText winelist={winelist} onUpdate={(list) => {
                            this.setState({winelist: list, isDirty: true});
                        }}/>
                        {/* Images */}

                        <TabBigImage winelist={winelist} onUpdate={(list) => {
                            this.setState({winelist: list, isDirty: true});
                        }}/>

                        {/* Wines */}
                        <TabWines wineItems={wineItems} isPublic={winelist.isPublished} onUpdate={(items) => {
                            this.isWineItemsDirty = true;
                            this.setState({wineItems: items, isDirty: true});
                        }}/>
                        {/* Publish */}
                        <TabPublish isDirty={isDirty}
                                    winelist={winelist}
                                    wineItems={wineItems}
                                    onPublish={(list) => this.onPublish(list, true)}
                                    onUnPublish={(list) => this.onPublish(list, false)}/>
                        {/* Links */}
                        {isAdmin &&
                        <TabLinks winelist={winelist} linkedUsers={linkedUsers} onUpdate={(users) => {
                            this.setState({isDirty: true, editLinkedUsers: users});
                        }}/>
                        }
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    {this.props.onDelete &&
                    <Button
                        className={'add-edit-wine-list-modal--delete-button'}
                        icon={<TrashIcon/>} trim
                        label={'Radera'} theme={'white'} onClick={() => this.onDelete(winelist)}/>
                    }
                    <Button trim label={'Stäng'} theme={'white'} onClick={() => onCancel()}/>

                    <Button disabled={(!isDirty)} trim
                            label={(isSaving)
                                ? 'Sparar...'
                                : (!isDirty) ? 'Sparat' : 'Spara'} theme={'pink'}
                            onClick={() => this.onSave(winelist, wineItems, editLinkedUsers)}/>
                    {/* <Button disabled={(!winelist.name)} trim label={'Spara'} theme={'pink'}
                            onClick={() => this.onSave(winelist, wineItems)}/> */}
                </ModalFooter>
            </Modal>
        );
    }
}

export default AddEditWineListModal;