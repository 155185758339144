import * as React from 'react';
import fileService, { FileUploadResponse } from '../../services/FileService';

interface Props {
    id: string;
    label: string;
    onFileUploaded?: (file: FileUploadResponse) => void;
    onFileSelected?: (file: File) => void;
    maxWidth: number;
    maxHeight: number;
}

interface State {
    isUploading: boolean;
}

class UploadButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isUploading: false
        };
    }

    onFileSelected = (file: File): Promise<FileUploadResponse | File> => {
        return new Promise<FileUploadResponse | File>(async (resolve, reject) => {
            try {
                const {maxWidth, maxHeight, onFileUploaded, onFileSelected} = this.props;

                if (onFileSelected) {
                    onFileSelected(file);
                }

                this.setState({isUploading: true});

                const formData = new FormData();
                formData.append('file', file);
                formData.append('w', maxWidth.toString(10));
                formData.append('h', maxHeight.toString(10));

                if (onFileUploaded) {
                    const result = await fileService.upload(formData, (progress) => console.log(progress));
                    onFileUploaded(result);
                    return resolve(result);
                }

                return resolve(file);
            } catch (error) {
                console.log(error);
                reject(error);
            } finally {
                this.setState({isUploading: false});
            }
        });
    }

    render() {
        return (
            <div className={'upload-button text-center'} style={{opacity: this.state.isUploading ? 0.5 : 1.0}}>
                <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id={this.props.id}
                    disabled={this.state.isUploading}
                    type="file"
                    onChange={(e) => e.target.files &&
                        this.onFileSelected(e.target.files[0])}
                />
                <label htmlFor={this.props.id} style={{cursor: 'pointer'}}>
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                            <circle cx="29" cy="29" r="24" fill="white"/>
                        </g>
                        <path
                            d="M20.5002 36.5001H33.0001H40.0002C42.5002 36.5001 46.2398 30.0871 40.0002 26.5001C40.0852 25.1483 36.8145 22.7565 30.5001 25.0001C28.1992 20.1639 20.2628 20.8973 20.2628 28.4743C13.574 32.463 18.1993 36.5001 20.5002 36.5001Z"
                            fill="#DFF1F9"/>
                        <path d="M29.0752 25.5223V41.5615" stroke="#323232" strokeWidth="2" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M35.5333 32.2363L29.0747 25.5222L22.6162 32.2363" stroke="#323232" strokeWidth="2"
                              strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M26.3092 36.6927C26.3092 36.6927 20.8954 36.6927 18.6359 36.6927C16.0793 36.6927 10.1932 31.0386 17.6252 26.068C17.6252 16.6257 29.2787 15.8162 31.8353 21.843C38.8512 19.0471 40.6699 24.3835 40.5755 26.068C47.5084 30.5381 42.1214 36.382 39.9809 36.6927L31.7799 36.6927"
                            stroke="#323232" strokeWidth="2" strokeLinejoin="round"/>
                        <defs>
                            <filter id="filter0_d" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset dx="1" dy="1"/>
                                <feGaussianBlur stdDeviation="3"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                    <br/>
                    {this.state.isUploading ? 'Ladda upp...' : this.props.label}
                </label>

            </div>
        );
    }
}

export default UploadButton;
