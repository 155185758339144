import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import Button from "../buttons/Button";
import AsyncStorage from "../../services/AsyncStorage";
import "../../styles/AgeModal.scss";

type State = {
  showModal: boolean;
};

class AgeModal extends React.PureComponent<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  async componentDidMount() {
    const ageAndCookieAgreement = await AsyncStorage.getItem(
      "ageAndCookieAgreement",
      "false"
    );
    this.setState({
      showModal: !JSON.parse(ageAndCookieAgreement),
    });
  }

  onOkClick = () => {
    AsyncStorage.setItem("ageAndCookieAgreement", "true");
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;

    if (!showModal) {
      return null;
    }

    return (
      <Modal isOpen={showModal} className={"age-modal"}>
        <p>
          Jag intygar härmed att jag är över 25 år samt att jag är medveten om
          att innehållet på denna sajt handlar om alkoholhaltiga drycker.
        </p>
        <p>
          Vi använder cookies och historiska data för personliga och anpassade
          annonser via Google (personaliseringskakor). Läs mer hur Google
          använder din personliga data på deras sida för allmänna villkor på{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
          >
            Google’s Privacy & Terms site
          </a>
        </p>
        <Button
          theme={"pink"}
          label={"OK - gå in på sajten!"}
          onClick={this.onOkClick}
        />
      </Modal>
    );
  }
}

export default AgeModal;
