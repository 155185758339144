import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';

@JsonObject
export default class CountryRegion extends Assignable<CountryRegion> implements JsonSerializable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('country', String, true)
    country?: string = undefined;

    @JsonProperty('region', String, true)
    region?: string = undefined;

    @JsonProperty('landscapeImageUrls', [String], true)
    landscapeImageUrls?: string[] = [];

    @JsonProperty('mapImageUrl', String, true)
    mapImageUrl?: string = undefined;

    @JsonProperty('content', String, true)
    content?: string = undefined;

    static fromJson(data: any): CountryRegion {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.deserializeObject(data, CountryRegion);
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

}