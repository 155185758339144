import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Button from '../buttons/Button';
import '../../styles/Footer.scss';
import withAuthentication, { AuthenticationProps } from '../../WithAuthentication';
import AuthUserContext from '../../AuthUserContext';
import { Link } from 'react-router-dom';
import routes from '../../Routes';


interface Props extends AuthenticationProps {
    path:string
}

class Footer extends React.PureComponent<Props> {

    _renderGDPR() {
        return (
            <React.Fragment>
                <h3>Användarvillkor</h3>
                <p>Ta del av vår användarvillkor samt sekretesspolicy i enlighet med GDPR-reglerna
                    här:&nbsp;
                    <Link to={routes.TERMS_OF_SERVICE}>Sekretesspolicy</Link>
                </p>
            </React.Fragment>
        );
    }

    render() {
        
        const {authUser} = this.props;

        return (
            <footer className={'footer'}>
                <Container>
                    <Row>
                        <Col sm={{size: 4}}>
                            <h3>Info och kontakt</h3>
                            <p>Vinkompassen och Systembolaget har inget kommersiellt samarbete. Vinkompassen tipsar endast om produkter som finns i Systembolagets sortiment. All försäljning samt beställning sker på och genom Systembolaget.</p>
                            <p>Har du frågor kring Vinkompassen?
                                Eller är du intresserad av att medverka som profil?
                                Kontakta oss gärna på <a href={'mailto:info@vinkompassen.se'}>info@vinkompassen.se</a></p>
                            {!authUser &&
                            this._renderGDPR()
                            }
                        </Col>
                        <Col sm={{size: 4}}>
                            {!authUser &&
                            <React.Fragment>
                            <h3>Skapa konto hos oss</h3>
                            <p>Mesta möjliga gläje och nytta av Vinkompassen får du med ett konto. 
                                Då kan du följa profiler och teman samt skapa egna listor och noteringar för viner. 
                                Kontot är helt kostnadsfritt.</p>
                                <AuthUserContext.Consumer>
                                    {context => (
                                        <Button
                                            style={{maxWidth: '340px', marginBottom: '20px'}}
                                            label={'Skapa konto'} 
                                            useCaret
                                            textAlign={'left'}
                                            onClick={() => context.showAccountModal('signup')}/>
                                    )}
                                </AuthUserContext.Consumer>
                            </React.Fragment>
                            }
                            {authUser &&
                            this._renderGDPR()
                            }
                            <p className={'footer--copywrite'}>Copyright © Vinkompassen 2021</p>
                        </Col>
                        <Col sm={{size: 4}}>
                            <h3>Kolla in våra andra kanaler</h3>
                            <p>Vinkompassen är Sveriges nya heta vincommunity, där du kan hålla koll på vilka viner du gillar
                                och få en massa inspiration av riktiga proffs.</p>
                            <p>Vi finns på andra kanaler, följ oss här:</p>
                            <a href="https://www.youtube.com/user/vinkompassen" target="_blank" rel="noopener noreferrer">
                                <img src={require('../../images/youtube.svg')} className='footer--social-media-icon' alt="Följ oss på Youtube" />
                            </a>
                            <a href="https://www.instagram.com/vinkompassen" target="_blank" rel="noopener noreferrer">
                                <img src={require('../../images/instagram.svg')} className='footer--social-media-icon' alt="Följ oss på Instagram" />
                            </a>
                            <a href="https://www.facebook.com/vinkompassen" target="_blank" rel="noopener noreferrer">
                                <img src={require('../../images/facebook.svg')} className='footer--social-media-icon' alt="Följ oss på Facebook" />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}
export default withAuthentication(Footer);