import * as React from 'react';
import '../../styles/LikeButton.scss';
import '../../styles/ActionButton.scss';
import { HeartIcon } from '../icons/Icons';
import { TweenLite, Back, Sine } from 'gsap';

type Props = {
    isSelected?: boolean;
    disabled?: boolean;
    onClick?: (isSelected: boolean) => void;
};

type State = {
    isSelected?: boolean;
};

export default class LikeButton extends React.PureComponent<Props, State> {

    private heart: any = null;
    private bigHeart: any = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isSelected: false,
        };
    }

    onSelect = () => {
        const {onClick} = this.props;
        TweenLite.to(this.heart, 0.1, {scale: 0.8, ease: Sine.easeOut});
        TweenLite.to(this.heart, 0.3, {scale: 1, ease: Back.easeOut, delay: 0.1});
        TweenLite.set(this.bigHeart, {scale: 1, opacity: 1});
        TweenLite.to(this.bigHeart, 1, {scale: 2, opacity: 0, ease: Sine.easeOut, delay: 0.1});
        if (onClick) {
            onClick(true);
        }
    }

    deSelect = () => {
        const {onClick} = this.props;
        TweenLite.to(this.heart, 0.1, {scale: 0.8, ease: Sine.easeOut});
        TweenLite.to(this.heart, 0.3, {scale: 1, ease: Back.easeOut, delay: 0.2});
        TweenLite.set(this.bigHeart, {scale: 0.8, opacity: 1});
        TweenLite.to(this.bigHeart, 1, {scale: 2, opacity: 0, ease: Sine.easeOut, delay: 0.1});
        if (onClick) {
            onClick(false);
        }
    }

    render () {
        const {isSelected, ...rest} = this.props;
       
        return (
            <button {...rest} 
                className={`action-button like-button ${(isSelected) ? 'selected' : ''}`} 
                onClick={() => { (!isSelected) ? this.onSelect() : this.deSelect(); }}>
                <figure className={'like-button--icon'}>
                    <div className={'like-button--icon--heart-big'} ref={(e: any) => this.bigHeart = e}>
                        <HeartIcon/>
                    </div>
                    <div className={'like-button--icon--heart'} ref={(e: any) => this.heart = e}>
                        <HeartIcon color={'#fff'}/>
                    </div>
                </figure>
                
                <span className={'action-button--label'}>{(isSelected) ? 'Gillar' : 'Gilla'}</span>
            </button>
        );
    }
}