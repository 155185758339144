import * as React from 'react';

interface Props {
    checked: boolean;
    selectedBackground: string;
    selectedForeground: string;
    background: string;
    foreground: string;
    width: number;
    height: number;
}

class Checkbox extends React.PureComponent<Partial<Props>> {
    static defaultProps: Partial<Props> = {
        selectedBackground: '#30cc22',
        selectedForeground: '#ffffff',
        background: '#e1e1e1',
        foreground: '#ffffff',
        width: 22,
        height: 22
    };

    render() {
        const {
            checked,
            selectedBackground,
            selectedForeground,
            background,
            foreground,
            width,
            height,
        } = this.props;

        const checkmark = (
            <span style={{
                position: 'absolute',
                border: '2px solid ' + (checked ? selectedForeground : foreground),
                borderTop: 0,
                borderRight: 0,
                height: height! * 0.21,
                width: width! * 0.43,
                left: width! * 0.28,
                top: height! * 0.34,
                transform: 'rotate(-45deg)'
            }}/>
        );

        return (
            <div style={{
                position: 'relative',
                backgroundColor: checked ? selectedBackground : background,
                height: height,
                width: width,
                borderRadius: '50%',
                flexGrow: 0,
                flexShrink: 0
            }}>
                {checkmark}
            </div>
        );
    }
}

export default Checkbox;
