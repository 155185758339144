import * as React from 'react';
import users from '../services/UserService';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import Routes from '../Routes';
import Button from '../components/buttons/Button';

interface Props extends RouteComponentProps {
    token?: string;
    email?: string;
}

type State = {
    isLoading: boolean;
};

class VerifyEmailView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await this.onCheckValidation();
    }

    onCheckValidation = async () => {
        try {
            this.setState({isLoading: true});
            const sessionResponse = await users.isVerified(this.props.token!);
            this.setState({isLoading: false});
            if (sessionResponse.authenticated) {
                this.props.history.replace(Routes.HOME);
            }
        } catch (error) {
            this.setState({isLoading: false});
            console.log(error);
        }
    }

    onSendNewVerification = async () => {
        try {
            const {email} = this.props;
            if (email) {
                await users.resendVerification(email);
                alert(`Ett nytt mail har skickats till ${email}.`);
            } else {
                alert('E-post saknas');
            }
        } catch (error) {
            alert(error.message);
        }
    }

    render() {
        const {token, email} = this.props;

        if (!token) {
            return (
                <Container>
                    <Row>
                        <Col>
                            <h2>Verifiera konto</h2>
                            <p>Nödvändiga parametrar saknas, så vi kan inte kontrollera att du verifierat kontot.</p>
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <h2>Verifiera konto</h2>
                        <p>Vi har skickat ett mejl till adressen du angav:</p>
                        <p>{email}</p>
                        <p>För att aktivera kontot behöver du klicka på länken i mejlet. Om du redan gjort
                            det men ändå inte kommer vidare kan det hjälpa att klicka på länken nedan.</p>

                        <p>
                            <Button theme={'pink'} onClick={this.onSubmit}>
                                Jag har verifierat kontot
                            </Button>
                        </p>

                        <p>
                            Om du inte fått något mejl kan det bero på att det hamnat i ett spam-filter eller
                            att det inte finns något konto med den adressen.
                        </p>

                        <p>
                            <Button theme={'white'} onClick={this.onSendNewVerification}>
                                Skicka verifieringsmejl en gång till
                            </Button>
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(VerifyEmailView);