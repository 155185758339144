import * as React from 'react';

export type AccountModalScreens = 'login' | 'signup' | 'resetpassword' | 'verifyemail';

export type AccountModalContextProps = { onShowScreen: (screen: AccountModalScreens, data?: any) => void };

export const AccountModalContext = React.createContext<AccountModalContextProps>({
    onShowScreen: screen1 => { /**/
    }
});
