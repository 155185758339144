import BaseService, { emitEventAction } from './BaseService';
import WineList from '../models/WineList';
import Wine from '../models/Wine';
import viewModelService from './WineListViewModelService';
import WineListViewModel from '../models/WineListViewModel';
import EventEmitter from './EventEmitter';
import AsyncStorage from './AsyncStorage';
import User, { UserRoles } from '../models/User';

export const WineListChanged = 'WineListChanged';
export const WineListFollowed = 'WineListFollowed';
export const WineListUnfollowed = 'WineListUnfollowed';
export const WineListLiked = 'WineListLiked';
export const WineListUnliked = 'WineListUnlikeed';
export const WineListRemoved = 'WineListRemoved';
export const addedToHistoryEvent: string = 'addedToHistoryEvent';

export type WineListType = 'saved' | 'rated' | 'winecooler' | 'scanned';

export const WineListTypeSaved: WineListType = 'saved';
export const WineListTypeRated: WineListType = 'rated';
export const WineListTypeWineCooler: WineListType = 'winecooler';
export const WineListTypeScanned: WineListType = 'scanned';

function emitWineListChangedEvent(data: any): Promise<void> {
    return new Promise<void>(resolve => {
        const wineList = WineList.fromJson(data);
        EventEmitter.emit(WineListChanged, wineList);

        resolve();
    });
}

class WineListService extends BaseService<WineList> {

    constructor() {
        super(`winelists`);
    }

    fromJson(data: any): WineList {
        return WineList.fromJson(data);
    }

    add(data: WineList): Promise<WineList> {
        return super.single('post', '/', data.toJson(), emitWineListChangedEvent);
    }

    update(data: WineList): Promise<WineList> {
        return super.single('put', '/', data.toJson(), emitWineListChangedEvent);
    }

    remove(id: number): Promise<void> {
        return super.r('delete', `/${id}`, null, emitEventAction(WineListRemoved, id));
    }

    follow(id: number): Promise<void> {
        return super.r('post', `/follow/${id}`, null, emitEventAction(WineListFollowed, id));
    }

    unfollow(id: number): Promise<void> {
        return super.r('post', `/unfollow/${id}`, null, emitEventAction(WineListUnfollowed, id));
    }

    like(id: number): Promise<void> {
        return super.r('post', `/like/${id}`, null, emitEventAction(WineListLiked, id));
    }

    unlike(id: number): Promise<void> {
        return super.r('delete', `/like/${id}`, null, emitEventAction(WineListUnliked, id));
    }

    publish(data: WineList): Promise<WineList> {
        return super.single('post', `/publish/${data.id}`, null, emitWineListChangedEvent);
    }

    unpublish(data: WineList): Promise<WineList> {
        return super.single('post', `/unpublish/${data.id}`, null, emitWineListChangedEvent);
    }

    link(id: number, userId: number): Promise<void> {
        return super.r('post', `/link`, {userId, winelistId: id});
    }

    unlink(id: number, userId: number): Promise<void> {
        return super.r('delete', `/link`, {userId, winelistId: id});
    }

    async linkedUser(id: number): Promise<User[]> {
        const json: any[] = await super.r('get', `/link/${id}`);
        return json.map(x => User.fromJson(x));
    }

    get(id: number, hash: string | undefined = undefined): Promise<WineListViewModel> {
        return viewModelService.get(id, hash);
    }

    owned(): Promise<WineListViewModel> {
        return viewModelService.owned();
    }

    ownedPrivate(): Promise<WineListViewModel> {
        return viewModelService.ownedPrivate();
    }

    ownedPublic(): Promise<WineListViewModel> {
        return viewModelService.ownedPublic();
    }

    followed(): Promise<WineListViewModel> {
        return viewModelService.followed();
    }

    latest(): Promise<WineListViewModel> {
        return viewModelService.latest();
    }

    popular(): Promise<WineListViewModel> {
        return viewModelService.popular();
    }

    forWine(wine: Wine): Promise<WineListViewModel> {
        return viewModelService.forWine(wine);
    }

    publicListsByUser(user: User): Promise<WineListViewModel> {
        return viewModelService.publicListsByUser(user);
    }

    publicListsByRole(role: UserRoles): Promise<WineListViewModel> {
        return viewModelService.publicListsByRole(role);
    }

    sort(ids: number[]): Promise<WineListViewModel> {
        return viewModelService.sort(ids);
    }

    getHistory(): Promise<number[]> {
        return new Promise<number[]>(async (resolve, reject) => {
            try {
                let list = await AsyncStorage.getItem('listViewHistory', '[]');
                // console.log('getHistory', list);
                resolve(JSON.parse(list));

            } catch (error) {
                console.log('Error getting from localstorage', error);
            }
        });
    }

    addToHistory(id: number): Promise<number[]> {
        return new Promise<number[]>(async (resolve, reject) => {
            try {
                let list = await this.getHistory();
                list.unshift(id);
                list = [...new Set(list)];
                list = list.slice(0, 3);

                await AsyncStorage.setItem('listViewHistory', JSON.stringify(list));

                resolve(list);
                EventEmitter.emit(addedToHistoryEvent, list);
            } catch (error) {
                console.log('Error saving to localstorage', error);
            }
        });
    }
}

export default new WineListService();
