import BaseService from './BaseService';
import Wine from '../models/Wine';
import WineListViewModel from '../models/WineListViewModel';
import User, { UserRoles } from '../models/User';
import WineListCombined from '../models/WineListCombined';
import { RequireAuthentication } from './RequestAuthenticationDecorator';
import NodeCache from 'node-cache';

class WineListViewModelService extends BaseService<WineListViewModel> {
    cache = new NodeCache({checkperiod: 300, stdTTL: 300});

    constructor() {
        super(`winelists`);
    }

    fromJson(data: any): WineListViewModel {
        return WineListViewModel.fromJson(data);
    }

    get(id: number, hash: string | undefined = undefined): Promise<WineListViewModel> {
        const qs = hash ? `?hash=${hash}` : '';
        return this.single('get', `/${id}${qs}`);
    }

    async getCached(id: number, hash: string | undefined = undefined): Promise<WineListViewModel> {
        const key = `winelist-${id}-${hash}`;
        const value = this.cache.get<WineListViewModel>(key);
        if (value) {
            return value;
        } else {
            const model = await this.get(id, hash);
            this.cache.set<WineListViewModel>(key, model);
            return model;
        }
    }

    @RequireAuthentication
    owned(): Promise<WineListViewModel> {
        return super.single('get', '/owned');
    }

    @RequireAuthentication
    ownedPrivate(): Promise<WineListViewModel> {
        return super.single('get', '/owned-private');
    }

    @RequireAuthentication
    ownedPublic(): Promise<WineListViewModel> {
        return super.single('get', '/owned-public');
    }

    @RequireAuthentication
    followed(): Promise<WineListViewModel> {
        return super.single('get', '/followed');
    }

    latest(): Promise<WineListViewModel> {
        return super.single('get', '/public');
    }

    popular(): Promise<WineListViewModel> {
        return super.single('get', '/popular');
    }

    publicListsByUser(user: User): Promise<WineListViewModel> {
        return super.single('get', `/user/${user.id}`);
    }

    publicListsByRole(role: UserRoles): Promise<WineListViewModel> {
        return super.single('get', `/role/${role}`);
    }

    forWine(wine: Wine): Promise<WineListViewModel> {
        return super.single('get', `/wine/${wine.id}`);
    }

    byUser(user: User): Promise<WineListViewModel> {
        return super.single('get', `/user/${user.id}`);
    }

    byUserCombined(user: User): Promise<WineListCombined> {
        return new Promise<WineListCombined>(async (resolve, reject) => {
            try {
                const data = await this.r(
                    'get',
                    `/user/${user.id}/combined`);
                const model = WineListCombined.fromJson(data);

                resolve(model);
            } catch (error) {
                reject(error);
            }
        });
    }

    @RequireAuthentication
    sort(ids: number[]): Promise<WineListViewModel> {
        return super.r('post', `/sort/`, ids);
    }
}

export default new WineListViewModelService();
