import * as React from 'react';
import WineListItemView from '../winelists/WineListItemView';
import WineListViewModel from '../models/WineListViewModel';
import { Link } from 'react-router-dom';
import Chevron from '../Chevron';

import '../styles/WineListWidget.scss';
import { createOwnWineListLink, createWineListLink } from '../Routes';

type ListType = 'own' | 'public';
type Props = {
    model?: WineListViewModel;
    limit?: number;
    type?: ListType;
    defaultText: string;
};

const WineListWidget = ({model, limit, type, defaultText}: Props) => {
// export default class WineListWidget extends React.PureComponent<Props> {

    // render() {

    // const {model, limit, type = 'own', defaultText} = this.props;

    if (model && model.lists && model.lists.length === 0) {
        return (
            <React.Fragment>
                <div className={'wine-list-widget--empty-item'}>
                    <figure className={`wine-list-widget--empty-item--profile ${(type === 'own') ? 'own' : ''}`}/>
                    <span className={'wine-list-widget--empty-item--text'}>
                            {type === 'own' ? 'Egen' : 'Sparad'} vinlista
                        </span>
                    <span className={'wine-list-widget--empty-item--caret'}><Chevron color={'#c8c8c8'}/></span>
                </div>
                <p>{defaultText}</p>
            </React.Fragment>
        );
    }

    return (
        <div>
            {model && model.lists && model.lists.slice(0, limit).map(list => (
                <Link  className={'link-item'} key={list.id}
                      to={(type === 'public') ? createWineListLink(list) : createOwnWineListLink(list)}>
                    <WineListItemView isOwner={(type === 'own')}
                                      circleImage={(type !== 'own')}
                                      isFollowing={true}
                                      user={model.users![list.userId!]}
                                      winelist={list}/>
                </Link>
            ))}
        </div>
    );
    // }
};
export default WineListWidget;