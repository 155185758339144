import * as React from 'react';
import users from './services/UserService';
import User from './models/User';
import { getStateManagerState, StateManagerProps, withStateManager } from './StateManager';

export interface AuthenticationState {
    authUser?: User | null;
}

export interface AuthenticationProps extends AuthenticationState, StateManagerProps {
}

interface State extends AuthenticationState {
    loading: boolean;
}

const withAuthentication =
    <P extends object>(Component: React.ComponentType<P & AuthenticationProps>,
                       waitForUserCheck: boolean = true): React.ComponentType<P & AuthenticationProps> => {
        class WithAuthentication extends React.Component<P & AuthenticationProps, State> {
            _mounted: boolean = false;

            constructor(props: P & AuthenticationProps) {
                super(props);

                let loading = waitForUserCheck;
                if (props.stateManager && props.stateManager.isServer) {
                    loading = false;
                }

                this.state = {
                    authUser: props.authUser, // null,
                    loading
                };
            }

            componentDidMount() {
                this._mounted = true;
                users.addAuthenticationStateListener(this.authChangedListener);
                this.checkUser();
            }

            componentWillUnmount() {
                this._mounted = false;
                users.removeAuthenticationStateListener(this.authChangedListener);
            }

            componentDidUpdate(prevProps: Readonly<P & AuthenticationProps>, prevState: Readonly<State>, snapshot?: any): void {
                if (this.props.authUser !== prevProps.authUser) {
                    this.setState({
                        authUser: this.props.authUser,
                        loading: false
                    });
                }
            }

            checkUser = async () => {
                try {
                    const user = await users.current(true);
                    this.authChangedListener(user);
                } catch (error) {
                    this.authChangedListener(null);
                }
            }

            authChangedListener = async (user: User | null) => {
                const {stateManager} = this.props;
                if (stateManager) {
                    await stateManager.set('authUser', user);
                }

                if (this._mounted) {
                    this.setState({
                        authUser: user || null,
                        loading: false
                    });
                }
            };

            render() {
                const {loading, authUser} = this.state;
                // noinspection JSPotentiallyInvalidUsageOfThis
                const props = this.props;

                if (loading) {
                    return null;
                }

                return (
                    <Component {...props} authUser={authUser}/>
                );
            }
        }

        return withStateManager(props => (
            <WithAuthentication {...getStateManagerState(props)}/>
        ));
    };

export default withAuthentication;
