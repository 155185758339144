import CountryRegion from '../models/CountryRegion';
import BaseService from './BaseService';

class CountryRegionService extends BaseService<CountryRegion> {
    private data: CountryRegion[] | undefined = undefined;

    fromJson(data: any): CountryRegion {
        return CountryRegion.fromJson(data);
    }

    getCachedList(): Promise<CountryRegion[]> {
        return new Promise<CountryRegion[]>(async (resolve, reject) => {
            try {
                if (!this.data) {
                    this.data = await this.getList('/');
                }

                resolve(this.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    getContent(country: string | undefined, region: string | undefined): Promise<CountryRegion[]> {
        return new Promise<CountryRegion[]>(async (resolve, reject) => {
            try {
                const result: CountryRegion[] = [];
                const data = await this.getCachedList();

                data.forEach((item) => {
                    if (item.country === country && !item.region) {
                        result.push(item);
                    }
                });

                if (region) {
                    data.forEach((item) => {
                        if (item.country === country && item.region === region) {
                            result.push(item);
                        }
                    });
                }

                // if no match get the default.
                if (result.length === 0) {
                    data.forEach((item) => {
                        if (item.country === 'default') {
                            result.push(item);
                        }
                    });
                }

                resolve(result);

            } catch (err) {
                reject(err);
            }
        });
    }
}

export default new CountryRegionService('/regiontexts');