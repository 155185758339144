import * as React from "react";
import { TabPane } from "reactstrap";
import UploadButton from "../../components/uploadbutton/UploadButton";
import { FileUploadResponse } from "../../services/FileService";
import WineList from "../../models/WineList";
import { TabNames } from "./AddEditWineListModal";
import "../../styles/TabBigImage.scss";

export const largeStandardWineListImages = [
  "https://api.vinkompassen.se/images/winelists/large/barrels-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/bottles-dark-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/bottles-in-rack-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/bubbles-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/corks-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/corkscrew-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/glasses-mix-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/glasses-rose-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/glasses-white-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/grapes-colourful-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/lying-bottles-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/smell-wine-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/vineyard-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/wine-city.jpg",
  "https://api.vinkompassen.se/images/winelists/large/wine-dinner-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/wine-Food-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/wine-modern-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/wine-white-01.jpg",
  "https://api.vinkompassen.se/images/winelists/large/winecellar-01.jpg",

  // 'https://api.vinkompassen.se/images/winelists/large/mixed-bottles.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/bottles-in-box.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/bottles-in-rack.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/bubbly-served.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/glasses-red.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/glasses-rose.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/glasses-white.jpg',
  // 'https://api.vinkompassen.se/images/winelists/large/lying-bottles.jpg',
];
type Props = {
  winelist: WineList;
  onUpdate: (winelist: WineList) => void;
};
export default class TabBigImage extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (!props.winelist.largeImageUrl) {
      props.winelist.largeImageUrl = largeStandardWineListImages[0];
      props.onUpdate(props.winelist);
    }
  }

  onFileUploaded(e: FileUploadResponse) {
    const w = this.props.winelist;
    w!.largeImageUrl = e.url;
    this.props.onUpdate(w);
    // this.setState({winelist : w});
  }

  _renderThumbs() {
    const activeImageUrl =
      this.props.winelist && this.props.winelist.largeImageUrl;
    return largeStandardWineListImages.map((item, index) => {
      return (
        <div
          key={index}
          onClick={() => {
            const w = this.props.winelist;
            w!.largeImageUrl = item;
            this.props.onUpdate(w);
          }}
          style={{ backgroundImage: `url(${item})` }}
          className={`tab--big-image--image-grid--item ${
            activeImageUrl === item ? "active" : ""
          }`}
        />
      );
    });
  }

  render() {
    const { winelist } = this.props;
    return (
      <TabPane tabId={TabNames.IMAGES_BIG} className={"tab--big-image"}>
        <h4>Nuvarande bild</h4>
        <div className={"tab--big-image--active-image-wrapper"}>
          <div>
            <div
              className={"tab--big-image--active-image"}
              style={{ backgroundImage: `url(${winelist.largeImageUrl})` }}
            />
            <span>Uppladdad originalbild</span>
          </div>
          <div>
            <div
              className={
                "tab--big-image--active-image tab--big-image--active-image--overlay"
              }
              style={{ backgroundImage: `url(${winelist.largeImageUrl})` }}
            />
            <span>Bilden nedmörkad som den ser ut i Vinkompassen</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <UploadButton
              label={"Ladda upp egen bild"}
              id={"big-image"}
              onFileUploaded={(e) => this.onFileUploaded(e)}
              maxWidth={800}
              maxHeight={800}
            />
            <p className={"tab--big-image--upload-text"}>Min 1280 x 640 px</p>
          </div>
        </div>

        <h5 className={"tab--big-image--grid-text"}>
          Eller välj en av våra bilder
        </h5>
        <div className={"tab--big-image--image-grid"}>
          {this._renderThumbs()}
        </div>
      </TabPane>
    );
  }
}
