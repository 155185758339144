// @ts-nocheck
export function distinctArray<T extends any>(data: T[], property: string): T[] {
    const results: T[] = [];
    const map = new Map();

    for (const item of data) {
        if (!map.has(item[property])) {
            map.set(item[property], true);
            results.push(item);
        }
    }

    return results;
}