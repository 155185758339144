import * as React from 'react';
import { ButtonHTMLAttributes } from 'react';
import '../../styles/BasicButton.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: () => void;
}

const BasicButton = (props: Props) => {
    return (
        <button {...props}
                className={'basic-button'}
                onClick={() => props.onClick && props.onClick()}>
            {props.children}
        </button>
    );
};

export default BasicButton;