import * as React from 'react';
import '../../styles/RateButton.scss';
import '../../styles/ActionButton.scss';
import { SmallStarIcon } from '../icons/Icons';

type Props = {
    isSelected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    rateValue?: number;
};

export default class RateButton extends React.PureComponent<Props> {

    _printStars = (count: number) => {
        const maxCount: number = 5;
        const list: any[] = [];
        let i: number = 0;
        for (i; i < count; i++) {
            list.push(
                // filled
                <SmallStarIcon key={`filled-${i}`}/>
            );
        }
        for (i; i < maxCount; i++) {
            list.push(
                // not filled...
                <SmallStarIcon color={'#fff'}  key={`not-filled-${i}`}/>
            );
        }
        return (list);
    }
    render () {
        const {rateValue = 0, onClick, ...rest} = this.props;
       
        return (
            <button {...rest} 
                className={`action-button rate-button`} 
                onClick={() => onClick && onClick()}>
                <figure className={'rate-button--icon'}>
                    {this._printStars(rateValue)}
                </figure>
    
                <span className={'action-button--label'}>{'Provat'}</span>
            </button>
        );
    }
}