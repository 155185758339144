import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AuthenticationProps } from "../WithAuthentication";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import winesService from "../services/WineService";
import Wine, { buildTitle } from "../models/Wine";
import wineListService from "../services/WineListService";
import WineListService from "../services/WineListService";
import wineListItemService from "../services/WineListItemService";
import WineListItemService from "../services/WineListItemService";
import wineNoteService from "../services/WineNoteService";
import { Users } from "../models/User";
import WineListViewModel from "../models/WineListViewModel";
import WineNote from "../models/WineNote";

import "../styles/WineView.scss";
import SystembolagetSign from "../components/systembolaget-sign/SystembolagetSign";
import RoundButton from "../components/buttons/RoundButton";
import noteIcon from "./icon-note.svg";
import listIcon from "./icon-list.svg";
import Button from "../components/buttons/Button";
import {
  AddListIcon,
  EcoIcon,
  EditPenIconThin,
  GrapeIcon,
  MapIcon,
  NewNoteIcon,
  ProcentIcon,
  ShareIcon,
  TrashIcon,
} from "../components/icons/Icons";
import { SmallFlagIcon } from "../components/icons/flags/Flags";
import WineTypeIcon from "../components/icons/wine-type/WineType";
import { PairingsIcon } from "../components/icons/pairings/PairingsIcon";
import WineList from "../models/WineList";
import WineListItemView from "../winelists/WineListItemView";
import BasicButton from "../components/buttons/BasicButton";
import IdParams from "../IdParams";
import WineListPicker from "./WineListPicker";
import WineListItem from "../models/WineListItem";
import Modal from "../components/modal/Modal";
import ShareModal from "../components/share-modal/ShareModal";
import CountryInfo from "./CountryInfo";
import { Link } from "react-router-dom";
import {
  createOwnWineListLink,
  createSpecialWineListLink,
  createWineLink,
  createWineListLink,
} from "../Routes";
import { WineListViewRouteState } from "../myvinos/PrivateWineListView";
import {
  getStateManagerState,
  StateManagerState,
  withStateManager,
} from "../StateManager";
import { frontloadConnect } from "react-frontload";
import DefaultMeta from "../DefaultMeta";
import { buildWebPath } from "../vinkompassen-config";
import WineStock from "../models/WineStock";
import WineRating from "../models/WineRating";
// import ActionButton from '../myvinos/ActionButton';
import RateModal from "./RateModal";
import StockModal from "./StockModal";
import AccountAndAppSign, {
  SignType,
} from "../components/account-and-app-sign/AccountAndAppSign";
import AsideButtonSpacer from "../components/spacers/AsideButtonSpacer";
import ActionButton from "../components/buttons/ActionButton";
import SaveButton from "../components/buttons/SaveButton";
import RateButton from "../components/buttons/RateButton";
import StockButton from "../components/buttons/StockButton";
import StoresForWineModal from "../stores/StoresForWineModal";
import AddEditWineListModal from "../winelists/add-edit-modal/AddEditWineListModal";
import VideoPlayer from "../components/videoplayer/VideoPlayer";
import { getYouTubeId } from "../helpers/YoutubeId";

export const rangeType = (value?: string): string => {
  switch (value) {
    case "BS":
      return "Beställningsvara";
    case "FS":
    case "FSB":
    case "FSN":
    case "FSÖ":
      return "Ordinarie sortiment";
    case "TSE":
      return "Små partier";
    case "TSS":
      return "Säsongsdryck";
    case "TSLS":
      return "Lokalt & småskaligt";
    default:
      return value || "";
  }
};

async function frontload(props: Props): Promise<void> {
  try {
    const {
      stateManager,
      match: { params },
    } = props;
    const id = Number(params.id);

    if (!stateManager) {
      return;
    }

    const currentState = stateManager.get();
    // Check if this is the same wine
    if (currentState && currentState.wine && currentState.wine.id === id) {
      return;
    }

    const wine = await winesService.get(id, true);
    const winelists = await wineListService.forWine(wine);

    if (stateManager) {
      await stateManager.setObject({ wine, winelists });
    }
  } catch (error) {
    console.log("Failed to load product & public list for wine", error);
  }
}

export interface WineViewRouteState extends StateManagerState {
  wine?: Wine;
  winelists?: WineListViewModel;
}

export interface Props
  extends RouteComponentProps<IdParams>,
    AuthenticationProps,
    WineViewRouteState {}

type State = {
  // wineId?: string;
  // wine?: Wine;
  ownLists?: WineListViewModel;
  winelists?: WineListViewModel;
  wineNote?: WineNote;
  wineRating?: WineRating;
  wineStock?: WineStock;
  isSaved: boolean;
  isRated: boolean;
  isStocked: boolean;
  showRatingModal: boolean;
  showStockModal: boolean;

  savedListItem?: WineListItem;
  stockedListItem?: WineListItem;
  ratedListItem?: WineListItem;

  wineComment: string;
  model?: WineListViewModel;
  wineNoteEditorVisible: boolean;
  shareModalVisible: boolean;
  wineListPickerVisible: boolean;
  showStoresModal: boolean;
  newListModalVisible: boolean;
};

class WineView extends React.Component<Props, State> {
  storesElement: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      wineComment: "",
      wineNoteEditorVisible: false,
      wineListPickerVisible: false,
      shareModalVisible: false,
      showStoresModal: false,
      isSaved: false,
      isRated: false,
      isStocked: false,
      showRatingModal: false,
      showStockModal: false,
      newListModalVisible: false,
    };
  }

  componentDidMount(): void {
    this.fetchUserData();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    // Wine was null, but now has value
    if (!prevProps.wine && this.props.wine) {
      this.fetchUserData();
      return;
    }

    // User was null, now has value
    if (!prevProps.authUser && this.props.authUser) {
      this.fetchUserData();
      return;
    }

    // User has changed
    if (
      prevProps.authUser &&
      this.props.authUser &&
      prevProps.authUser.id !== this.props.authUser.id
    ) {
      this.fetchUserData();
      return;
    }
  }

  async fetchUserData() {
    try {
      const { wine, winelists, authUser } = this.props;
      const user = authUser ? authUser : undefined;

      if (!wine) {
        return;
      }

      if (user) {
        const [model, ownedLists] = await Promise.all([
          winesService.getCombined(wine.id!, false),
          this.loadOwnWineLists(),
        ]);

        this.setState({
          winelists,
          ...ownedLists,
          wineNote: model.note,
          wineComment: (model.note && model.note.comment) || "",
          wineRating: model.rating,
          wineStock: model.stock,
          isSaved: model.isSaved || false,
          isRated: model.isRated || false,
          isStocked: model.isStocked || false,
          savedListItem: model.savedListItem,
          stockedListItem: model.stockedListItem,
          ratedListItem: model.ratedListItem,
        });
      } else {
        this.setState({
          winelists,
        });
      }
    } catch (error) {
      console.log("Failed to fetch user lists and notes", error);
    }
  }

  async loadOwnWineLists(): Promise<Pick<State, "ownLists">> {
    try {
      const lists = await wineListService.owned();
      return { ownLists: lists };
    } catch (error) {
      console.log("Failed to load wine lists for wine", error);
      return {};
    }
  }

  onChangeWineNote = (str: string) => {
    // console.log(e);
    this.setState({
      wineComment: str,
    });
  };

  onSaveNote = async () => {
    // console.log('Save note!');
    try {
      const note = this.state.wineNote || new WineNote();
      note.wineId = this.props.wine!.id;
      note.comment = this.state.wineComment;
      const savedNote = await wineNoteService.add(note);
      this.setState({
        wineNote: savedNote,
      });

      this.closeNoteModal();
    } catch (error) {
      console.error(error);
    }
  };

  onDeleteNote = async (id?: number) => {
    this.closeNoteModal();

    if (!id) {
      return;
    }
    try {
      await wineNoteService.remove(id);
      this.setState({
        wineNote: undefined,
        wineComment: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  onCancelNote = () => {
    // console.log('onCancelNote note!');
    this.closeNoteModal();
  };

  closeNoteModal = () => {
    this.setState({ wineNoteEditorVisible: false });
  };

  openNoteModal = () => {
    this.setState({ wineNoteEditorVisible: true });
  };

  openListPicker = () => {
    this.setState({ wineListPickerVisible: true });
  };

  closeListPicker = () => {
    this.setState({ wineListPickerVisible: false });
  };

  onWineListSelected = async (list: WineList) => {
    try {
      const { wine } = this.props;
      if (!wine) {
        return;
      }

      // EventEmitter.emit(ToasterEvents.SHOW, {label: 'Lägger till...', type: 'progress'} as ToasterState);
      const itemList: WineListItem[] =
        (list.id && (await wineListItemService.getItems(list.id))) || [];
      const isWineInList: boolean =
        itemList.find((item: WineListItem) => item.wineId === wine.id) !==
        undefined;

      if (isWineInList) {
        const str: string = `${
          wine.name ? wine.name : "Vinet"
        } finns redan i listan.`;
        alert("Vinet finns redan" + str);
        // EventEmitter.emit(ToasterEvents.HIDE);
      } else {
        await this.addWineToList(wine, list);
        this.closeListPicker();

        const winelists = await wineListService.forWine(wine);
        this.setState({ winelists });

        // EventEmitter.emit(ToasterEvents.SHOW,
        //     {label:'Tillagd i listan!', type:'check', delay:3} as ToasterState);
      }
    } catch (error) {
      // EventEmitter.emit(ToasterEvents.HIDE);
      console.log(error);
    }
  };

  openShareModal = () => {
    this.setState({ shareModalVisible: true });
  };

  closeShareModal = () => {
    this.setState({ shareModalVisible: false });
  };

  onOpenStores = () => {
    this.setState({
      showStoresModal: true,
    });
  };

  onCloseStores = () => {
    this.setState({
      showStoresModal: false,
    });
  };

  onShowRatedModal = () => {
    this.setState({
      showRatingModal: true,
    });
  };

  onShowStockModal = () => {
    this.setState({
      showStockModal: true,
    });
  };

  onAddToSaved = async () => {
    try {
      const { wine, authUser } = this.props;
      if (authUser && wine) {
        await wineListItemService.addToSaved(wine);
        await this.fetchUserData();
      }
    } catch (error) {
      console.log(error);
      alert(
        "Misslyckades att lägga till vinet till din lista med sparade viner"
      );
    }
  };

  onUnSave = async () => {
    try {
      const { wine, authUser } = this.props;
      const { savedListItem } = this.state;
      if (authUser && wine && savedListItem) {
        await wineListItemService.remove(savedListItem);
        await this.fetchUserData();
      }
    } catch (error) {
      console.log(error);
      alert("Misslyckades att tabort vinet till din lista med sparade viner");
    }
  };

  onAddToRated = async (score: number | undefined) => {
    try {
      const { wine, authUser } = this.props;
      const { isRated } = this.state;
      if (authUser && wine) {
        if (!isRated) {
          await wineListItemService.addToRated(wine);
        }
        await winesService.setRating(wine, score || 0);
        await this.fetchUserData();

        this.setState({
          showRatingModal: false,
        });
      }
    } catch (error) {
      console.log(error);
      alert(
        "Misslyckades att lägga till vinet till din lista med provade viner"
      );
    }
  };

  onAddToStocked = async (count: number | undefined) => {
    try {
      const { wine, authUser } = this.props;
      const { isStocked } = this.state;
      if (authUser && wine) {
        if (!isStocked) {
          await wineListItemService.addToWineCooler(wine);
        }
        await winesService.setStock(wine, count || 0);
        await this.fetchUserData();

        this.setState({
          showStockModal: false,
        });
      }
    } catch (error) {
      console.log(error);
      alert(
        "Misslyckades att lägga till vinet till din lista med viner du har hemma"
      );
    }
  };

  onAddNewListModal = async (newList: WineList, wineItems?: WineListItem[]) => {
    try {
      newList = await WineListService.add(newList);

      if (newList) {
        wineItems = this.generateWineItem();
        wineItems = await WineListItemService.updateItems(
          newList.id!,
          wineItems!
        );
        newList.wineCount = wineItems!.length;
      }
      let { ownLists, winelists } = this.state;

      if (ownLists && ownLists.lists) {
        ownLists.lists.unshift(newList);
      }
      if (winelists && winelists.lists) {
        winelists.lists.unshift(newList);
      }

      this.setState({
        winelists,
        ownLists,
        newListModalVisible: false,
      });

      // EventEmitter.emit(EVENT_WINE_LIST_ADDED, newList);
    } catch (error) {
      console.log(error);
    }
  };

  generateWineItem = () => {
    const { wine } = this.props;

    if (!wine) {
      return;
    }

    const item = new WineListItem();
    item.wineId = this.props.wine!.id;
    item.wine = wine;
    return [item];
  };

  _renderWineLists = (list: WineList[], owners: Users, isPublic: boolean) => {
    return list.map((item, index) => {
      const owner = owners[item.userId!];
      let path: string;

      if (item.type) {
        path = createSpecialWineListLink(item);
      } else if (!item.isPublic) {
        path = createOwnWineListLink(item);
      } else {
        path = createWineListLink(item);
      }

      return (
        <Link
          key={item.id}
          className={"link-item"}
          to={{
            pathname: path,
            state: {
              winelist: item,
            } as WineListViewRouteState,
          }}
        >
          <WineListItemView
            key={index}
            winelist={item}
            circleImage={isPublic}
            isOwner={isPublic}
            user={owner}
          />
        </Link>
      );
    });
  };

  _renderWineNote = (note?: WineNote) => {
    const show: boolean =
      (note && note.comment && note.comment.length > 0) || false;

    if (!show) {
      return (
        <React.Fragment>
          <p>Vinet saknar notering.</p>
          <RoundButton
            icon={noteIcon}
            label={"Skapa ny notering"}
            onClick={() => this.openNoteModal()}
          />
        </React.Fragment>
      );
    }

    return (
      <div className={"aside--wine-note"}>
        <div className={"aside--wine-note--header"}>
          <BasicButton onClick={() => this.onDeleteNote(note && note.id)}>
            <TrashIcon />
          </BasicButton>
          <BasicButton onClick={() => this.openNoteModal()}>
            <EditPenIconThin />
          </BasicButton>
        </div>
        <p className={"aside--wine-note--text"}>{note && note.comment}</p>
      </div>
    );
  };

  _renderCartButton = (wine: Wine) => {
    if (wine.isCampaign) {
      return (
        <Button
          label={"Läs mer och beställ"}
          theme={"white"}
          textAlign={"left"}
          useCaret={true}
          onClick={() => window.open(wine.campaignUrl)}
        />
      );
    }

    if (wine.rangeType !== "BS") {
      return (
        <div />
        // <Button
        //     icon={<MapIcon/>}
        //     label={'Hitta på Systembolaget'}
        //     theme={'white'}
        //     textAlign={'left'}
        //     useCaret={true}
        //     onClick={() => this.onOpenStores()}
        // />
      );
    }

    return (
      <div />
      // <Button
      //     label={'Beställ på Systembolaget'}
      //     theme={'white'}
      //     textAlign={'left'}
      //     useCaret={true}
      //     onClick={() => window.open(wine.systembolagetUrl())}
      // />
    );
  };

  render() {
    const { authUser, wine } = this.props;
    const {
      winelists,
      wineNote,
      wineComment,
      wineNoteEditorVisible,
      wineListPickerVisible,
      ownLists,
      shareModalVisible,
      isSaved,
      isRated,
      isStocked,
      wineRating,
      wineStock,
      showRatingModal,
      showStockModal,
      showStoresModal,
      newListModalVisible,
    } = this.state;

    if (!wine) {
      return null;
    }

    const ownList: WineList[] = [];
    const publicList: WineList[] = [];

    if (winelists && winelists.lists) {
      winelists.lists.forEach((list) => {
        if (!list.type && authUser && list.userId === authUser.id) {
          ownList.push(list);
        }
        if (list.isPublic) {
          publicList.push(list);
        }
      });
    }

    const paringsIcons =
      wine &&
      wine.pairingsCleaned &&
      wine.pairingsCleaned.split(",").map((item, index) => {
        return <PairingsIcon key={index} pairing={item} />;
      });

    const youtubeId = wine && wine.videoUrl && getYouTubeId(wine.videoUrl);

    return (
      <Container className={"wine"}>
        <DefaultMeta
          title={buildTitle(wine)}
          description={wine.description}
          // image={wine.productImageUrl}
          canonical={createWineLink(wine)}
        />

        <Row>
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={{ size: 6, offset: 1 }}
            md={{ size: 7, offset: 0 }}
          >
            <Row className={"wine--basic-info"}>
              <Col
                className={""}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h2 className={"wine--basic-info--name2"}>
                          {wine.name2} {wine.vintage! > 0 && wine.vintage}
                        </h2>
                        <h1 className={"wine--basic-info--name"}>
                          {wine.name}
                        </h1>
                        <div className={"wine--basic-info--divider"} />
                      </Col>
                    </Row>

                    <Row>
                      <Col style={{ flexGrow: 1 }}>
                        <p className={"wine--basic-info--price"}>
                          {wine.price} kr
                        </p>
                        <p className={"wine--basic-info--packaging"}>
                          {wine.packagingCleaned}
                        </p>
                        {/* <p className={'wine--basic-info--range'}>{rangeType(wine.rangeType)}</p> */}
                      </Col>
                      {/* <Col className={'text-right align-self-end'} style={{flexGrow: 0}}>
                                                <SystembolagetSign
                                                    size={'big'}
                                                    label={wine.systembolagetArticleNumberExternal}/>
                                            </Col> */}
                    </Row>
                  </Col>

                  <Col
                    className={
                      "wine--basic-info--product-image-wrapper text-center"
                    }
                  >
                    <img
                      className={"wine--basic-info--product-image"}
                      alt={
                        wine.productImageUrl
                          ? `Produktbild på ${wine.name}`
                          : "Produktbild saknas"
                      }
                      src={wine.productImageUrl || require("./buttle.svg")}
                    />
                  </Col>
                </Row>

                <Row
                  className={"wine--basic-info--buttons-wrapper"}
                  style={{ flexGrow: 1, marginTop: "20px" }}
                >
                  <Col>
                    <div
                      className={
                        "wine--basic-info--buttons-wrapper--button-group"
                      }
                    >
                      <ActionButton
                        disabled={!authUser}
                        label={"Notering"}
                        icon={<NewNoteIcon />}
                        onClick={() => this.openNoteModal()}
                      />
                      <ActionButton
                        disabled={!authUser}
                        label={"Lägg i lista"}
                        icon={<AddListIcon />}
                        onClick={() => this.openListPicker()}
                      />
                      <ActionButton
                        label={"Dela"}
                        icon={<ShareIcon />}
                        onClick={() => this.openShareModal()}
                      />
                      <SaveButton
                        disabled={!authUser}
                        isSelected={isSaved}
                        onClick={(isSelected) => {
                          !isSaved ? this.onAddToSaved() : this.onUnSave();
                        }}
                      />
                      <RateButton
                        disabled={!authUser}
                        rateValue={
                          isRated ? wineRating && wineRating.rating : 0
                        }
                        onClick={() => this.onShowRatedModal()}
                      />
                      <StockButton
                        disabled={!authUser}
                        count={isStocked ? wineStock && wineStock.count : 0}
                        onClick={() => this.onShowStockModal()}
                      />
                    </div>
                    <Row style={{ marginTop: "12px" }}>
                      <Col>{this._renderCartButton(wine)}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* <Col className={'align-self-center text-center'}>
                            <img className={'wine--basic-info--product-image'} src={wine.productImageUrl}/>
                        </Col> */}
            </Row>

            {youtubeId && (
              <div style={{ marginLeft: -15, marginRight: -15 }}>
                <VideoPlayer youtubeId={youtubeId} />
              </div>
            )}

            <Row className={"wine--additional-info"}>
              {/* info */}
              <Col>
                <div className={"wine--additional-info--mini-icons"}>
                  <figure className={"wine--additional-info--mini-icons--icon"}>
                    <SmallFlagIcon country={wine.country} />
                  </figure>
                  <span className={"wine--additional-info--mini-icons--label"}>
                    {wine.country}, {wine.region}
                  </span>
                </div>

                <div className={"wine--additional-info--mini-icons"}>
                  <figure className={"wine--additional-info--mini-icons--icon"}>
                    <WineTypeIcon type={wine.type} style={wine.style} />
                  </figure>
                  <span className={"wine--additional-info--mini-icons--label"}>
                    {wine.type}, {wine.style}
                  </span>
                </div>

                {wine.grapesCleaned && (
                  <div className={"wine--additional-info--mini-icons"}>
                    <figure
                      className={"wine--additional-info--mini-icons--icon"}
                    >
                      <GrapeIcon />
                    </figure>
                    <span
                      className={"wine--additional-info--mini-icons--label"}
                    >
                      {wine.grapesCleaned}
                    </span>
                  </div>
                )}

                {wine.ecological && (
                  <div className={"wine--additional-info--mini-icons"}>
                    <figure
                      className={"wine--additional-info--mini-icons--icon"}
                    >
                      <EcoIcon />
                    </figure>
                    <span
                      className={"wine--additional-info--mini-icons--label"}
                    >
                      Ekologiskt
                    </span>
                  </div>
                )}

                <div className={"wine--additional-info--mini-icons"}>
                  <figure className={"wine--additional-info--mini-icons--icon"}>
                    <ProcentIcon />
                  </figure>
                  <span className={"wine--additional-info--mini-icons--label"}>
                    {wine.alcoholContent}
                  </span>
                </div>
                {wine.characteristics && (
                  <div style={{ marginTop: "18px" }}>
                    <strong>Karaktär</strong>
                    <p>{wine.characteristics}</p>
                  </div>
                )}
              </Col>
              {/* pairings */}
              <Col>
                <div>
                  <strong>Passar till</strong>
                  <p>{wine.pairings && wine.pairings.split(",").join(", ")}</p>
                  <div className={"wine--additional-info--pairings"}>
                    {paringsIcons}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <CountryInfo country={wine.country} />
              </Col>
            </Row>
          </Col>
          {/* aside */}
          <Col
            tag={"aside"}
            className={"aside"}
            xl={{ size: 3, offset: 1 }}
            lg={{ size: 4, offset: 0 }}
            md={{ size: 5, offset: 0 }}
          >
            {!authUser && (
              <AccountAndAppSign
                headline={"Inloggning krävs!"}
                text={
                  "För att kunna spara egna noteringar, omdömen och listor behöver du ha ett konto och vara inloggad. Då sparas allt säkert här i din egen vinhubb. Kontot är gratis."
                }
              />
            )}
            {authUser && (
              <React.Fragment>
                <h3>Mina noteringar</h3>
                <div className={"aside--info-wrapper"}>
                  {this._renderWineNote(wineNote)}
                </div>
              </React.Fragment>
            )}
            <div className={"aside--divider"} />
            {authUser && <h3>I mina listor</h3>}
            {ownList.length > 0 &&
              this._renderWineLists(ownList, winelists!.users!, false)}
            {authUser && ownList.length === 0 && (
              <div className={"aside--info-wrapper"}>
                <p>Vinet finns inte i nån egen lista.</p>
                <RoundButton
                  icon={listIcon}
                  label={"Lägg till i lista"}
                  onClick={() => this.openListPicker()}
                />
              </div>
            )}

            {publicList.length > 0 && (
              <React.Fragment>
                <div className={"aside--divider"} />
                <h3>I publika listor</h3>
                {this._renderWineLists(publicList, winelists!.users!, true)}
              </React.Fragment>
            )}

            <AsideButtonSpacer space={30} />
            {authUser && <AccountAndAppSign type={SignType.map} />}
          </Col>
        </Row>

        <RateModal
          isOpen={showRatingModal}
          onSave={this.onAddToRated}
          score={wineRating && wineRating.rating}
        />

        {/* <StockModal isOpen={showStockModal}
                            onSave={this.onAddToStocked}
                            count={wineStock && wineStock.count}/> */}

        {/* <StoresForWineModal wine={wine} isOpen={showStoresModal} onClose={this.onCloseStores}/> */}

        <Modal
          isOpen={wineNoteEditorVisible}
          buttons={[
            {
              label: "Spara",
              style: "primary",
              onClick: () => this.onSaveNote(),
            },
            { label: "Avbryt", onClick: () => this.onCancelNote() },
          ]}
        >
          <Form>
            <FormGroup>
              <Input
                rows={10}
                type="textarea"
                name="text"
                placeholder={"Notering"}
                value={wineComment}
                onChange={(e: any) => {
                  this.onChangeWineNote(e.target.value);
                }}
              />
            </FormGroup>
          </Form>
        </Modal>

        <Modal isOpen={wineListPickerVisible} className={"wine-list-picker"}>
          {authUser && (
            <WineListPicker
              model={ownLists}
              owner={authUser}
              onAdd={() =>
                this.setState({
                  newListModalVisible: true,
                  wineListPickerVisible: false,
                })
              }
              onCancel={() => this.closeListPicker()}
              onListSelected={(list) => this.onWineListSelected(list)}
            />
          )}
        </Modal>

        <ShareModal
          isOpen={shareModalVisible}
          shareUrl={buildWebPath(createWineLink(wine))}
          onClosed={() => this.closeShareModal()}
          title={"Dela"}
          emailMessage={{
            subject: "Vintips via Vinkompassen",
            message: `Jag vill tipsa dig om vinet ${wine.name}. Klicka på länken nedan för mer info om vinet i Vinkompassen.`,
          }}
        />

        <AddEditWineListModal
          wineItems={this.generateWineItem()}
          isOpen={newListModalVisible}
          onCancel={() => this.setState({ newListModalVisible: false })}
          onSave={(list: WineList, wineItems?: WineListItem[]) =>
            this.onAddNewListModal(list, wineItems)
          }
        />
      </Container>
    );
  }

  private async addWineToList(wine: Wine, list: WineList) {
    try {
      const item = new WineListItem();
      item.wineListId = list.id;
      item.wineId = wine.id;
      await wineListItemService.add(item);
    } catch (error) {
      console.log("Failed to add wine to list", error);
    }
  }
}

export default withRouter<Props, React.ComponentType<Props>>(
  withStateManager(
    frontloadConnect(frontload, { onUpdate: true })((props) => (
      <WineView {...getStateManagerState(props)} />
    ))
  )
);
