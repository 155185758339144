const apiPrefix = "api/v1/";
const webEndPoint = "https://vinkompassen.se/vin/";
const serverEndPoint = process.env.REACT_APP_API_URL;
const apiEndPoint = serverEndPoint + apiPrefix;

export default {
  serverEndPoint,
  apiEndPoint,
  webEndPoint,
};

export function buildApiPath(relativePath: string): string {
  let path: string = relativePath;

  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  if (path.startsWith(apiPrefix)) {
    return serverEndPoint + path;
  } else {
    return apiEndPoint + path;
  }
}

export function buildWebPath(relativePath: string): string {
  let path: string = relativePath;

  if (path.indexOf("://") !== -1) {
    return path;
  }

  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  return webEndPoint + path;
}
