import * as React from 'react';
import users from '../services/UserService';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import BasicButton from '../components/buttons/BasicButton';
import AuthUserContext from '../AuthUserContext';

interface Props extends RouteComponentProps {

}

type State = {
    email: string,
    passwordSent: boolean
};

class ForgotPasswordView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            passwordSent: false
        };
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            await users.resetPassword(this.state.email);

            this.setState({passwordSent: true});
        } catch (error) {
            console.log(error);
            alert(error.message);
        }
    }

    render() {
        return (
            <AuthUserContext.Consumer>
                {context =>
                    <Container>
                        <Row>
                            <Col>
                                <h2>Återställ lösenord</h2>
                                {!this.state.passwordSent && (
                                    <Form onSubmit={this.onSubmit}>
                                        <FormGroup>
                                            <Label>E-postadress:</Label>
                                            <Input type={'email'}
                                                   value={this.state.email}
                                                   onChange={(e) => this.setState({email: e.target.value})}
                                                   placeholder={'E-postadress'}/>
                                        </FormGroup>

                                        <BasicButton type={'submit'}>Återställ lösenord</BasicButton>
                                    </Form>
                                )}
                                {this.state.passwordSent && (
                                    <div>
                                        <p>Vi har skickat ett nytt lösenord till din registrerade mailadress.</p>
                                        <BasicButton onClick={() => context.hideAccountModal()}>
                                            Stäng
                                        </BasicButton>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                }
            </AuthUserContext.Consumer>
        );
    }
}

export default withRouter(ForgotPasswordView);