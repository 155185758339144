import * as React from 'react';
import { FormGroup } from 'reactstrap';
import WineList from '../models/WineList';
import WineListItemView from '../winelists/WineListItemView';
import User from '../models/User';
import Button from '../components/buttons/Button';
import WineListViewModel from '../models/WineListViewModel';

type Props = {
    model?: WineListViewModel;
    owner: User;
    onListSelected: (list: WineList) => void;
    onAdd: () => void;
    onCancel: () => void;
};

const WineListPicker = (props: Props) => {
    const {model, owner, onAdd, onCancel, onListSelected} = props;
    const _renderWineLists = model &&
        model.lists &&
        model.lists
            .filter(x => !x.type)
            .map((item, index) => {
                return (
                    <WineListItemView key={index}
                                      winelist={item}
                                      isOwner={true}
                                      circleImage={false}
                                      user={owner}
                                      onClick={() => onListSelected(item)}/>
                );
            });

    return (
        <React.Fragment>
            <FormGroup className={'wine--modal-add-to-list--list-content'}>
                {_renderWineLists}
            </FormGroup>
            <FormGroup>
                <Button label={'Skapa ny lista'} theme={'pink'} textAlign={'center'}
                        onClick={() => onAdd()}
                />
            </FormGroup>
            <FormGroup>
                <Button label={'Avbryt'} textAlign={'center'} onClick={() => onCancel()}/>
            </FormGroup>
        </React.Fragment>
    );
};

export default WineListPicker;