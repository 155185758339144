import { Users, UsersConverter } from './User';
import WineList from './WineList';
import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import Feed from './Feed';

@JsonObject
export default class WineListViewModel extends Assignable<Feed> implements JsonSerializable {
    @JsonProperty('lists', [WineList], true)
    lists?: WineList[] = undefined;

    @JsonProperty('users', UsersConverter, true)
    users?: Users = undefined;

    @JsonProperty('liked', [Number], true)
    liked?: number[] = undefined;

    static fromJson(data: any): WineListViewModel {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, WineListViewModel) as WineListViewModel;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}