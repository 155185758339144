import BaseService, { emitEventAction } from './BaseService';
import WineNote from '../models/WineNote';
import Wine from '../models/Wine';
import EventEmitter from './EventEmitter';
import { RequireAuthentication } from './RequestAuthenticationDecorator';

export const WineNoteChanged = 'WineNoteChanged';
export const WineNoteRemoved = 'WineNoteRemoved';

function emitWineNoteChangedEvent(data: any): Promise<void> {
    return new Promise<void>(resolve => {
        const note = WineNote.fromJson(data);
        EventEmitter.emit(WineNoteChanged, note);

        resolve();
    });
}

export type HasWineNoteChecker = (wine: Wine) => boolean;

class WineNoteService extends BaseService<WineNote> {

    constructor() {
        super(`winenotes`);
    }

    fromJson(data: any): WineNote {
        return WineNote.fromJson(data);
    }

    @RequireAuthentication
    add(data: WineNote): Promise<WineNote> {
        return super.single('post', '/', data.toJson(), emitWineNoteChangedEvent);
    }

    @RequireAuthentication
    update(data: WineNote): Promise<WineNote> {
        return super.single('post', '/', data.toJson(), emitWineNoteChangedEvent);
    }

    @RequireAuthentication
    remove(id: number): Promise<void> {
        return super.r('delete', `/${id}`, emitEventAction(WineNoteRemoved, id));
    }

    @RequireAuthentication
    forWine(wine: Wine): Promise<WineNote> {
        return super.single('get', `/wine/${wine.id}`);
    }

    // Returns a function that can check if a wine has a wine note created by signed in user.
    //
    // Create function and store as a member variable
    // const hasWineNoteChecker = await service.createHasWineNoteCheckerFunction();
    //
    // Later use it like:
    // const hasWineNote = hasWineNoteChecker(wine);
    //
    @RequireAuthentication
    createHasWineNoteCheckerFunction(): Promise<HasWineNoteChecker> {
        return new Promise<HasWineNoteChecker>(async (resolve, reject) => {
            try {
                const notes = await this.getList('/');

                resolve((wine: Wine) => {
                    return notes.filter(x => x.wineId === wine.id && x.comment).length > 0;
                });
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new WineNoteService();
