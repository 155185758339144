import { Any, JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { WineDataRanking } from './WineStatistic';
import DateConverter from './DateConverter';
import { buildWebPath } from '../vinkompassen-config';
import { createWineLink } from '../Routes';

export function wineRanking(wine: Wine): WineDataRanking {
    if (wine.ignored) {
        return WineDataRanking.ignored;
    }

    if (wine.hasLargeImage &&
        Boolean(wine.packaging) &&
        (Boolean(wine.pairingsCleaned) || Boolean(wine.pairings)) &&
        Boolean(wine.grapes) &&
        Boolean(wine.characteristics) &&
        Boolean(wine.type)) {
        return WineDataRanking.good;
    }

    if (Boolean(wine.productImageUrl) &&
        Boolean(wine.packaging) &&
        (Boolean(wine.pairingsCleaned) || Boolean(wine.pairings)) &&
        Boolean(wine.type)) {
        return WineDataRanking.ok;
    }

    return WineDataRanking.bad;
}

export function buildTitle(wine: Wine): string {
    let title = '';

    if (wine.name2) {
        title += wine.name2 + ' ';
    }

    title += wine.name;

    if (wine.vintage) {
        title += ` | ${wine.vintage}`;
    }

    if (wine.price) {
        title += ` | ${wine.price} kr`;
    }

    title += ` | ${wine.type}`;

    return title;
}

export function buildSystembolagetUrl(wine: Wine): string {
    return `https://www.systembolaget.se/${wine.systembolagetArticleNumberExternal}`;
}

@JsonObject
export class ProductImage {
    @JsonProperty('id', String, true)
    id?: string = undefined;

    @JsonProperty('url', String, true)
    url?: string = undefined;

    @JsonProperty('width', Number, true)
    width?: number = undefined;

    @JsonProperty('height', Number, true)
    height?: number = undefined;

    @JsonProperty('resolution', Number, true)
    resolution?: number = undefined;
}

@JsonObject
export default class Wine {
    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('systembolagetArticleNumberInternal', String, true)
    systembolagetArticleNumberInternal?: string = undefined;

    @JsonProperty('systembolagetArticleNumberExternal', String, true)
    systembolagetArticleNumberExternal?: string = undefined;

    @JsonProperty('articleId', String, true)
    articleId?: string = undefined;

    @JsonProperty('name', String, true)
    name?: string = undefined;

    @JsonProperty('name2', String, true)
    name2?: string = undefined;

    @JsonProperty('vintage', Number, true)
    vintage?: number = undefined;

    @JsonProperty('country', String, true)
    country?: string = undefined;

    @JsonProperty('region', String, true)
    region?: string = undefined;

    @JsonProperty('price', Number, true)
    price?: number = undefined;

    @JsonProperty('volume', Number, true)
    volume?: number = undefined;

    @JsonProperty('type', String, true)
    type?: string = undefined;

    @JsonProperty('style', String, true)
    style?: string = undefined;

    @JsonProperty('rangeType', String, true)
    rangeType?: string = undefined;

    @JsonProperty('producer', String, true)
    producer?: string = undefined;

    @JsonProperty('importer', String, true)
    importer?: string = undefined;

    @JsonProperty('imageUrl', String, true)
    imageUrl?: string = undefined;

    @JsonProperty('videoUrl', String, true)
    videoUrl?: string = undefined;

    @JsonProperty('scrapedUrl', String, true)
    scrapedUrl?: string = undefined;

    @JsonProperty('description', String, true)
    description?: string = undefined;

    @JsonProperty('characteristics', String, true)
    characteristics?: string = undefined;

    @JsonProperty('grapes', String, true)
    grapes?: string = undefined;

    @JsonProperty('grapesCleaned', String, true)
    grapesCleaned?: string = undefined;

    @JsonProperty('pairings', String, true)
    pairings?: string = undefined;

    @JsonProperty('pairingsCleaned', String, true)
    pairingsCleaned?: string = undefined;

    @JsonProperty('pairingsArray', [String], true)
    pairingsArray?: string[] = undefined;

    @JsonProperty('packaging', String, true)
    packaging?: string = undefined;

    @JsonProperty('packagingCleaned', String, true)
    packagingCleaned?: string = undefined;

    @JsonProperty('additionalInfo', String, true)
    additionalInfo?: string = undefined;

    @JsonProperty('veganFriendly', Boolean, true)
    veganFriendly?: boolean = undefined;

    @JsonProperty('labels', [String], true)
    labels?: string[] = [];

    @JsonProperty('priceSegment', String, true)
    priceSegment?: string = undefined;

    @JsonProperty('ranking', Number, true)
    ranking?: number = undefined;

    @JsonProperty('views', Number, true)
    views?: number = 0;

    @JsonProperty('boost', Number, true)
    boost?: number = 0;

    @JsonProperty('shares', Number, true)
    shares?: number = 0;

    @JsonProperty('inPublicLists', Number, true)
    inPublicLists?: number = 0;

    @JsonProperty('stores', [String], true)
    stores?: string[] = [];

    @JsonProperty('productThumbnailUrl', String, true)
    productThumbnailUrl?: string = undefined;

    @JsonProperty('productThumbnailId', String, true)
    productThumbnailId?: string = undefined;

    @JsonProperty('productImageUrl', String, true)
    productImageUrl?: string = undefined;

    @JsonProperty('productImageId', String, true)
    productImageId?: string = undefined;

    @JsonProperty('productImageWidth', Number, true)
    productImageWidth?: number = undefined;

    @JsonProperty('productImageHeight', Number, true)
    productImageHeight?: number = undefined;

    @JsonProperty('scraper', String, true)
    scraper?: string = undefined;

    @JsonProperty('hasLabel', Boolean, true)
    hasLabel?: boolean = undefined;

    @JsonProperty('hasImage', Boolean, true)
    hasImage?: boolean = undefined;

    @JsonProperty('campaignStart', DateConverter, true)
    campaignStart?: Date = undefined;

    @JsonProperty('campaignEnd', DateConverter, true)
    campaignEnd?: Date = undefined;

    @JsonProperty('campaignUrl', Any, true)
    campaignUrl?: string = undefined;

    @JsonProperty('systembolagetImportDate', DateConverter, true)
    systembolagetImportDate?: Date = undefined;

    @JsonProperty('importerImportDate', DateConverter, true)
    importerImportDate?: Date = undefined;

    @JsonProperty('manuallyEditedDate', DateConverter, true)
    manuallyEditedDate?: Date = undefined;

    @JsonProperty('avoidImport', Boolean, true)
    avoidImport?: boolean = undefined;

    @JsonProperty('ignored', Boolean, true)
    ignored?: boolean = undefined;

    @JsonProperty('inOpis', Boolean, true)
    inOpis?: boolean = undefined;

    @JsonProperty('ecological', Boolean, true)
    ecological?: boolean = false;

    @JsonProperty('alcoholContent', String, true)
    alcoholContent?: string = undefined;

    public get hasLargeImage(): boolean {
        return Boolean(this.productImageUrl) && Boolean(this.productImageHeight) && this.productImageHeight! >= 500;
    }

    public get isCampaign(): boolean {
        if (!!this.campaignStart && !!this.campaignEnd) {
            const now = new Date();
            return this.campaignStart <= now && this.campaignEnd >= now;
        }

        return false;
    }

    systembolagetUrl(): string {
        return buildSystembolagetUrl(this);
    }

    shareUrl(): string {
        return buildWebPath(createWineLink(this));
    }

    static fromJson(data: any): Wine {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.deserializeObject(data, Wine);
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}

export const wineAttributes: string[] = function () {
    const wine = new Wine();
    return Object.keys(wine);
}();
