import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import { Users, UsersConverter } from './User';
import Feed, { FeedsConverter, FeedsMap } from './Feed';
import DatesMapConverter, { DatesMap } from './DatesMapConverter';

@JsonObject
export default class FeedsViewModel extends Assignable<FeedsViewModel> implements JsonSerializable {
    @JsonProperty('feeds', [Feed], true)
    feeds?: Feed[] = undefined;

    @JsonProperty('comments', FeedsConverter, true)
    comments?: FeedsMap = undefined;

    @JsonProperty('users', UsersConverter, true)
    users?: Users = undefined;

    @JsonProperty('liked', DatesMapConverter, true)
    liked?: DatesMap = undefined;

    @JsonProperty('read', DatesMapConverter, true)
    read?: DatesMap = undefined;

    static fromJson(data: any): FeedsViewModel {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, FeedsViewModel) as FeedsViewModel;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}

export function addFeedLike(model: FeedsViewModel, id: number, count: number): FeedsViewModel {
    const likeMap = {[id]: new Date()};
    if (model.liked) {
        Object.assign(model.liked, likeMap);
    } else {
        model.liked = likeMap;
    }

    return updateFeedProperty(model, id, 'likes', count);
}

export function removeFeedLike(model: FeedsViewModel, id: number, count: number): FeedsViewModel {
    if (model.liked) {
        delete model.liked[id];
    }

    return updateFeedProperty(model, id, 'likes', count);
}

export function markFeedRead(model: FeedsViewModel, id: number): FeedsViewModel {
    const readMap = {[id]: new Date()};
    if (model.read) {
        Object.assign(model.read, readMap);
    } else {
        model.read = readMap;
    }

    return model;
}

export function addFeed(model: FeedsViewModel, feed: Feed, markRead: boolean): FeedsViewModel {
    if (feed.parentId) {
        const comments = model.comments![feed.parentId!];
        if (comments) {
            comments.push(feed);
        } else {
            model.comments![`${feed.parentId}`] = [feed];
        }
    } else {
        model.feeds = [feed, ...model.feeds!];
    }

    if (markRead) {
        return markFeedRead(model, feed.parentId || feed.id!);
    }

    return model;
}

export function mergeFeedsViewModels(a: FeedsViewModel, b: FeedsViewModel): FeedsViewModel {
    b.feeds!.forEach(x => a.feeds!.push(x));
    // .sort((f1, f2) => f1.modifiedAt!.valueOf() - f2.modifiedAt!.valueOf());
    // Object.assign(a.comments, b.comments);
    // Object.assign(a.liked, b.liked);
    // Object.assign(a.read, b.read);
    // Object.assign(a.users, b.users);

    return a;
}

export function updateFeedProperty<K extends keyof Feed>(
    model: FeedsViewModel,
    id: number,
    propName: K,
    propValue: Feed[K]): FeedsViewModel {
    if (!model.comments) {
        return model;
    }

    const feeds = model.feeds || [];
    const comments: Feed[] = ([] as Feed[]).concat(...Object.values(model.comments!));

    feeds.concat(comments).filter(x => x.id === id).forEach(x => {
        x[propName] = propValue;
    });

    return model;
}