import * as React from 'react';
import { withRouter } from 'react-router';
import { frontloadConnect } from 'react-frontload';
import withAuthentication from '../WithAuthentication';
import { getStateManagerState, withStateManager } from '../StateManager';
import { MyVinosProps } from './MyVinosLayout';
import { NewWineListIcon } from '../components/icons/Icons';
import AddEditWineListModal from '../winelists/add-edit-modal/AddEditWineListModal';
import WineList from '../models/WineList';
import WineListItem from '../models/WineListItem';
import WineListItemService from '../services/WineListItemService';
import EventEmitter from '../services/EventEmitter';
import WineListService from '../services/WineListService';
import WineListViewModel from '../models/WineListViewModel';
import WineListWidget from './WineListWidget';
import DefaultMeta from '../DefaultMeta';
import ActionButton from '../components/buttons/ActionButton';

type State = {
    isEditMode: boolean;
    isModalVisible: boolean;
    wineLists?: WineListViewModel
};

export const EVENT_WINE_LIST_ADDED: string = 'eventWineListAdded';

async function frontload(props: MyVinosProps): Promise<void> {
    //
}

class PrivateWineListsView extends React.PureComponent<MyVinosProps, State> {
    constructor(props: MyVinosProps) {
        super(props);
        this.state = {
            isEditMode: false,
            isModalVisible: false,
            wineLists: props.combined && props.combined.winelists
        };
    }

    componentWillReceiveProps(newProps: MyVinosProps) {
       
        if (newProps.combined) {
            // console.log('componentWillReceiveProps -> ', newProps.combined.winelists);
            this.setState({
                wineLists: newProps.combined.winelists
            });
            this.forceUpdate();
        }
    }

    toggleEdit = () => {
        this.setState((prevState: State) => ({
            isEditMode: !prevState.isEditMode
        }));
    }
    createNewList = () => {
        this.setState({isModalVisible: true});
    }

    onCancelAddEditModal = () => {
        this.setState({isModalVisible: false});
    }
    onSaveAddEditModal = async (newList: WineList, wineItems?: WineListItem[]) => {
        try {
           
            this.setState({
                isModalVisible: false
            });

            newList = await WineListService.add(newList);

            if (wineItems && newList) {
                wineItems = await WineListItemService.updateItems(newList.id!, wineItems);
                newList.wineCount = wineItems.length;
            }

            EventEmitter.emit(EVENT_WINE_LIST_ADDED, newList);
        
        } catch (error) {
            console.log(error);
        }
    }
    // onDeleteWinelist = (list: WineList) => {
    //     // 
    // }
    render() {        
        const {isModalVisible, wineLists } = this.state;

        return (
            <div>
                <DefaultMeta title={'Egna listor'}
                    description={'Lorum ipsum...'}/>
                <h3 style={{display: 'flex'}}>Egna listor </h3>
                
                <ActionButton
                    className={'d-md-flex d-sm-none'}
                    style = {{margin: 10, marginLeft: 'auto', marginRight: 'auto'}}
                    icon={<NewWineListIcon />}
                    label={'Skapa ny'}
                    onClick={() => this.createNewList()}/>
                
                <WineListWidget 
                    type={'own'}
                    defaultText={'Här kan du skapa egna listor'}
                    model={wineLists}  />

                <AddEditWineListModal
                    isOpen={isModalVisible}
                    onCancel={() => this.onCancelAddEditModal()}
                    onSave={(list: WineList, wineItems?: WineListItem[]) => this.onSaveAddEditModal(list, wineItems)}
                />
            </div>
        );
    }
}

export default withRouter(
    withAuthentication(
        withStateManager(
            frontloadConnect(frontload)(props => (
                    <PrivateWineListsView {...getStateManagerState(props)}/>
                )
            )
        )
    )
);