import Wine from '../models/Wine';
import BaseService from './BaseService';
import WineCombined from '../models/WineCombined';
import { RequireAuthentication } from './RequestAuthenticationDecorator';
import NodeCache from 'node-cache';

export type WineMetaData = {
    wineTypes: string[],
    pairings: string[],
    grapes: string[],
    recipeTypes: string[],
    recipePairings: string[]
};

class WineService extends BaseService<Wine> {
    cache = new NodeCache({checkperiod: 300, stdTTL: 300});

    constructor() {
        super('/wines');
    }

    fromJson(data: any): Wine {
        return Wine.fromJson(data);
    }

    boost(articleIds: string[]): Promise<number> {
        return super.r(
            'post',
            '/inopis',
            {articleIds});
    }

    metadata(): Promise<WineMetaData> {
        return super.r('get', '/metadata');
    }

    get(id: number, incrementViewsCounter: boolean = false): Promise<Wine> {
        return new Promise<Wine>(async (resolve, reject) => {
            try {
                const wines = await this.getAsArray(id, incrementViewsCounter);
                if (wines.length > 0) {
                    resolve(wines[0]);
                } else {
                    reject();
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    async getCached(id: number): Promise<Wine> {
        const key = `wine-${id}`;
        const value = this.cache.get<Wine>(key);
        if (value) {
            return value;
        } else {
            const model = await this.get(id);
            this.cache.set<Wine>(key, model);
            return model;
        }
    }

    getAsArray(id: number, incrementViewsCounter: boolean = false): Promise<Wine[]> {
        return super.getList(`/${id}?incrementViewsCounter=${incrementViewsCounter}`);
    }

    getCombined(id: number, incrementViewsCounter: boolean = false): Promise<WineCombined> {
        return new Promise<WineCombined>(async (resolve, reject) => {
            try {
                const data = await this.r(
                    'get',
                    `/${id}/combined?incrementViewsCounter=${incrementViewsCounter}`);
                const model = WineCombined.fromJson(data);

                resolve(model);
            } catch (error) {
                reject(error);
            }
        });
    }

    getBySystembolagetId(id: string): Promise<Wine> {
        return new Promise<Wine>(async (resolve, reject) => {
            try {
                const wines = await this.getBySystembolagetIdAsArray(id);
                if (wines.length > 0) {
                    resolve(wines[0]);
                } else {
                    reject(new Error('WineNotFound'));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    getBySystembolagetIdAsArray(id: string): Promise<Wine[]> {
        return super.getList(`/systemet/${id}`);
    }

    share(wine: Wine): Promise<Wine> {
        return super.single('post', `/share/${wine.id!}`);
    }

    @RequireAuthentication
    setRating(wine: Wine, rating: number): Promise<void> {
        return super.r(
            'post',
            `/rate/${wine.id!}`,
            {rating});
    }

    @RequireAuthentication
    setStock(wine: Wine, count: number): Promise<void> {
        return super.r(
            'post',
            `/stock/${wine.id!}`,
            {count});
    }
}

export default new WineService();
