import * as React from 'react';
import '../../styles/ActionButton.scss';

type Props = {
    disabled?: boolean;
    onClick?: () => void;
    icon: any;
    label: string;
    iconColor?: string;
    style?: React.CSSProperties;
    className?: string;
};

export default class ActionButton extends React.PureComponent<Props> {

    render () {
        const {className, label, icon, onClick, iconColor = '#E95003',  ...rest} = this.props;
        return (
            <button {...rest} 
                className={`action-button ${className}`} 
                onClick={() => onClick && onClick()}>
                <figure className={'action-button--icon'}>
                    {React.cloneElement(
                        icon,
                        {color: (!this.props.disabled) ? iconColor : '#1E1E1E'}
                    )}
                </figure>
                <span className={'action-button--label'}>{label}</span>
            </button>
        );
    }
}