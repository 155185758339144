import * as React from 'react';
import '../../styles/RoundButton.scss';

type Props = {
    label: string;
    icon: any;
    onClick?: () => void;
    invert?: boolean;
};
/**
 *
 * @param props:Props
 */
const RoundButton = (props: Props) => {
    const {label, onClick, invert} = props;
    const Icon = props.icon;
    return (
        <div className={`round-button ${(invert) ? 'inverted' : ''}`}
             onClick={() => onClick && onClick()}>
            <figure className={'round-button--icon-container'}>
            <div className={'round-button--icon-container--green-circle'}/>
                {(typeof Icon === 'string')
                    ? <img src={Icon} alt={''}/>
                    : <Icon/>
                }
                
            </figure>
            <p className={'round-button--label'}>{label}</p>
        </div>
    );
};
export default RoundButton;