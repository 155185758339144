import * as React from 'react';
import users from '../services/UserService';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Button from '../components/buttons/Button';
import AuthUserContext from '../AuthUserContext';
import ChevronLink from '../ChevronLink';
import { EmailNotVerified, SessionResponse } from '../models/SessionResponse';
import { AccountModalContext, AccountModalContextProps } from './AccountModalContext';

interface Props extends RouteComponentProps, AccountModalContextProps {

}

type State = {
    email: string,
    password: string,
};

class LoginView extends React.Component<Props, State> {
    static contextType = AccountModalContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        };
    }

    onLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const session = await users.login({
                email: this.state.email,
                password: this.state.password
            });

            if (session.message === EmailNotVerified) {
                this.showVerifyModal(session);
            } else if (!session.authenticated) {
                alert(this.friendlyErrorMessage(session));
            }
        } catch (error) {
            console.log(error);
            if (error.message === EmailNotVerified) {
                this.showVerifyModal({token: '', authenticated: false});
            } else {
                alert(this.friendlyErrorMessage(error));
            }
        }
    }

    showVerifyModal(session: SessionResponse) {
        // noinspection JSDeprecatedSymbols
        const {onShowScreen} = this.context as AccountModalContextProps;
        onShowScreen('verifyemail', {
            token: session.token,
            email: this.state.email
        });
    }

    friendlyErrorMessage = (error: Error | SessionResponse) => {
        switch (error.message) {
            case EmailNotVerified:
                return 'Ditt konto är inte verifierat. Kontrollera din e-post och klicka på aktiveringslänken i meddelandet.';
            case 'InvalidPassword':
            case 'InvalidEmail':
                return 'E-postadressen eller lösenordet är felaktigt.';
            default:
                return error.message;
        }
    }

    render() {
        return (
            <AuthUserContext.Consumer>
                {context =>
                    <Container>
                        <Row>
                            <Col>
                                <h2 id={'modal-title'}>Logga in</h2>
                                <Form onSubmit={this.onLogin}>
                                    <FormGroup>
                                        <Label id={'email'}>E-postadress:</Label>
                                        <Input type={'email'}
                                               aria-labelledby={'email'}
                                               value={this.state.email}
                                               onChange={(e) => this.setState({email: e.target.value})}
                                               placeholder={'E-postadress'}/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Lösenord:</Label>
                                            </Col>
                                            <Col xs={6} className={'text-right'}>
                                                <ChevronLink to={''} onClick={() => {
                                                    context.showAccountModal('resetpassword');
                                                    return false;
                                                }}>Glömt lösenordet?</ChevronLink>
                                            </Col>
                                        </Row>
                                        <Input type={'password'}
                                               value={this.state.password}
                                               onChange={(e) => this.setState({password: e.target.value})}
                                               placeholder={'Lösenord'}/>
                                    </FormGroup>

                                    <Button theme={'pink'} type={'submit'}>
                                        Logga in
                                    </Button>

                                    <br/>

                                    <ChevronLink to={''} onClick={() => {
                                        context.showAccountModal('signup');
                                    }}>Skapa konto</ChevronLink>

                                    {/*<TextDivider>eller</TextDivider>*/}

                                    {/*<Button theme={'pink'} type={'submit'}>Logga in med Facebook</Button>*/}
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                }
            </AuthUserContext.Consumer>
        );
    }
}

export default withRouter(LoginView);