 // @ts-nocheck
import { SessionResponse } from '../models/SessionResponse';
import AsyncStorage from './AsyncStorage';
import User from '../models/User';
import BaseService, {
    defaultPagingOptions,
    emitEventAction,
    getToken,
    httpHeaders,
    PagingOptions,
    throwIfNotOk,
    UserKey,
    UserTokenKey
} from './BaseService';
import { EventEmitter } from 'events';
import { RequireAuthentication } from './RequestAuthenticationDecorator';
import NodeCache from 'node-cache';

export const AuthenticationStateEvent = 'AuthenticationStateEvent';
export const UserFollowed = 'UserFollowed';
export const UserUnfollowed = 'UsertUnfollowed';

export type HasFollowUserChecker = (user: User) => boolean;

export class UserService extends BaseService<User> {
    private _emitter = new EventEmitter();
    cache = new NodeCache({checkperiod: 300, stdTTL: 300});

    constructor() {
        super(`users`);
    }

    addAuthenticationStateListener(listener: (user: User | null) => void) {
        this._emitter.addListener(AuthenticationStateEvent, listener);
    }

    removeAuthenticationStateListener(listener: (user: User | null) => void) {
        this._emitter.removeListener(AuthenticationStateEvent, listener);
    }

    fromJson(data: any): User {
        return User.fromJson(data);
    }

    token = getToken;

    tokenSync(): string | undefined {
        try {
            return localStorage.getItem(UserTokenKey) || undefined;
        } catch {
            return undefined;
        }
    }

    async isAuthenticated(): Promise<boolean> {
        try {
            return !!(await this.token());
        } catch (_) {
            return false;
        }
    }

    login(data: LoginOptions): Promise<SessionResponse> {
        return new Promise<SessionResponse>(async (resolve, reject) => {
            const url = `${this.baseUrl}/login`;
            const headers = await httpHeaders();

            try {
                await this.logout();

                const res = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data)
                });

                await throwIfNotOk(res);

                const json = (await res.json()) as SessionResponse;

                if (json.authenticated) {
                    await AsyncStorage.setItem(UserTokenKey, json.token!);

                    const user = await this.current();

                    this._emitter.emit(AuthenticationStateEvent, user);

                    resolve(json);
                } else {
                    this._emitter.emit(AuthenticationStateEvent, undefined);
                    resolve(json);
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    signup(data: SignupOptions): Promise<SessionResponse> {
        return new Promise<SessionResponse>(async (resolve, reject) => {
            const url = `${this.baseUrl}/signup`;
            const headers = await httpHeaders();

            try {
                await this.logout();

                const res = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data)
                });

                await throwIfNotOk(res);

                const json = (await res.json()) as SessionResponse;

                if (json.authenticated) {
                    await AsyncStorage.setItem(UserTokenKey, json.token!);
                    const user = await this.current();

                    this._emitter.emit(AuthenticationStateEvent, user);

                    resolve(json);
                } else {
                    resolve(json);
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    logout(): Promise<void> {
        return super.r('post', '/logout', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
            this._emitter.emit(AuthenticationStateEvent, undefined);
        });
    }

    logoutEverywhere(): Promise<void> {
        return super.r('post', '/logouteverywhere', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
            this._emitter.emit(AuthenticationStateEvent, undefined);
        });
    }

    getUser(id: number): Promise<User> {
        return super.getSingle(id);
    }

    async getCached(id: number): Promise<User> {
        const key = `user-${id}`;
        const value = this.cache.get<User>(key)
        if (value) {
            return value;
        } else {
            const user = await this.getUser(id);
            this.cache.set<User>(key, user);
            return user;
        }
    }

    currentSync(): User | undefined {
        try {
            const cachedUserJson = localStorage.getItem(UserKey);
            if (cachedUserJson) {
                return User.fromJson(JSON.parse(cachedUserJson));
            }
        } catch (e) {
            console.log('Failed to load user from cache', e);
        }

        return undefined;
    }

    current(resolveAnonymousAsUndefined: boolean = false): Promise<User> {
        return new Promise<User>(async (resolve, reject) => {
            try {
                await AsyncStorage.getItem(UserTokenKey);
            } catch (e) {
                if (resolveAnonymousAsUndefined) {
                    resolve(undefined);
                } else {
                    reject(new Error('Unauthorized'));
                }
                return;
            }

            try {
                const cachedUserJson = await AsyncStorage.getItem(UserKey);
                if (cachedUserJson) {
                    const json = JSON.parse(cachedUserJson);

                    resolve(User.fromJson(json));
                    return;
                }
            } catch (error) {
                // Fall thru
            }

            try {
                const user = await this.currentFromNetwork();

                await this.cacheUser(user);

                resolve(user);
            } catch (error) {
                if (resolveAnonymousAsUndefined) {
                    resolve(undefined);
                } else {
                    reject(error);
                }
            }
        });
    }

    @RequireAuthentication
    currentFromNetwork(): Promise<User> {
        return super.single('get', '/me');
    }

    @RequireAuthentication
    logSession(): Promise<void> {
        return super.r('post', '/log');
    }

    isVerified(token: string): Promise<SessionResponse> {
        return new Promise<SessionResponse>(async (resolve) => {
            try {
                const result: SessionResponse = await this.r('post', '/isverified', {token});

                if (result.authenticated) {
                    await AsyncStorage.setItem(UserTokenKey, result.token!);
                    const user = await this.current();

                    this._emitter.emit(AuthenticationStateEvent, user);

                    resolve(result);
                } else {
                    resolve({authenticated: false, message: result.message});
                }
            } catch (error) {
                resolve({authenticated: false, message: error.message});
            }
        });
    }

    resendVerification(email: string): Promise<void> {
        return super.r('post', '/sendverification', {email});
    }

    @RequireAuthentication
    updateSelf(data: User): Promise<User> {
        return super.single('put', '/me', data.toJson(), async (json) => {
            const user = User.fromJson(json);
            await this.cacheUser(user);
            this._emitter.emit(AuthenticationStateEvent, user);
        });
    }

    @RequireAuthentication
    deleteSelf(): Promise<void> {
        return super.r('delete', '/me', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
        });
    }

    listUsers(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/', options);
    }

    @RequireAuthentication
    followers(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/followers', options);
    }

    @RequireAuthentication
    followed(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/followed', options);
    }

    profiles(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/profiles', options);
    }

    themes(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/themes', options);
    }

    wineclubs(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/wineclubs', options);
    }

    popular(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/popular', options);
    }

    trending(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/trending', options);
    }

    resetPassword(email: string): Promise<void> {
        return super.r('post', '/resetpassword', {email: email});
    }

    @RequireAuthentication
    follow(id: number): Promise<void> {
        return super.r('post', `/follow/${id}`, undefined, emitEventAction(UserFollowed, id));
    }

    @RequireAuthentication
    unfollow(id: number): Promise<void> {
        return super.r('post', `/unfollow/${id}`, undefined, emitEventAction(UserUnfollowed, id));
    }

    @RequireAuthentication
    changePassword(newPassword: string): Promise<void> {
        return super.r('post', '/changepassword', {
            newPassword
        });
    }

    @RequireAuthentication
    closeAccount(): Promise<void> {
        return super.r('delete', '/me', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
            this._emitter.emit(AuthenticationStateEvent, undefined);
        });
    }

    search(query: string, options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return this.getList('/search', {
            q: query,
            ...options
        });
    }

    @RequireAuthentication
    createHasFollowCheckerFunction(): Promise<HasFollowUserChecker> {
        return new Promise<HasFollowUserChecker>(async (resolve, reject) => {
            try {
                const userIds = await this.followed();
                resolve((user: User) => {
                    return userIds.filter(x => x.id === user.id).length > 0;
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    clearUserCache(): Promise<void> {
        return AsyncStorage.removeItem(UserKey);
    }

    refreshUser = (): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            await AsyncStorage.removeItem(UserKey);
            const user = await this.current(true);

            this._emitter.emit(AuthenticationStateEvent, user);

            resolve();
        });
    }

    private cacheUser(user: User): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await AsyncStorage.setItem(UserKey, JSON.stringify(user.toJson()));
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new UserService();

export interface LoginOptions {
    email: string;
    password: string;
}

export interface SignupOptions {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
}

export interface ChangePasswordOptions {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}
