import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './../styles/my-vinos.scss';
import Button from '../components/buttons/Button';
import AuthUserContext from '../AuthUserContext';

type  Props = {

};

type SectionProps = {
    label: string;
    children: React.ReactChild;
};

const Section = ({label, children}: SectionProps) => {
    return (
        <div className={'my-vinos--no-auth--content--section'}>
            <h4>{label}</h4>
            {children}
        </div>);
};

export default class NoAuth extends React.PureComponent<Props> {
    render() {
        return (
            <Container className={'my-vinos my-vinos--no-auth'}>
                <Row style={{ justifyContent: 'center'}}>
                    <Col xl={{size: 10}}>
                    <div className={'my-vinos--no-auth--container'}>
                        <div className={'my-vinos--no-auth--hero'}><h4>Ditt eget Vinkompassen</h4></div>
                            <div className={'my-vinos--no-auth--content'}>
                                <p>Skapa ett konto så får du här din egen smarta samlingsplats för all vininfo. 
                                    Alla dina noteringar, omdömen, egna listor och allt du följer sparas säkert 
                                    och finns i alla dina enheter. Kontot är helt gratis.</p>
                                    <AuthUserContext.Consumer>
                                        {context => (
                                            <Button theme={'pink'}
                                            style={{maxWidth: '214px'}}
                                            label={'Skapa konto'}
                                            onClick={() =>  context.showAccountModal('signup')} />
                                        )}
                                    </AuthUserContext.Consumer>

                                <h2>Allt detta får du med eget konto:</h2>
                                <div className={'my-vinos--no-auth--content--grid'}>
                                    
                                        <Section label={'Egen visuell profil'}>
                                            <img src={require('../images/my-vinos-user-header.jpg')} alt={'Profilbild'}/>
                                        </Section>
                                        <Section label={'Följa profiler och teman'}>
                                            <img src={require('../images/my-vinos-follow-header.jpg')} alt={'Jag följer'}/>
                                        </Section>
                                        <Section label={'Smarta snabbfunktioner'}>
                                            <img src={require('../images/my-vinos-actions.png')} alt={'Snabbfunktioner'}/>
                                        </Section>
                                        <Section label={'Skapa och spara listor'}>
                                            <img src={require('../images/my-vinos-winelists.jpg')} alt={'Egna listor'}/>
                                        </Section>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </Col>
                </Row>
            </Container>);
    }
}

