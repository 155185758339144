import {
    JsonConvert,
    JsonConverter,
    JsonCustomConvert,
    JsonObject,
    JsonProperty,
    ValueCheckingMode
} from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import DateConverter from './DateConverter';

export type ImageResizeMode = 'cover' | 'contain';

@JsonObject
export default class Feed extends Assignable<Feed> implements JsonSerializable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('userId', Number, true)
    userId?: number = undefined;

    @JsonProperty('parentId', Number, true)
    parentId?: number = undefined;

    @JsonProperty('likes', Number, true)
    likes?: number = 0;

    @JsonProperty('reports', Number, true)
    reports?: number = 0;

    @JsonProperty('comments', Number, true)
    comments?: number = 0;

    @JsonProperty('views', Number, true)
    views?: number = 0;

    @JsonProperty('createdAt', DateConverter, true)
    createdAt?: Date = undefined;

    @JsonProperty('modifiedAt', DateConverter, true)
    modifiedAt?: Date = undefined;

    @JsonProperty('imageUrl', String, true)
    imageUrl?: string = undefined;

    @JsonProperty('text', String, true)
    text?: string = undefined;

    @JsonProperty('tags', [String], true)
    tags?: string[] = [];

    @JsonProperty('links', [String], true)
    links?: string[] = [];

    @JsonProperty('promoted', Boolean, true)
    promoted?: boolean = false;

    @JsonProperty('removed', Boolean, true)
    removed?: boolean = false;

    @JsonProperty('resizeMode', String, true)
    resizeMode?: ImageResizeMode = 'cover';

    @JsonProperty('aspectRatio', Number, true)
    aspectRatio?: number = 1.0;

    static fromJson(data: any): Feed {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, Feed) as Feed;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}

export class FeedsMap {
    [key: string]: Feed[];
}

@JsonConverter
export class FeedsConverter implements JsonCustomConvert<FeedsMap> {
    serialize(feedsMap: FeedsMap): any {
        return feedsMap;
    }

    deserialize(feeds: any): FeedsMap {
        let jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        const items = new FeedsMap();
        for (const key in feeds) {
            if (feeds.hasOwnProperty(key)) {
                items[key] = jsonConvert.deserializeArray(feeds[key], Feed);
            }
        }

        return items;
    }
}