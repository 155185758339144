import * as React from 'react';
import { TabPane } from 'reactstrap';
import WineList from '../../models/WineList';
import { TabNames } from './AddEditWineListModal';
import '../../styles/TabPublish.scss';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import Button from '../../components/buttons/Button';
import WineListItem from '../../models/WineListItem';

type Props = {
    winelist: WineList;
    onPublish: (winelist: WineList) => void;
    onUnPublish: (winelist: WineList) => void;
    wineItems?: WineListItem[];
    isDirty: boolean;
};

type StatusProps = {
    activeText?: string,
    inactiveText?: string,
    activeColor?: string,
    inactiveColor?: string,
    active: boolean
};
const Status = (props: StatusProps) => {
    return (
        <div className={'publish-status-indicator'}>
            <p>
                {(props.active) ? props.activeText || 'Aktiv' : props.inactiveText || 'Inaktiv'}
            </p>
            <div className={'publish-status-indicator--dot'} style={{
                backgroundColor: (props.active)
                    ? props.activeColor || '#00ff00'
                    : props.inactiveColor || '#ff0000'
            }}/>
        </div>);

};

export const TabPublish = ({winelist, isDirty, onPublish, onUnPublish, wineItems}: Props) => {

    const needToSave = (isDirty && !winelist.isPublished);
    const hasNoWines = (!wineItems || wineItems.length === 0);
    return (
        <TabPane tabId={TabNames.PUBLISH} className={'tab--publish'}>
            <div className={'tab--publish--head'}>
                <h4>{winelist.name}</h4>
                <Status active={winelist.isPublished}/>
            </div>
            {needToSave && <p>
                Du har inte sparat än. Gör det först, innan du publicerar.
            </p>}
            {(hasNoWines) && <p>
                Lägg till viner i listan innan den kan publiceras.
            </p>}
            {(!hasNoWines && !isDirty && !winelist.isPublished) && <p>
                När du publicerar listan kommer alla som följer dig få en notis.
            </p>}
            {(!isDirty && winelist.isPublished) && <p>
                Du kan när du vill avpublicera listan. Tänk bara på att eventuella länkar till listan inte kommer att fungera.
            </p>}

            <Button 
                disabled={(hasNoWines || needToSave)}
                label={(winelist.isPublished) ? 'Avpublicera' : 'Publicera'}
                theme={(winelist.isPublished) ? 'white' : 'pink'}
                style={{maxWidth: '220px'}}
                onClick={() => {
                  //
                    if (winelist.isPublished) {
                        onUnPublish(winelist);
                    } else {
                        onPublish(winelist);
                    }
                }}
            />
            {/* <div className={'tab--publish--date'}>
                <h5>Startdatum</h5>
                <div className={'tab--publish--date--input'}>
                    <CalendarIcon/>
                    <DatePicker
                        dateFormat={'yyyy-MM-dd'}
                        customInput={<ExampleCustomInput defaultText={'Inget startdatum valt'}/>}
                        selected={winelist.publishDate}
                        onChange={(e) => {
                            const w = winelist;
                            w.publishDate = e;
                            onUpdate(w);
                        }}
                    />
                    {winelist.publishDate &&
                    <span className={'tab--publish--date--input--erace-button'} onClick={() => {
                        const w = winelist;
                        w.publishDate = null;
                        onUpdate(w);
                    }}>
                        <EraceIcon/>
                    </span>
                    }
                </div>
                <h5>Slutdatum</h5>
                <div className={'tab--publish--date--input'}>
                    <CalendarIcon/>
                    <DatePicker
                        dateFormat={'yyyy-MM-dd'}
                        customInput={<ExampleCustomInput defaultText={'Inget slutdatum valt'}/>}
                        selected={winelist.unpublishDate}
                        onChange={(e) => {
                            const w = winelist;
                            w.unpublishDate = e;
                            onUpdate(w);
                        }}
                    />
                    {winelist.unpublishDate &&
                    <span className={'tab--publish--date--input--erace-button'} onClick={() => {
                        const w = winelist;
                        w.unpublishDate = null;
                        onUpdate(w);
                    }}>
                        <EraceIcon/>
                    </span>
                    }
                </div>
            </div> */}
        </TabPane>
    );
};