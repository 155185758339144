import { JsonConvert, JsonConverter, JsonCustomConvert, ValueCheckingMode } from 'json2typescript';

export class DatesMap {
    [key: number]: Date;
}

@JsonConverter
class DatesMapConverter implements JsonCustomConvert<DatesMap> {
    serialize(dates: DatesMap | null): any {
        const map: { [key: number]: number } = {};

        if (!dates) {
            return map;
        }

        for (let [key, value] of Object.entries(dates)) {
            map[parseInt(key, 10)] = value.valueOf();
        }

        return map;
    }

    deserialize(users: any): DatesMap {
        let jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        const items = new DatesMap();
        for (const key in users) {
            if (users.hasOwnProperty(key)) {
                let value = users[key];
                if (value < Date.parse('1980-01-01')) {
                    value = value * 1000;
                }

                items[parseInt(key, 10)] = new Date(value);
            }
        }

        return items;
    }
}

export default DatesMapConverter;