import BaseService, { defaultPagingOptions, emitEventAction, PagingOptions } from './BaseService';
import Feed from '../models/Feed';
import FeedsViewModel from '../models/FeedsViewModel';
import User from '../models/User';
import EventEmitter from './EventEmitter';
import { RequireAuthentication } from './RequestAuthenticationDecorator';

export const FeedAdded = 'FeedAdded';
export const FeedChanged = 'FeedChanged';
export const FeedRemoved = 'FeedRemoved';
export const FeedLiked = 'FeedLiked';
export const FeedUnliked = 'FeedUnliked';
export const FeedRead = 'FeedRead';

export type LikedEventArgs = {
    id: number,
    likes: number
};

class FeedsViewModelService extends BaseService<FeedsViewModel> {
    constructor() {
        super('/feeds');
    }

    fromJson(data: any): FeedsViewModel {
        return FeedsViewModel.fromJson(data);
    }

    feedsForUser(options: PagingOptions = defaultPagingOptions): Promise<FeedsViewModel> {
        return this.single('get', '/', options);
    }

    feedsByLoggedInUser(options: PagingOptions = defaultPagingOptions): Promise<FeedsViewModel> {
        return this.feedsByUser(undefined, options);
    }

    feedsByUser(user?: User, options: PagingOptions = defaultPagingOptions): Promise<FeedsViewModel> {
        if (user) {
            return this.single('get', `/user/${user.id!}`, options);
        } else {
            return this.single('get', '/owned', options);
        }
    }

    unreadFeedsForUser(options: PagingOptions = defaultPagingOptions): Promise<FeedsViewModel> {
        return this.single('get', '/unread', options);
    }
}

const viewModelService = new FeedsViewModelService();

class FeedService extends BaseService<Feed> {
    addedEventName = FeedAdded;
    changedEventName = FeedChanged;
    removedEventName = FeedRemoved;

    constructor() {
        super('/feeds');
    }

    fromJson(data: any): Feed {
        return Feed.fromJson(data);
    }

    feedsForUser(options: PagingOptions = defaultPagingOptions): Promise<FeedsViewModel> {
        return viewModelService.feedsForUser(options);
    }

    feedsByUser(user?: User, options: PagingOptions = defaultPagingOptions): Promise<FeedsViewModel> {
        return viewModelService.feedsByUser(user, options);
    }

    unreadFeedsForUser(): Promise<FeedsViewModel> {
        return viewModelService.unreadFeedsForUser();
    }

    getFeedById(id: number): Promise<FeedsViewModel> {
        return viewModelService.getSingle(id);
    }

    @RequireAuthentication
    markRead(id: number): Promise<void> {
        return this.r('post', `/markread/${id}`, null, emitEventAction(FeedRead, id));
    }

    @RequireAuthentication
    approve(id: number): Promise<void> {
        return this.r('post', `/approve/${id}`);
    }

    @RequireAuthentication
    report(id: number): Promise<void> {
        return this.r('post', `/report/${id}`);
    }

    @RequireAuthentication
    unreport(id: number): Promise<void> {
        return this.r('delete', `/report/${id}`);
    }

    @RequireAuthentication
    like(id: number): Promise<void> {
        return this.r('post', `/like/${id}`, null, async (likes: number) => {
            EventEmitter.emit(FeedLiked, {id, likes} as LikedEventArgs);
        });
    }

    @RequireAuthentication
    unlike(id: number): Promise<void> {
        return this.r('delete', `/like/${id}`, null, async (likes: number) => {
            EventEmitter.emit(FeedUnliked, {id, likes} as LikedEventArgs);
        });
    }
}

export default new FeedService();