import BaseService, { emitEventAction } from './BaseService';
import WineListItem from '../models/WineListItem';
import Wine from '../models/Wine';
import WineListItemsViewModel from '../models/WineListItemsViewModel';
import winelistService, {
    WineListChanged,
    WineListType,
    WineListTypeRated,
    WineListTypeSaved,
    WineListTypeScanned,
    WineListTypeWineCooler,
} from './WineListService';
import EventEmitter from './EventEmitter';
import { RequireAuthentication } from './RequestAuthenticationDecorator';

export const WineListItemChanged = 'WineListItemChanged';
export const WineListItemRemoved = 'WineListItemRemoved';


function emitWineListItemChangedEvent(data: any): Promise<void> {
    return new Promise<void>(async resolve => {
        const item = WineListItem.fromJson(data);
        EventEmitter.emit(WineListItemChanged, item);

        if (item.wineListId) {
            try {
                const model = await winelistService.get(item.wineListId!);
                if (model && model.lists) {
                    EventEmitter.emit(WineListChanged, model.lists[0]);
                }
            } catch (error) {
                console.warn('Failed to emit event that winelist has changed');
            }
        }

        resolve();
    });
}

export type InWineListChecker = (wine: Wine) => boolean;

class WineListItemService extends BaseService<WineListItem> {
    constructor() {
        super(`winelists`);
    }

    fromJson(data: any): WineListItem {
        return WineListItem.fromJson(data);
    }

    remove(data: WineListItem): Promise<void> {
        return super.r('delete', `/${data.wineListId}/items/${data.id}`,
                       data.toJson(), emitEventAction(WineListItemRemoved, data.id));
    }

    getItems(wineListId: number, hash: string | undefined = undefined): Promise<WineListItem[]> {
        const qs = hash ? `?hash=${hash}` : '';
        return super.getList(`/${wineListId}/items${qs}`);
    }

    getItemsCombined(wineListId: number): Promise<WineListItemsViewModel> {
        return new Promise<WineListItemsViewModel>(async (resolve, reject) => {
            try {
                const data = await this.r(
                    'get',
                    `/${wineListId}/items/combined`);
                const model = WineListItemsViewModel.fromJson(data);

                resolve(model);
            } catch (error) {
                reject(error);
            }
        });
    }

    @RequireAuthentication
    sort(wineListId: number, itemIds: number[]): Promise<void> {
        return super.r('post', `/${wineListId}/items/sort`, itemIds);
    }

    @RequireAuthentication
    add(data: WineListItem): Promise<WineListItem> {
        return super.single(
            'post',
            `/${data.wineListId}/items`,
            data.toJson());
    }

    @RequireAuthentication
    addToSpecialList(type: WineListType, wine: Wine): Promise<WineListItem> {
        const data = new WineListItem();
        data.wineId = wine.id;
        data.wineListId = 0;

        return super.single(
            'post',
            `/${type}/items`,
            data.toJson(),
            emitWineListItemChangedEvent);
    }

    addToSaved(wine: Wine): Promise<WineListItem> {
        return this.addToSpecialList(WineListTypeSaved, wine);
    }

    addToRated(wine: Wine): Promise<WineListItem> {
        return this.addToSpecialList(WineListTypeRated, wine);
    }

    addToWineCooler(wine: Wine): Promise<WineListItem> {
        return this.addToSpecialList(WineListTypeWineCooler, wine);
    }

    addToScanned(wine: Wine): Promise<WineListItem> {
        return this.addToSpecialList(WineListTypeScanned, wine);
    }

    @RequireAuthentication
    updateItems(wineListId: number, data: WineListItem[]): Promise<WineListItem[]> {
        return super.list(
            'post',
            `/${wineListId}/items/batch`,
            data.map(x => x.toJson()));
    }

    @RequireAuthentication
    getIdsForWinesInListsCreatedByUser(): Promise<number[]> {
        return super.r('get', '/user/items/wineids');
    }

    @RequireAuthentication
    createInWineListCheckerFunction(): Promise<InWineListChecker> {
        return new Promise<InWineListChecker>(async (resolve, reject) => {
            try {
                const wineIds = await this.getIdsForWinesInListsCreatedByUser();

                resolve((wine: Wine) => {
                    return wineIds.indexOf(wine.id!) > -1;
                });
            } catch (error) {
                reject(error);
            }
        });
    }

}

export default new WineListItemService();
