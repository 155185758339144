import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthenticationState } from './WithAuthentication';

export const StateManagerContext = React.createContext<StateManagerProps>({});

// tslint:disable-next-line:interface-name
export interface IStateManager {
    get: () => any;
    set: (key: string, value: any) => Promise<void>;
    setObject: (obj: { [key: string]: any }) => Promise<void>;
    isServer: boolean;
}

export interface StateManagerProps {
    stateManager?: IStateManager;
}

export interface StateManagerClientProps extends RouteComponentProps {
    initialState?: StateManagerState;
    renderApp: (stateManager: IStateManager, initialState?: StateManagerState) => any;
}

export interface StateManagerState extends AuthenticationState {
    [key: string]: any;
}

const cleanState = (): StateManagerState => ({authUser: null});

class StateManagerClient extends React.Component<StateManagerClientProps, StateManagerState> implements IStateManager {
    constructor(props: StateManagerClientProps) {
        super(props);

        this.state = props.initialState || cleanState();
    }

    isServer = false;

    get = () => this.state;

    set = (key: string, value: any): Promise<void> => {
        return this.setObject({[key]: value});
    }

    setObject = (obj: { [key: string]: any }): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            this.setState(
                obj,
                resolve);
        });
    }

    // componentWillReceiveProps(nextProps: Readonly<StateManagerClientProps>, nextContext: any): void {
    //     const oldPath = this.props.location.pathname;
    //     const newPath = nextProps.location.pathname;
    //
    //     if (oldPath !== newPath) {
    //         this.setState(prevState => {
    //             const newState: StateManagerState = {};
    //             for (const key in prevState) {
    //                 if (prevState.hasOwnProperty(key) && key !== 'authUser') {
    //                     newState[key] = undefined;
    //                 }
    //             }
    //
    //             return newState;
    //         });
    //     }
    // }

    componentDidUpdate(prevProps: Readonly<StateManagerClientProps>, prevState: Readonly<StateManagerState>, snapshot?: any): void {
        const oldPath = prevProps.location.pathname;
        const newPath = this.props.location.pathname;

        if (oldPath !== newPath) {
            this.setState(prevState => {
                const newState: StateManagerState = {};
                for (const key in prevState) {
                    if (prevState.hasOwnProperty(key) && key !== 'authUser') {
                        newState[key] = undefined;
                    }
                }

                return newState;
            });
        }
    }

    render() {
        const {renderApp} = this.props;
        const state = this.state;
        const stateManager: IStateManager = {
            get: this.get,
            set: this.set,
            setObject: this.setObject,
            isServer: this.isServer
        };

        return <React.Fragment>
            {renderApp(
                stateManager,
                state)}
        </React.Fragment>;
    }
}

export class StateManagerServer implements IStateManager {
    state: StateManagerState = cleanState();
    isServer = true;

    get = () => {
        return this.state;
    }

    set = (key: string, value: any): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            this.state[key] = value;
            resolve();
        });
    }

    setObject = (obj: { [p: string]: any }): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    this.state[key] = obj[key];
                }
            }
            resolve();
        });
    }
}

export default {
    Client: withRouter(StateManagerClient),
    Server: new StateManagerServer()
};

export const withStateManager =
    <P extends object>(Component: React.ComponentType<P & StateManagerProps>) => (props: P) => (
        <StateManagerContext.Consumer>
            {value => <Component {...props} stateManager={value.stateManager}/>}
        </StateManagerContext.Consumer>
    );

export function getStateManagerState<P extends object>(props: P & StateManagerProps) {
    return {
        ...props,
        ...(props.stateManager && props.stateManager.get())
    };
}