
interface Window {
    fbq?: (type: string, eventName: string) => void;
}

export default function FBPixelEvent(eventName: string): void {
    const w = window as Window;
    if (w.fbq ) {
        w.fbq('track', eventName);
    }
}