import * as React from 'react';
import WineList from '../models/WineList';
import User from '../models/User';
import winelistItemService from '../services/WineListItemService';
import { formatDate } from '../DateUtils';
import '../styles/WineListItemWidget.scss';
import {
    ActionTrashIcon,
    HeartIconSmall,
    PadLockIcon,
    PenIcon,
    SaveIconInv,
    ShareIcon
} from '../components/icons/Icons';
import SimpleButton from '../components/buttons/SimpleButton';
import Chevron from '../Chevron';
import WineListItem from '../models/WineListItem';
import WineItemView from '../wines/WineItemView';
import { WineViewRouteState } from '../wines/WineView';

import Wine from '../models/Wine';
import { createProfileLink, createWineLink } from '../Routes';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SaveButton from '../components/buttons/SaveButton';
import LikeButton from '../components/buttons/LikeButton';
import ActionButton from '../components/buttons/ActionButton';
import winenotes, { HasWineNoteChecker } from '../services/WineNoteService';
import { resultOrUndefined } from '../ResultOrNull';

interface Props extends RouteComponentProps {
    model: WineList;
    items?: WineListItem[];
    user: User;
    onWineClick?: (wine: Wine) => void;
    forceOpen?: boolean;
    showEditMode?: boolean;
    onEditClick?: () => void;
    onDeleteClick?: (list: WineList) => void;
    onShareClick?: () => void;
    isFollowing?: boolean;
    onFollowToggle?: () => void;
    isLiked?: boolean;
    onLikeClick?: () => void;
    hash?: string;
    isLoggedIn?: boolean;
    linkToProfile?: boolean;
    isOwner?: boolean;
    isAdmin?: boolean;
}

type State = {
    isListVisible: boolean;
    items: WineListItem[];
    showEditMode?: boolean;
};

class WineListItemWidget extends React.Component<Props, State> {
    static defaultProps: Partial<Props> = {
        linkToProfile: true
    };

    hasWineNoteChecker?: HasWineNoteChecker;
    editModeTimer: number | undefined = undefined;

    constructor(props: Props) {
        super(props);

        this.state = {
            isListVisible: (props.forceOpen) || false,
            items: (props.items) || [],
            showEditMode: props.showEditMode || false
        };

        if (props.model.id && !props.items && props.forceOpen) {
            this.fetchItems(props.model.id, props.hash);
        }
    }

    componentDidMount(): void {
        window.addEventListener('keydown', this.onKeyDownListener, false);
        window.addEventListener('keyup', this.onKeyUpListener, false);
    }

    componentWillUnmount(): void {
        window.removeEventListener('keydown', this.onKeyDownListener, false);
        window.removeEventListener('keyup', this.onKeyUpListener, false);
    }

    componentWillReceiveProps(newProps: Props) {

        if (newProps.items) {
            this.setState({
                items: newProps.items,
                showEditMode: newProps.showEditMode
            });
            return;
        }

        if (newProps.model.id! !== this.props.model.id!) {
            this.fetchItems(newProps.model.id!);
        }
    }

    onKeyDownListener = (e: any) => {
        const {isAdmin} = this.props;

        if (!isAdmin) {
            return;
        }

        if (e.shiftKey && !this.editModeTimer) {
            this.editModeTimer = window.setTimeout(() => {
                this.setState(prevState => ({showEditMode: !prevState.showEditMode}));
                this.editModeTimer = undefined;
            },                                     2000);
        }
    }

    onKeyUpListener = (e: any) => {
        const {isAdmin} = this.props;

        if (!isAdmin) {
            return;
        }

        window.clearTimeout(this.editModeTimer);
        this.editModeTimer = undefined;
    }

    fetchItems = async (id: number, hash: string | undefined = undefined) => {
        try {
            this.hasWineNoteChecker = await resultOrUndefined<HasWineNoteChecker>(() => winenotes.createHasWineNoteCheckerFunction());

            this.setState({
                items: await winelistItemService.getItems(id, hash)
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    onListToggle = async () => {
        try {
            const {model} = this.props;
            const {items} = this.state;
            if (items.length === 0) {
                await this.fetchItems(model.id!);
            }
            this.setState({
                isListVisible: !this.state.isListVisible,
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    onWineClick(wine: Wine) {
        if (this.props.onWineClick) {
            this.props.onWineClick(wine);
        }
    }

    onEditClick = () => {
        if (this.props.onEditClick) {
            this.props.onEditClick();
        }
    }

    onDeleteClick = () => {
        const anwser = window.confirm('Är du verkligen säker på att du vill radera vinlistan?');
        const {model} = this.props;
        if (anwser && this.props.onDeleteClick) {
            this.props.onDeleteClick(model);
        }
    }

    render() {

        const {
            model,
            user,
            forceOpen,
            onShareClick,
            isFollowing,
            onFollowToggle,
            isLiked,
            onLikeClick,
            isLoggedIn,
            linkToProfile,
            isOwner = false
        } = this.props;

        const {isListVisible, items, showEditMode} = this.state;

        return (
            <div className={'wine-list-item-widget'}>
                <div className={'wine-list-item-widget--header'}>
                    <div className={'wine-list-item-widget--hero'}
                         style={{backgroundImage: `url(${model.largeImageUrl})`}}>
                        <div className={'wine-list-item-widget--hero--overlay'}/>

                        <h3 className={'wine-list-item-widget--hero--name'}>{model.name}</h3>
                        <div className={'wine-list-item-widget--hero--toolbar'}>
                            <span
                                className={'wine-list-item-widget--hero--toolbar--item'}>{model.modified && formatDate(model.modified)}</span>

                            {model.isPublished && (
                                <div className={'wine-list-item-widget--hero--toolbar--item'}>
                                    <span>{model.followers}<SaveIconInv color={'#fff'}/></span>
                                    {(isOwner) && <span style={{marginLeft: 10}}>{model.likes}<HeartIconSmall/></span>}
                                </div>
                            )}

                            {!model.isPublished && (
                                <div className={'wine-list-item-widget--hero--toolbar--item'}>
                                    <PadLockIcon color={'#fff'}/>
                                    &nbsp;
                                    Bara synlig för dig
                                </div>
                            )}

                            <span className={'wine-list-item-widget--hero--toolbar--item'}>{model.wineCount}
                                {(model.wineCount && model.wineCount > 1) ? ' Viner' : ' Vin'}</span>
                        </div>
                    </div>
                    <div className={'wine-list-item-widget--owner'}>
                        <figure style={{backgroundImage: `url(${user.imageUrl})`}}/>
                        <div className={'wine-list-item-widget--owner--info'}>
                            {linkToProfile ? (
                                <Link
                                    className={'wine-list-item-widget--owner--info--link link-item'}
                                    to={{
                                        pathname: createProfileLink(user)
                                    }}>
                                    <Chevron/>
                                    <h3>{user.fullName}</h3>
                                    <h4>{user.company}</h4>
                                </Link>
                            ) : (
                                <div className={'wine-list-item-widget--owner--info--link link-item'}>
                                    <h3>{user.fullName}</h3>
                                    <h4>{user.company}</h4>
                                </div>
                            )}
                            <div className={'divider'}/>
                            <p>{model.description}</p>
                            {!forceOpen &&
                            <SimpleButton
                                caret={(!isListVisible) ? 'down' : 'up'}
                                label={'Visa vinerna'} onClick={this.onListToggle}/>
                            }
                        </div>
                    </div>
                </div>

                {(isListVisible) && (
                    <React.Fragment>
                        {showEditMode && (
                            <div className={'wine-list-item-widget--toolbar'}>
                                <ActionButton
                                    label={'Radera listan'}
                                    icon={<ActionTrashIcon/>}
                                    onClick={() => this.onDeleteClick()}/>

                                <ActionButton
                                    label={'Ändra'}
                                    className={'d-none d-md-flex'}
                                    icon={<PenIcon/>}
                                    onClick={() => this.onEditClick()}/>

                                <ActionButton
                                    label={'Dela'}
                                    icon={<ShareIcon/>}
                                    onClick={() => onShareClick && onShareClick()}/>
                            </div>
                        )}

                        {(!showEditMode && model.isPublished) && (
                            <div className={'wine-list-item-widget--toolbar'}>
                                <SaveButton
                                    disabled={!isLoggedIn}
                                    isSelected={isFollowing}
                                    onClick={() => onFollowToggle && onFollowToggle()}/>

                                <LikeButton
                                    disabled={!isLoggedIn}
                                    isSelected={isLiked}
                                    onClick={() => onLikeClick && onLikeClick()}/>

                                <ActionButton
                                    label={'Dela'}
                                    icon={<ShareIcon/>}
                                    onClick={() => onShareClick && onShareClick()}/>
                            </div>
                        )}

                        {(!showEditMode && !model.isPublished) && (
                            <div className={'wine-list-item-widget--toolbar'}>
                                <SaveButton
                                    disabled={!isLoggedIn}
                                    isSelected={isFollowing}
                                    onClick={() => onFollowToggle && onFollowToggle()}/>

                            </div>
                        )}

                        <div className={'wine-list-item-widget--list'}>
                            {items.map((x, index) => (
                                <Link key={x.id || index} to={{
                                    pathname: createWineLink(x.wine!),
                                    state: {
                                        wine: x.wine

                                    } as WineViewRouteState
                                }}>
                                    <WineItemView wine={x.wine!}
                                                  inWineList={!model.isPublic}
                                                  hasWineNote={this.hasWineNoteChecker && this.hasWineNoteChecker(x.wine!)}
                                                  onClick={(wine) => this.onWineClick(wine)}/>
                                    {x.comment &&
                                    <div className={'wine-list-item-widget--list--comment'}>
                                        <div className={'wine-list-item-widget--list--comment--line'}/>
                                        <p>“{x.comment}”</p>
                                    </div>
                                    }
                                </Link>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(WineListItemWidget);