import * as React from 'react';
import Helmet from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';
import { buildWebPath } from './vinkompassen-config';

interface Props extends RouteComponentProps {
    title?: string;
    description?: string;
    image?: string;
    canonical?: string;
}

class DefaultMeta extends React.Component<Props> {
    render() {
        const {location} = this.props;
        const defaultTitle = 'Vinkompassen';
        const defaultDescription = 'Vinkompassen är den smarta samlingsplatsen för allt som rör vin. Här kan du hålla koll ' +
            'på dina viner och få ny inspiration från våra kunniga profiler.';
        const defaultImage = '/icon512.png';
        const defaultCanonical = location.pathname;

        const {
            title = defaultTitle,
            description = defaultDescription,
            image = defaultImage,
            canonical = defaultCanonical
        } = this.props;

        return (
            <Helmet
                title={title}
                meta={[
                    {name: 'description', content: description},
                    {property: 'og:title', content: title},
                    {property: 'og:type', content: 'website'},
                    {property: 'og:url', content: buildWebPath(canonical)},
                    {property: 'og:image', content: buildWebPath(image)},
                    {property: 'og:description', content: description},
                ]}
                link={[
                    {rel: 'canonical', href: buildWebPath(canonical)}
                ]}/>
        );
    }
}

export default withRouter(DefaultMeta);
