import * as React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { LayoutProps } from '../LayoutHelpers';
import { Col, Container, Row } from 'reactstrap';
import Button from '../components/buttons/Button';
import {
    SettingsEmailIcon,
    SettingsImagesIcon,
    SettingsPadLockIcon,
    SettingsStoresIcon,
    SettingsTextsIcon,
    SettingsTrashIcon
} from '../components/icons/Icons';
import Routes from '../Routes';
import AsideButtonSpacer from '../components/spacers/AsideButtonSpacer';
import withAuthorization, { isAuthenticated } from '../WithAuthorization';
import { AuthenticationProps } from '../WithAuthentication';
import { LogoutButton } from '../AuthUserContext';
import { scrollToContent } from '../helpers/ScrollToContent';

interface Props extends LayoutProps<any>, RouteComponentProps, AuthenticationProps {

}

class SettingsLayout extends React.PureComponent<Props> {
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        const {authUser, history} = this.props;

        if (prevProps.authUser && !authUser) {
            history.push(Routes.HOME);
            return;
        }
    }

    onNavigate = (route: string) => {
        const {history} = this.props;

        history.push(route);

        scrollToContent('settings-content');
    }

    render() {
        const {component: Component, location, history, authUser, ...rest} = this.props;
        const pathname = location.pathname;

        return (
            <Route {...rest} render={matchProps => (
                <Container className={'settings'}>
                    <Row>
                        {/* Aside */}
                        <Col
                            className={'aside'}
                            tag={'aside'}
                            xl={{size: 3, offset: 1}} lg={{size: 4, offset: 0}} md={{size: 5, offset: 0}}
                        >
                            <div className={'aside--box col-background text-center'}>
                                <div className={'text-center'}>
                                    Inloggad:<br/>
                                    <strong>{authUser && authUser.email}</strong>
                                </div>

                                <AsideButtonSpacer/>

                                <LogoutButton/>
                            </div>

                            <div className={'aside--box col-background'}>
                                <h3>Konto-uppgifter</h3>
                                <Button
                                    theme={(pathname === Routes.SETTINGS_CHANGEEMAIL) ? 'green' : 'white'}
                                    textAlign={'left'}
                                    icon={<SettingsEmailIcon/>}
                                    useCaret={true}
                                    label={'Byt mailadress'}
                                    onClick={() => this.onNavigate(Routes.SETTINGS_CHANGEEMAIL)}/>

                                <AsideButtonSpacer/>

                                <Button
                                    theme={(pathname === Routes.SETTINGS_CHANGEPASSWORD) ? 'green' : 'white'}
                                    textAlign={'left'}
                                    icon={<SettingsPadLockIcon/>}
                                    useCaret={true}
                                    label={'Byt lösenord'}
                                    onClick={() => this.onNavigate(Routes.SETTINGS_CHANGEPASSWORD)}/>

                                <AsideButtonSpacer/>

                                <Button
                                    theme={(pathname === Routes.SETTINGS_CLOSEACCOUNT) ? 'green' : 'white'}
                                    textAlign={'left'}
                                    icon={<SettingsTrashIcon/>}
                                    useCaret={true}
                                    label={'Radera kontot'}
                                    onClick={() => this.onNavigate(Routes.SETTINGS_CLOSEACCOUNT)}/>
                            </div>

                            <div className={'aside--box col-background'}>
                                <h3>Profil-info</h3>
                                <Button
                                    theme={(pathname === Routes.SETTINGS_PROFILE_TEXTS) ? 'green' : 'white'}
                                    textAlign={'left'}
                                    icon={<SettingsTextsIcon/>}
                                    useCaret={true}
                                    label={'Texter'}
                                    onClick={() => this.onNavigate(Routes.SETTINGS_PROFILE_TEXTS)}/>

                                <AsideButtonSpacer/>

                                <Button
                                    theme={(pathname === Routes.SETTINGS_PROFILE_IMAGES) ? 'green' : 'white'}
                                    textAlign={'left'}
                                    icon={<SettingsImagesIcon/>}
                                    useCaret={true}
                                    label={'Bilder'}
                                    onClick={() => this.onNavigate(Routes.SETTINGS_PROFILE_IMAGES)}/>
                            </div>

                            {/* <div className={'aside--box col-background'}>
                                <h3>Systembolaget</h3>
                                <Button
                                    theme={(pathname === Routes.SETTINGS_STORES) ? 'green' : 'white'}
                                    textAlign={'left'}
                                    icon={<SettingsStoresIcon/>}
                                    useCaret={true}
                                    label={'Favoritbutiker'}
                                    onClick={() => this.onNavigate(Routes.SETTINGS_STORES)}/>

                            </div> */}
                        </Col>
                        {/* main content */}
                        <Col xl={{size: 6, offset: 1}} lg={{size: 6, offset: 1}} md={{size: 7, offset: 0}}
                             id={'settings-content'}>
                            <Component {...matchProps} {...rest} />
                        </Col>
                    </Row>
                </Container>
            )}/>
        );
    }
}

// const SettingsLayout = ({component: Component, location, history, authUser, ...rest}:
//                             LayoutProps<any> & RouteComponentProps & AuthenticationProps) => {
//     const {pathname} = location;
//     let contentRef: HTMLElement | null = null;
//
//     function onNavigate(route: string) {
//         history.push(route);
//
//         if (contentRef) {
//             contentRef!.scrollIntoView();
//         }
//     }
//
//     return (
//         <Route {...rest} render={matchProps => (
//             <Container className={'settings'}>
//                 <Row>
//                     {/* Aside */}
//                     <Col
//                         className={'aside'}
//                         tag={'aside'}
//                         xl={{size: 3, offset: 1}} lg={{size: 4, offset: 0}} md={{size: 5, offset: 0}}
//                     >
//                         <div className={'aside--box col-background text-center'}>
//                             <div className={'text-center'}>
//                                 Inloggad:<br/>
//                                 <strong>{authUser && authUser.email}</strong>
//                             </div>
//
//                             <AsideButtonSpacer/>
//
//                             <LogoutButton/>
//                         </div>
//
//                         <div className={'aside--box col-background'}>
//                             <h3>Konto-uppgifter</h3>
//                             <Button
//                                 theme={(pathname === Routes.SETTINGS_CHANGEEMAIL) ? 'green' : 'white'}
//                                 textAlign={'left'}
//                                 icon={<SettingsEmailIcon/>}
//                                 useCaret={true}
//                                 label={'Byt mailadress'}
//                                 onClick={() => onNavigate(Routes.SETTINGS_CHANGEEMAIL)}/>
//
//                             <AsideButtonSpacer/>
//
//                             <Button
//                                 theme={(pathname === Routes.SETTINGS_CHANGEPASSWORD) ? 'green' : 'white'}
//                                 textAlign={'left'}
//                                 icon={<SettingsPadLockIcon/>}
//                                 useCaret={true}
//                                 label={'Byt lösenord'}
//                                 onClick={() => onNavigate(Routes.SETTINGS_CHANGEPASSWORD)}/>
//
//                             <AsideButtonSpacer/>
//
//                             <Button
//                                 theme={(pathname === Routes.SETTINGS_CLOSEACCOUNT) ? 'green' : 'white'}
//                                 textAlign={'left'}
//                                 icon={<SettingsTrashIcon/>}
//                                 useCaret={true}
//                                 label={'Radera kontot'}
//                                 onClick={() => onNavigate(Routes.SETTINGS_CLOSEACCOUNT)}/>
//                         </div>
//
//                         <div className={'aside--box col-background'}>
//                             <h3>Profil-info</h3>
//                             <Button
//                                 theme={(pathname === Routes.SETTINGS_PROFILE_TEXTS) ? 'green' : 'white'}
//                                 textAlign={'left'}
//                                 icon={<SettingsTextsIcon/>}
//                                 useCaret={true}
//                                 label={'Texter'}
//                                 onClick={() => onNavigate(Routes.SETTINGS_PROFILE_TEXTS)}/>
//
//                             <AsideButtonSpacer/>
//
//                             <Button
//                                 theme={(pathname === Routes.SETTINGS_PROFILE_IMAGES) ? 'green' : 'white'}
//                                 textAlign={'left'}
//                                 icon={<SettingsImagesIcon/>}
//                                 useCaret={true}
//                                 label={'Bilder'}
//                                 onClick={() => onNavigate(Routes.SETTINGS_PROFILE_IMAGES)}/>
//                         </div>
//
//                         <div className={'aside--box col-background'}>
//                             <h3>Systembolaget</h3>
//                             <Button
//                                 theme={(pathname === Routes.SETTINGS_STORES) ? 'green' : 'white'}
//                                 textAlign={'left'}
//                                 icon={<SettingsStoresIcon/>}
//                                 useCaret={true}
//                                 label={'Favoritbutiker'}
//                                 onClick={() => onNavigate(Routes.SETTINGS_STORES)}/>
//
//                         </div>
//                     </Col>
//                     {/* main content */}
//                     <Col xl={{size: 6, offset: 1}} lg={{size: 6, offset: 1}} md={{size: 7, offset: 0}}
//                          ref={(r: any) => contentRef = r}>
//                         <Component {...matchProps} {...rest} />
//                     </Col>
//                 </Row>
//             </Container>
//         )}/>
//     );
// };

export default withRouter<any, React.ComponentType<any>>(withAuthorization(isAuthenticated)(SettingsLayout));
