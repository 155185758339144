import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-96568380-1', {debug: false});

class PageTracker extends React.PureComponent<RouteComponentProps> {
    componentDidMount(): void {
        this.trackLocation(this.props.location.pathname);
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.trackLocation(this.props.location.pathname);
        }
    }

    trackLocation(path: string) {
        ReactGA.pageview(path);
    }

    render() {
        return (null);
    }
}

export default withRouter(PageTracker);
