import * as React from 'react';
import { FormGroup, Input, TabPane, Label } from 'reactstrap';

import { TabNames } from './AddEditWineListModal';
import '../../styles/TabLinks.scss';
import User from '../../models/User';
import UserService from '../../services/UserService';
import WineList from '../../models/WineList';

type State = {
    users: User[];
    seletcedList: User[];
    // wineItems: WineListItem[];
};

type Props = {
    winelist?: WineList;
    linkedUsers?: User[];
    onUpdate: (users: User[]) => void;
};

export default class TabLinks extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            users: [],
            seletcedList: (props.linkedUsers) ? [...props.linkedUsers] : [],
        };
    }

    componentDidMount() {
        this.fetchUsers();
    }

    // componentWillReceiveProps(newProps: Props) {

    //     console.log(newProps);

    //     if (newProps.linkedUsers) {
    //         this.setState({seletcedList: [...newProps.linkedUsers]});
    //     }
    // }

    fetchUsers = async() => {
        try {

            const users  = await UserService.themes();
            this.setState({
                users,
            });
            
        } catch (error) {
            console.log('error', error);
        }
    }

    toggleValue = (user: User) => {
      
        const {seletcedList} = this.state;
        const index = seletcedList.findIndex(x => x.id === user.id);
        
        if (index === -1) {
            seletcedList.push(user);
        } else {
            seletcedList.splice(index, 1);
        }
        this.setState({
            seletcedList
        });
        this.forceUpdate();
        this.props.onUpdate(seletcedList);
    }

    _renderUsers = (users: User[], seletcedList: User[]) => {
        return users.map(x => (
            <Label key={x.id} check className={'tab--links--items--item'}>
                <Input type="checkbox" 
                    checked={!!(seletcedList.find(b => b.id === x.id))} 
                    value={x.id} onChange={ () => this.toggleValue(x)} />{x.fullName}
            </Label>
        ));
    }
   
    render() {
        const {users, seletcedList} = this.state;
        const {winelist} = this.props;
       
        return (
            <TabPane tabId={TabNames.LINKS} className={'tab--links'}>
                <h4>Länkar ”{winelist && winelist.name}” till följande teman:</h4>
                <FormGroup check className="tab--links--items">
                    {this._renderUsers(users, seletcedList)}
                </FormGroup>
            </TabPane>
        );
    }
}
