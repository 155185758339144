import * as React from 'react';
import Wine from '../models/Wine';
import '../styles/WineItemView.scss';
import SystembolagetSign from '../components/systembolaget-sign/SystembolagetSign';
import { SmallFlagIcon } from '../components/icons/flags/Flags';
import WineTypeIcon from '../components/icons/wine-type/WineType';
import { WineListIcon, WineNoteIcon } from '../components/icons/Icons';
import { LazyImage } from 'react-lazy-images';

interface Props {
    wine: Wine;
    onClick?: (wine: Wine) => void;
    inWineList?: boolean;
    hasWineNote?: boolean;
}

class WineItemView extends React.Component<Props> {
    render() {
        const {wine, onClick, inWineList, hasWineNote} = this.props;

        if (!wine) {
            return null;
        }

        const showVintage: boolean = !!(wine.vintage && wine.vintage > 0);
        const placeholderImage = require('../images/bottle-placeholder.png');
        const imageUrl = wine.productThumbnailUrl || wine.productImageUrl || placeholderImage;

        return (
            <div className={'wineitem wineitem--container'} onClick={() => onClick && onClick(wine)}>
                <div className={'wineitem--image-container'}>
                    <LazyImage
                        src={imageUrl}
                        alt={`${wine.name2} ${wine.name}`}
                        placeholder={({imageProps, ref}) => (
                            <img className={'wineitem--image-placeholder'}
                                 src={placeholderImage}
                                 ref={ref}
                                 alt={imageProps.alt}
                            />
                        )}
                        actual={({imageProps}) => (
                            <img className={'wineitem--image'}
                                 onError={(e) => e.currentTarget.src = placeholderImage}
                                 alt={imageProps.alt}
                                 {...imageProps}/>
                        )}
                    />
                </div>

                <div className={'wineitem--content-container'}>
                    <div className={'wineitem--subtitle'}>
                        {!!wine.name2 && <span>{wine.name2}</span>}
                        {!!wine.name2 && showVintage && <span>,&nbsp;</span>}
                        {showVintage && <span>{`${wine.vintage}`}</span>}
                    </div>
                    <div className={'wineitem--title'}>
                        <strong>{wine.name}</strong>
                    </div>
                    <div className={'wineitem--icons'}>
                        <SmallFlagIcon country={wine.country}/>
                        <WineTypeIcon type={wine.type} style={wine.style}/>
                        {inWineList &&
                        <WineListIcon/>
                        }
                        {hasWineNote &&
                        <WineNoteIcon/>
                        }
                    </div>
                </div>

                <div className={'wineitem--price-container'}>
                    <div className={'wineitem--price-container--volume'}>
                        {`${wine.volume} ml`}
                    </div>

                    <div className={'wineitem--price-container--price'}>
                        {`${wine.price} kr`}
                    </div>

                    <div className={'wineitem--price-container--article-number'}>
                        {/* <SystembolagetSign label={wine.systembolagetArticleNumberExternal}/> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default WineItemView;
