import * as React from 'react';
import withAuthentication, { AuthenticationProps } from './../../WithAuthentication';
import '../../styles/AccountAndAppSign.scss';
import AuthUserContext from '../../AuthUserContext';
import Button from '../buttons/Button';

export enum SignType {
    app,
    map,
    default
}

interface Props  extends AuthenticationProps {
    text?: string;
    headline?: string;
    type?: SignType;
}
const DEFAULT_ACCOUNT_TEXT: string = 'Skapa ett konto så får du din egen smarta samplingsplats för all vininfo. ' +
                                     'Alla dina noteringar, omdömen egna listor och allt du följer sparas säkert. ' +
                                     'Kontot är gratis.';

class AccountAndAppSign extends React.PureComponent<Props> {

    render() {
        const {authUser, text } = this.props;
        return (
            <div className={'account-and-app-sign'}>
                <header>
                    <h4>Ditt eget Vinkompassen</h4>
                </header>
                <div className={'account-and-app-sign--content'}>
                    <p>{text || DEFAULT_ACCOUNT_TEXT}</p>
                    {!authUser && 
                    <React.Fragment>
                        <AuthUserContext.Consumer>
                            {context => (
                                <Button theme={'pink'}
                                        textAlign={'center'}
                                        label={'Skapa konto'}
                                        className={'align-self-center'}
                                        onClick={() => context.showAccountModal('signup')}/>
                            )}
                        </AuthUserContext.Consumer>
                    </React.Fragment>
                    }
                </div>
            </div>);
    }
}

export default withAuthentication(AccountAndAppSign);