import * as React from 'react';

interface Props {
    color?: string;
}

class Chevron extends React.Component<Props> {
    static defaultProps: Props = {
        color: '#E95003'
    };

    render() {
        return (<svg style={{verticalAlign: 'baseline'}}
                     width="8"
                     height="13"
                     viewBox="0 0 8 13"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 6.5L1 12" stroke={this.props.color} strokeWidth="2" strokeLinecap="round"/>
            </svg>
        );
    }
}

export default Chevron;
