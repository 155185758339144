import * as React from 'react';
import users from '../services/UserService';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Button from '../components/buttons/Button';
import AuthUserContext from '../AuthUserContext';
import ChevronLink from '../ChevronLink';
import { EmailNotVerified } from '../models/SessionResponse';
import { AccountModalContext, AccountModalContextProps } from './AccountModalContext';
import Checkbox from '../components/checkbox/Checkbox';
import EnterKeyHandler from '../EnterKeyHandler';
import { Link } from 'react-router-dom';
import Routes from '../Routes';
import FBPixelEvent from  './../helpers/FacebookHelper';

interface Props extends RouteComponentProps {

}

type State = {
    email: string,
    password: string,
    acceptAgreement: boolean
};

class SignupView extends React.Component<Props, State> {
    static contextType = AccountModalContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            acceptAgreement: false
        };
    }

    onSignup = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const {history} = this.props;
            const {email, password, acceptAgreement} = this.state;

            if (!acceptAgreement) {
                alert('Du måste godkänna våra villkör för att skapa ditt konto.');
                return;
            }

            const session = await users.signup({
                email,
                password,
                firstName: '',
                lastName: ''
            });
            FBPixelEvent('CompleteRegistration');

            if (session.authenticated) {
                await users.current();
                history.replace('/');
            } else if (session.message === EmailNotVerified) {
                // noinspection JSDeprecatedSymbols
                const {onShowScreen} = this.context as AccountModalContextProps;
                onShowScreen('verifyemail', {
                    token: session.token,
                    email: email
                });
            } else {
                alert(this.getFriendlyErrorMessage(session));
            }
        } catch (error) {
            console.log(error);
            alert(this.getFriendlyErrorMessage(error));
        }
    }

    getFriendlyErrorMessage(error: { message?: string }): string {
        switch (error.message) {
            case 'EmailInUse':
                return 'E-postadressen är redan registrerad.';
            case 'InvalidEmail':
                return 'Du behöver ange en giltig e-postadress.';
            case 'InvalidPassword':
                return 'Lösenordet behöver vara minst 4 tecken långt.';
            case 'EmailNotVerified':
                return 'Ditt konto behöver verifieras.';
            default:
                return `Ett oväntat fel uppstod: ${error.message}`;
        }
    }

    onAgreementPress = () => {
        this.setState(prevState => ({
            acceptAgreement: !prevState.acceptAgreement
        }));
    }

    render() {
        return (
            <AuthUserContext.Consumer>
                {context => <Container>
                    <Row>
                        <Col>
                            <h2 id={'modal-title'}>Skapa konto</h2>
                            <p id={'modal-description'}>Med ett konto kan du göra noteringar om viner och spara viner i
                                dina egna listor.
                                Allt sparas säkert och hänger med även om du byter mobil eller dator. Kontot
                                är helt kostnadsfritt.</p>

                            <Form onSubmit={this.onSignup}>
                                <FormGroup>
                                    <Label>E-postadress:</Label>
                                    <Input type={'email'}
                                           value={this.state.email}
                                           onChange={(e) => this.setState({email: e.target.value})}
                                           placeholder={'E-postadress'}/>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Lösenord:</Label>
                                    <Input type={'password'}
                                           value={this.state.password}
                                           onChange={(e) => this.setState({password: e.target.value})}
                                           placeholder={'Lösenord'}/>
                                </FormGroup>

                                <FormGroup>
                                    <div role={'checkbox'}
                                         aria-checked={this.state.acceptAgreement}
                                         aria-labelledby={'acceptedAgreementLabel'}
                                         tabIndex={0}
                                         onKeyPress={(e) => EnterKeyHandler(e, this.onAgreementPress)}
                                         onClick={this.onAgreementPress}
                                         style={{
                                               display: 'flex',
                                               flexDirection: 'row'
                                           }}>
                                        <Checkbox checked={this.state.acceptAgreement}/>
                                        <label style={{display: 'inline-block', marginLeft: 10}}
                                               id={'acceptedAgreementLabel'}
                                        >
                                            Jag bekräftar att jag tagit del av och
                                            godkänt <Link to={Routes.TERMS_OF_SERVICE} target={'_blank'}>
                                            användarvillkoren</Link> för Vinkompassen.
                                        </label>
                                    </div>
                                </FormGroup>

                                <Button theme={'pink'}
                                        type={'submit'}>Skapa konto</Button>

                                <br/>

                                <ChevronLink to={''} onClick={() => {
                                    context.showAccountModal('login');
                                }}>Logga in</ChevronLink>

                                {/*<TextDivider>eller</TextDivider>*/}

                                {/*<Button theme={'pink'} type={'submit'}>Skapa konto med Facebook</Button>*/}
                            </Form>
                        </Col>
                    </Row>
                </Container>
                }
            </AuthUserContext.Consumer>);
    }
}

export default withRouter(SignupView);