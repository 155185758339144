//@ts-nocheck
import * as React from "react";
import { FormGroup, Input, TabPane } from "reactstrap";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortEnd,
  SortEvent,
} from "react-sortable-hoc";

import * as Algolia from "react-instantsearch/dom";
import algoliaConfig from "../../algolia-config";

import WineListItem from "../../models/WineListItem";
import { EditIcon, TrashIcon } from "../../components/icons/Icons";
import WineItemView from "../../wines/WineItemView";
import WineNoteService, {
  HasWineNoteChecker,
} from "../../services/WineNoteService";
import WineListItemService, {
  InWineListChecker,
} from "../../services/WineListItemService";

import Hits from "./Hits";
import Wine from "../../models/Wine";

import { TabNames } from "./AddEditWineListModal";
import "../../styles/WineSearch.scss";
import "../../styles/Pagination.scss";
import "../../styles/TabWines.scss";
import Modal from "../../components/modal/Modal";
// import Modal from 'reactstrap/lib/Modal';
// import ModalBody from 'reactstrap/lib/ModalBody';
// import ModalFooter from 'reactstrap/lib/ModalFooter';
// import Button from '../../components/buttons/Button';

type State = {
  searchState: any;
  showCommentModal: boolean;
  commentWineIndex?: number;
  activeComment: string;
  // wineItems: WineListItem[];
};

type Props = {
  isPublic?: boolean;
  wineItems?: WineListItem[];
  onUpdate: (items: WineListItem[]) => void;
};

type SortableElementProps = {
  isPublic: Boolean;
  value: WineListItem;
  onRemove: () => void;
  onComment: () => void;
};

type SortableContainerProps = {
  isPublic: boolean;
  items: WineListItem[];
  onRemove: (index: number) => void;
  onComment: (index: number) => void;
};

const SortableItem = SortableElement<SortableElementProps>(
  ({ value, onRemove, isPublic, onComment }: SortableElementProps) => {
    return (
      <div className={"tab--wines--winelist--item"}>
        <span
          className={"tab--wines--winelist--item--delete-button"}
          onClick={() => {
            onRemove();
          }}
        >
          <TrashIcon />
        </span>

        <div className={"tab--wines--winelist--item--wrapper"}>
          <WineItemView wine={value.wine!} inWineList={true} />

          <div className={"tab--wines--winelist--item--comment"}>
            <div className={"tab--wines--winelist--item--comment--line"} />
            {value.comment ? (
              <span>{`“${value.comment}”`}</span>
            ) : (
              <span>Ange kommentar om vinet</span>
            )}
            <div
              className={"tab--wines--winelist--item--comment--edit-button"}
              onClick={() => onComment()}
            >
              <EditIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer<SortableContainerProps>(
  ({ items, onRemove, isPublic, onComment }: SortableContainerProps) => {
    return (
      <div className={"tab--wines--winelist"}>
        <p>Sortera vinerna genom att dra och släppa.</p>
        {items.map((value: any, index: number) => (
          <SortableItem
            key={index}
            isPublic={isPublic}
            index={index}
            value={value}
            onComment={() => onComment(index)}
            onRemove={() => onRemove(index)}
          />
        ))}
      </div>
    );
  }
);

export default class TabWines extends React.PureComponent<Props, State> {
  public static defaultProps = {
    wineItems: [],
    isPublic: false,
  };

  hasWineNoteChecker?: HasWineNoteChecker;
  inWineListChecker?: InWineListChecker;

  state: Readonly<State> = {
    searchState: {},
    showCommentModal: false,
    activeComment: "",
    // wineItems: []
  };

  componentDidMount() {
    this.fetchCheckers();
  }

  fetchCheckers = async () => {
    try {
      this.hasWineNoteChecker =
        await WineNoteService.createHasWineNoteCheckerFunction();
      this.inWineListChecker =
        await WineListItemService.createInWineListCheckerFunction();
      this.forceUpdate();
    } catch (error) {
      console.log("error in fetch checkers", error);
    }
  };

  onSearchStateChange = (searchState: any) => {
    this.setState({ searchState });
  };

  onSortEnd = (s: SortEnd, e: SortEvent) => {
    const { oldIndex, newIndex } = s;
    const { wineItems } = this.props;
    const sortetItems = arrayMove(wineItems!, oldIndex, newIndex);
    sortetItems.forEach((row: WineListItem, index: number) => {
      row.sortOrder = index;
    });
    // this.setState({wineItems: sortetItems});
    this.props.onUpdate(sortetItems);
  };

  onRemoveItem = (index: number) => {
    const wineItems = [...this.props.wineItems!];
    wineItems.splice(index, 1);
    // this.setState({wineItems});
    this.props.onUpdate(wineItems);
  };

  onComment = (index: number) => {
    console.log(index);
    const comment =
      this.props.wineItems && this.props.wineItems[index]
        ? this.props.wineItems[index].comment || ""
        : "";
    this.setState({
      commentWineIndex: index,
      showCommentModal: true,
      activeComment: comment,
    });
  };

  onAddWine = (wine: Wine) => {
    const wineItems = [...this.props.wineItems!];
    const match: boolean =
      wineItems.find((item) => item.wine!.id! === wine.id!) !== undefined;
    if (match) {
      return alert(`${wine.name ? wine.name : "Vinet"} finns redan i listan.`);
    }

    const wineItem = new WineListItem();
    wineItem.wineId = wine.id;
    wineItem.wine = { ...wine } as Wine;
    wineItem.sortOrder = wineItems.length;
    wineItems.push(wineItem);
    // this.setState({wineItems});
    this.props.onUpdate(wineItems);
  };

  saveComment = () => {
    const { wineItems } = this.props;
    const { activeComment, commentWineIndex } = this.state;
    wineItems![commentWineIndex!].comment = activeComment;
    this.setState({
      showCommentModal: false,
    });
    this.props.onUpdate(wineItems!);
  };

  render() {
    const { wineItems = [], isPublic = false } = this.props;
    const { showCommentModal, commentWineIndex, activeComment } = this.state;

    return (
      <TabPane tabId={TabNames.WINES}>
        <div className={"tab--wines"}>
          <SortableList
            distance={1}
            helperClass={"tab--wines--winelist--item--active"}
            items={wineItems}
            isPublic={isPublic}
            onRemove={this.onRemoveItem}
            onComment={this.onComment}
            onSortEnd={this.onSortEnd}
          />

          <div className={"tab--wines--search"}>
            <Algolia.InstantSearch
              appId={algoliaConfig.appId}
              apiKey={algoliaConfig.apiKey}
              indexName={algoliaConfig.winesIndex}
              searchState={this.state.searchState}
              onSearchStateChange={this.onSearchStateChange}
            >
              <Algolia.Configure hitsPerPage={5} />
              <div className={"searchbox"}>
                <Algolia.SearchBox
                  translations={{
                    placeholder: "Sök vin, varunummer, region eller druva",
                    submitTitle: "Sök",
                    resetTitle: "Rensa",
                  }}
                />
              </div>
              <Hits
                HasWineNoteChecker={this.hasWineNoteChecker}
                InWineListChecker={this.inWineListChecker}
                hasSearched={!!this.state.searchState.query}
                onAdd={this.onAddWine}
              />
              <Algolia.Pagination />
            </Algolia.InstantSearch>
          </div>
        </div>
        {commentWineIndex !== undefined && (
          <Modal
            className={"tab--wines--modal-comment"}
            buttons={[
              {
                label: "Spara",
                style: "primary",
                onClick: () => this.saveComment(),
              },
              {
                label: "Avbryt",
                style: "default",
                onClick: () => {
                  this.setState({ showCommentModal: false });
                },
              },
            ]}
            isOpen={showCommentModal}
          >
            <FormGroup>
              <Input
                type="textarea"
                name="description"
                id="description"
                placeholder="Beskrivning av vinet"
                value={activeComment}
                onChange={(e) => {
                  this.setState({
                    activeComment: e.target.value.substr(0, 350),
                  });
                }}
              />
              <span>{`Max 350 tecken. Just nu: ${
                activeComment.length || 0
              } `}</span>
            </FormGroup>
          </Modal>
        )}
      </TabPane>
    );
  }
}

/*
    <span style="
    margin-top: -33px;
    padding: 5px 15px;
    display: block;
    text-align: center;
    color: #808080;
    font-size: 14px;
    border-top: 1px solid #80808055;
    pointer-events: none;">
    Max 160 tecken. Just nu: 0 </span>
 */
