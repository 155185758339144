import * as React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import Button from '../components/buttons/Button';
import { CreateFeedIcon, FeedsIcon, ListIcon, ProfileIcon, SaveIcon, ThemeIcon, } from '../components/icons/Icons';
import withAuthentication, { AuthenticationProps } from '../WithAuthentication';
import ProfileHeader from './ProfileHeader';
import WineListViewModelService from '../services/WineListViewModelService';
import Section from './Section';
import WineList from '../models/WineList';
import WineListViewModel from '../models/WineListViewModel';
import WineListCombined from '../models/WineListCombined';
import { distinctArray } from '../helpers/DistinctArray';

import User from '../models/User';
import IdParams from '../IdParams';

import './../styles/my-vinos.scss';
import routes, { createSpecialWineListLink } from '../Routes';
import EventEmitter from '../services/EventEmitter';
import { EVENT_WINE_LIST_ADDED } from './PrivateWineListsView';
import { EVENT_WINE_LIST_DELETED, EVENT_WINE_LIST_UPDATED } from './PrivateWineListView';
import { LayoutProps } from '../LayoutHelpers';
import NoAuth from './NoAuth';
import StandaloneSearchBox from '../search/StandaloneSearchBox';
import AccountAndAppSign from '../components/account-and-app-sign/AccountAndAppSign';
import { WineListItemRemoved } from '../services/WineListItemService';
import { scrollToContent } from '../helpers/ScrollToContent';
import FeedService from '../services/FeedService';
import FeedsViewModel from '../models/FeedsViewModel';

// import { EVENT_WINE_LIST_GROUPED_PAGE } from '../winelists/WineListGroupedView';

export interface MyVinosProps extends RouteComponentProps<IdParams>, AuthenticationProps, LayoutProps<any> {
}

type State = {
    myListsCombo?: WineListViewModel;
    combined?: WineListCombined;
    profiles?: User[];
    themes?: User[];
    activeSpecialList?: WineList;
    feedViewModel?: FeedsViewModel;
    unreadFeedViewModel?: FeedsViewModel;
};

const sortByModified = (items: WineList[]) => {
    return items.sort((a, b) =>
        (b.modified!.getTime() - a.modified!.getTime())
    );
};

class MyVinosLayout extends React.PureComponent<MyVinosProps, State> {
    constructor(props: MyVinosProps) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        EventEmitter.addListener(EVENT_WINE_LIST_ADDED, this.onListAdded);
        EventEmitter.addListener(EVENT_WINE_LIST_DELETED, this.onListDeleted);
        EventEmitter.addListener(EVENT_WINE_LIST_UPDATED, this.onListUpdated);

        EventEmitter.addListener(WineListItemRemoved, this.onWineRemoved);
        // EventEmitter.addListener(EVENT_WINE_LIST_GROUPED_PAGE, this.onPrepGroupedPage);
        this.fetchData();
    }

    componentWillUnmount() {
        EventEmitter.removeListener(EVENT_WINE_LIST_ADDED, this.onListAdded);
        EventEmitter.removeListener(EVENT_WINE_LIST_DELETED, this.onListDeleted);
        EventEmitter.removeListener(EVENT_WINE_LIST_UPDATED, this.onListUpdated);
        EventEmitter.removeListener(WineListItemRemoved, this.onWineRemoved);
        // EventEmitter.removeListener(EVENT_WINE_LIST_GROUPED_PAGE, this.onPrepGroupedPage);
    }

    componentDidUpdate(prevProps: Readonly<MyVinosProps>, prevState: Readonly<State>, snapshot?: any): void {
        const {authUser} = this.props;

        if (!prevProps.authUser && authUser) {
            this.fetchData();
            return;
        }
    }

    onWineRemoved = (id: number[]) => {
        let {combined} = this.state;
        let {wineList} = this.props;

        if (!wineList || !combined) {
            return;
        }
        
        switch (wineList.id) {
            case combined.ratedWineList!.id :
                combined.ratedWineList!.wineCount! --;
                break;
            case combined.savedWineList!.id :
                combined.savedWineList!.wineCount! --;
                break;
            case combined.scannedWineList!.id :
                combined.scannedWineList!.wineCount! --;
                break;
            case combined.stockedWineList!.id :
                combined.stockedWineList!.wineCount! --;
                break;
            default:
        }
       
        this.forceUpdate();
    }

    async fetchData() {
        const {authUser} = this.props;
        if (!authUser) {
            return;
        }

        // const combined: WineListCombined = await WineListViewModelService.byUserCombined(authUser!);
        // const feedsViewModel = await FeedService.feedsByUser(authUser);

        const [wineListViewModel, feedViewModel, unreadFeedViewModel] = await Promise.all([
            WineListViewModelService.byUserCombined(authUser),
            FeedService.feedsByUser(),
            FeedService.unreadFeedsForUser()
        ]);

        // if (wineListViewModel.winelists && wineListViewModel.winelists.lists) {
        //     wineListViewModel.winelists.lists = wineListViewModel.winelists.lists.filter(x => !x.isPublic);
        // }

        let myListsCombo = new WineListViewModel();
        myListsCombo.lists = sortByModified(
            distinctArray<WineList>(
                [
                    ...(wineListViewModel.winelists && wineListViewModel.winelists.lists)
                        ? wineListViewModel.winelists.lists : [],
                    ...(wineListViewModel.followedWinelists && wineListViewModel.followedWinelists.lists)
                        ? wineListViewModel.followedWinelists.lists : [],
                ],
                'id')
        );

        const profiles = wineListViewModel.followedUsers && wineListViewModel.followedUsers.filter(x => !x.isTheme);
        const themes = wineListViewModel.followedUsers && wineListViewModel.followedUsers.filter(x => x.isTheme);
        myListsCombo.users = Object.assign({}, wineListViewModel.winelists!.users!, wineListViewModel.followedWinelists!.users!);

        this.setState({
            profiles,
            themes,
            myListsCombo,
            combined: wineListViewModel,
            feedViewModel,
            unreadFeedViewModel,
        });
    }

    onListAdded = (list: WineList) => {
        // console.log('onListAdded');
        const {combined} = this.state;
        if (combined) {
            combined.winelists!.lists!.push(list);
            this.forceUpdate();
        }
    }

    onListDeleted = (id: number) => {
        // console.log('onRemoved');
        const {combined} = this.state;
        if (combined) {
            const list = combined.winelists!.lists!.filter(x => (x.id !== id));
            combined.winelists!.lists = list;
        }
        this.onNavigate(routes.MYVINOS_OWN_WINE_LISTS);
    }

    onListUpdated = (list: WineList) => {
        const {combined} = this.state;
        if (combined) {
            const index = combined.winelists!.lists!.findIndex(x => (x.id === list.id));
            combined.winelists!.lists![index] = list;
            this.setState({combined});
        }
    }

    onNavigate = (route: string) => {
        const {history} = this.props;

        history.push(route);

        scrollToContent('my-vinos--content');
    }

    onSpecialListClick = (winelist: WineList | undefined) => {
        if (winelist) {
            this.setState({activeSpecialList: winelist}, () => 
                this.onNavigate(createSpecialWineListLink(winelist))
            );
            
        }
    }

    render() {
        
        const {component: Component, wineList, location, history, authUser, ...rest} = this.props;
        const {combined, profiles, themes, feedViewModel} = this.state;
        const pathname = location.pathname;
        const profileCount = (profiles && profiles.length) || 0;
        const themeCount = (themes && themes.length) || 0;
        const feedsCount = (feedViewModel && feedViewModel.feeds && feedViewModel.feeds.length) || 0;
        const savedCount = (
            combined &&
            combined.followedWinelists &&
            combined.followedWinelists.lists &&
            combined.followedWinelists.lists.length) || 0;
        const ownListCount = (
            combined &&
            combined.winelists &&
            combined.winelists.lists &&
            combined.winelists.lists.length) || 0;

        if (!authUser) {
            return (
                <Route {...rest} render={matchProps => (<NoAuth />)}/>
            );
        }

        return (
            <Route {...rest} render={matchProps => (
                <Container className={'my-vinos'}>
                    <Row>
                        {/* Aside */}
                        <Col
                            className={'aside'}
                            tag={'aside'}
                            xl={{size: 3, offset: 1}}
                            lg={{size: 4, offset: 0}}
                            md={{size: 5, offset: 0}} style={{marginBottom: 20}}
                            // sm={{size: 6, offset: 0}} style={{marginBottom: 20}}
                            >
                            
                            {/* <StandaloneSearchBox/> */}

                            {combined &&
                            <ProfileHeader
                                onSpecialListClick={this.onSpecialListClick}
                                authUser={authUser}
                                activeListId={matchProps.match.params.id}
                                data={combined}/>
                            }
                            <Section label={'Inlägg'} image={require('../images/feed-header.jpg')}>
                                <Button
                                    icon={<FeedsIcon/>}
                                    textAlign={'left'}
                                    label={'Mina inlägg'}
                                    useCaret={true}
                                    theme={(pathname === routes.MYVINOS_START) ? 'green' : 'white'}
                                    badge={feedsCount}
                                    onClick={() => this.onNavigate(routes.MYVINOS_START)}/>
                                <Button
                                    icon={<CreateFeedIcon/>}
                                    textAlign={'left'}
                                    label={'Skapa nytt'}
                                    useCaret={true}
                                    theme={(pathname === routes.MYVINOS_CREATE_FEEDS) ? 'green' : 'white'}
                                    onClick={() => this.onNavigate(routes.MYVINOS_CREATE_FEEDS)}/>
                            </Section>
                            <Section label={'Jag följer'} image={require('../images/i-follow-header.jpg')}>
                                <Button
                                    icon={<ProfileIcon/>}
                                    textAlign={'left'}
                                    label={'Profiler & medlemmar'}
                                    useCaret={true}
                                    theme={(pathname === routes.MYVINOS_PROFILES) ? 'green' : 'white'}
                                    badge={profileCount}
                                    onClick={() => this.onNavigate(routes.MYVINOS_PROFILES)}/>
                                <Button
                                    icon={<ThemeIcon/>}
                                    textAlign={'left'}
                                    label={'Teman'}
                                    useCaret={true}
                                    theme={(pathname === routes.MYVINOS_THEMES) ? 'green' : 'white'}
                                    badge={themeCount}
                                    onClick={() => this.onNavigate(routes.MYVINOS_THEMES)}/>
                            </Section>
                            <Section label={'Mina listor'} image={require('../images/my-lists-header.jpg')}>
                                <Button
                                    icon={<SaveIcon/>}
                                    textAlign={'left'}
                                    label={'Sparade'}
                                    useCaret={true}
                                    theme={(pathname === routes.MYVINOS_SAVED_WINE_LISTS) ? 'green' : 'white'}
                                    badge={savedCount}
                                    onClick={() => this.onNavigate(routes.MYVINOS_SAVED_WINE_LISTS)}/>
                                <Button
                                    icon={<ListIcon/>}
                                    textAlign={'left'}
                                    label={'Egna'}
                                    useCaret={true}
                                    theme={(pathname === routes.MYVINOS_OWN_WINE_LISTS) ? 'green' : 'white'}
                                    badge={ownListCount}
                                    onClick={() => this.onNavigate(routes.MYVINOS_OWN_WINE_LISTS)}/>
                            </Section>
                            {/* <img className={'my-vinos--teaser'} src={require('../images/my-vinos-teaser.jpg')}
                                 alt={'Min Vinresa börjar snart...'}/> */}
                            <AccountAndAppSign />
                        </Col>
                        {/* main content */}
                        <Col xl={{size: 6, offset: 1}} 
                            lg={{size: 8, offset: 0}} 
                            md={{size: 7, offset: 0}}
                            // sm={{size: 6, offset: 0}}

                             id={'my-vinos--content'}>
                            <Component {...matchProps} {...this.props} {...this.state} />
                        </Col>
                    </Row>
                </Container>
            )}/>
        );
    }
}

// export default withRouter<any>(MyVinosLayout);
export default withRouter(withAuthentication(MyVinosLayout));