import * as React from 'react';
import '../../styles/SaveButton.scss';
import '../../styles/ActionButton.scss';
import { Back, Elastic, TweenLite } from 'gsap';

type Props = {
    isSelected?: boolean;
    disabled?: boolean;
    onClick?: (isSelected: boolean) => void;
};

type State = {
    isSelected?: boolean;
};

export default class SaveButton extends React.PureComponent<Props, State> {

    private icon: any = null;
    private line1: any = null;
    private line2: any = null;
    private lines: any = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isSelected: false,
        };
    }

    onSelect = () => {
        const {onClick} = this.props;
        TweenLite.to(this.icon, 2, {
            backgroundColor: '#E95003',
            rotation: 125,
            ease: Elastic.easeOut
        });
        TweenLite.to(this.line2, 0.2, {
            backgroundColor: '#fff',
            y: '0px',
            x: '6px',
            height: '10px',
            ease: Back.easeOut,
            delay: 0.1
        });
        TweenLite.to(this.line1, 0.2, {
            backgroundColor: '#fff',
            width: '14px',
            x: '-6px',
            ease: Back.easeOut,
            delay: 0.1
        });
        TweenLite.to(this.lines, 0.2, {
            y: '-3px',
            x: '-2px',
            ease: Back.easeOut,
            delay: 0.1
        });
        if (onClick) {
            onClick(true);
        }
    }

    deSelect = () => {
        const {onClick} = this.props;
        TweenLite.to(this.icon, 0.3, {backgroundColor: '#fff', rotation: 0, ease: Back.easeOut});
        TweenLite.to(this.line2, 0.2, {
            backgroundColor: '#E95003',
            y: '-8px',
            x: '-1px',
            height: '16px',
            ease: Back.easeOut
        });
        TweenLite.to(this.line1, 0.2, {backgroundColor: '#E95003', width: '16px', x: '-8px', ease: Back.easeOut});
        TweenLite.to(this.lines, 0.2, {y: '0px', x: '0px', ease: Back.easeOut});
        if (onClick) {
            onClick(false);
        }
    }

    render() {
        const {isSelected, ...rest} = this.props;

        return (
            <button {...rest}
                    className={`action-button save-button ${(isSelected) ? 'selected' : ''}`}
                    onClick={() => {
                        (!isSelected) ? this.onSelect() : this.deSelect();
                    }}>
                <figure className={'save-button--icon'} ref={(e) => this.icon = e}>
                    <div className={'save-button--icon--lines'} ref={(e) => this.lines = e}>
                        <div className={'save-button--icon--line1'} ref={(e) => this.line1 = e}/>
                        <div className={'save-button--icon--line2'} ref={(e) => this.line2 = e}/>
                    </div>
                </figure>
                <span className={'action-button--label'}>{(isSelected) ? 'Sparad' : 'Spara'}</span>
            </button>
        );
    }
}