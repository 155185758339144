import {
    JsonConvert,
    JsonConverter,
    JsonCustomConvert,
    JsonObject,
    JsonProperty,
    ValueCheckingMode
} from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import { Ownable } from './Ownable';
import User from './User';
import Wine from './Wine';

@JsonObject
export default class WineNote extends Assignable<WineNote> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('userId', Number, true)
    userId?: number = undefined;

    @JsonProperty('wineId', Number, false)
    wineId?: number = undefined;

    @JsonProperty('comment', String, false)
    comment?: string = undefined;

    @JsonProperty('wine', Wine, true)
    wine?: Wine = undefined;

    static fromJson(data: any): WineNote {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, WineNote) as WineNote;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

    isOwner(user: User): boolean {
        return user && this.userId === user.id;
    }
}

export class WineNotes {
    [wineId: number]: WineNote;
}

@JsonConverter
export class WineNotesConverter implements JsonCustomConvert<WineNotes> {
    serialize(items: WineNotes): any {
        return items;
    }

    deserialize(data: any): WineNotes {
        let jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        const items = new WineNotes();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                items[key as any] = jsonConvert.deserializeObject(data[key], WineNote);
            }
        }

        return items;
    }
}