import * as React from 'react';
import { connectStats } from 'react-instantsearch-core';
import '../../styles/Fonts.scss';

interface Props {
    nbHits: number;
}

class Stats extends React.Component<Props> {

    render() {
        return (<h3 className={'heading-green-bigger'}>
            {this.props.nbHits} viner
        </h3>);
    }
}

export default connectStats(Stats);
