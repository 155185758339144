import * as React from 'react';
import User from '../models/User';
import '../styles/ProfileHeader.scss';
import ActionButton from './ActionButton';
import { CheckmarkIcon, ScanIcon, StarIcon, StockIcon } from '../components/icons/Icons';
import WineListCombined from '../models/WineListCombined';
import WineList from '../models/WineList';

export type SpecialListType = 'saved' | 'rated' | 'stock' | 'scan';

type Props = {
    authUser: User;
    data: WineListCombined;
    onSpecialListClick: (winelist: WineList | undefined) => void;
    activeListId?: string;
};

export default class ProfileHeader extends React.Component<Props> {

    render() {
        
        const {authUser, data, onSpecialListClick, activeListId} = this.props;
        
        return (
            <div className={'profile-header'} >
                <div className={'profile-header--top'} style={{backgroundImage: `url(${authUser.largeImageUrl})`}}>
                    <div className={'profile-header--top--overlay'}/>
                        <h2 className={'profile-header--name'}>{authUser.fullName}</h2>
                        <figure className={'profile-header--profile-image'} style={{backgroundImage: `url(${authUser.imageUrl})`}}/>
                    </div>
                
                <div className={'profile-header--toolbar'}>
                    <h3>Mina viner</h3>
                    <div className={'profile-header--toolbar--buttons'}>
                        <ActionButton
                            icon={CheckmarkIcon}
                            label={'Sparat'}
                            selected={(Number(activeListId) === data.savedWineList!.id)}
                            value={(data && data.savedWineList && data.savedWineList.wineCount) || 0}
                            onPress={() => onSpecialListClick(data.savedWineList)}
                            />
                        <ActionButton
                            icon={StarIcon}
                            label={'Provat'}
                            selected={(Number(activeListId) === data.ratedWineList!.id)}
                            value={(data && data.ratedWineList && data.ratedWineList.wineCount) || 0}
                            onPress={() => onSpecialListClick(data.ratedWineList)}/>
                        <ActionButton
                            icon={StockIcon}
                            label={'Hemma'}
                            selected={(Number(activeListId) === data.stockedWineList!.id)}
                            value={(data && data.stockedWineList && data.stockedWineList.wineCount) || 0}
                            onPress={() => onSpecialListClick(data.stockedWineList)}/>
                        <ActionButton
                            icon={ScanIcon}
                            label={'Skannat'}
                            selected={(Number(activeListId) === data.scannedWineList!.id)}
                            value={(data && data.scannedWineList && data.scannedWineList.wineCount) || 0}
                            onPress={() => onSpecialListClick(data.scannedWineList)}/>
                    </div>
                </div>
            </div>);
    }
}