import * as React from 'react';
// import { LoaderAnim } from './components/loader/LoaderAnim';

class Loading extends React.Component {
    render() {
        return (
        <div style={{minHeight: '60vh', position: 'relative'}}>
            <p style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0,0,0,0.5)',
                padding: 20,
                borderRadius: 10,
                color: '#fff'}}>
                    Laddar…
            </p>
            {/* <LoaderAnim /> */}
        </div>);
    }
}

export default Loading;
