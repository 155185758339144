import * as React from 'react';
import '../styles/WineListItem.scss';
// import '../GlobalStyles.scss';
import WineList from '../models/WineList';
import User from '../models/User';
import Chevron from '../Chevron';
import { formatDate } from '../DateUtils';
import { MiniBottleIcon, PadLockIcon, SaveIconInv } from '../components/icons/Icons';
// @ts-ignore
import LinesEllipsis from 'react-lines-ellipsis';

interface Props {
    winelist: WineList;
    user: User;
    onClick?: (list: WineList) => void;
    isOwner?: boolean;
    isFollowing?: boolean;
    selected?: boolean;
    circleImage: boolean;
}

class WineListItemView extends React.PureComponent<Props> {
    render() {
        const {winelist, user, circleImage, onClick, isOwner = false, isFollowing = false, selected = false} = this.props;

        return (
            <div key={winelist.id}
                 className={`winelist-item ${circleImage ? 'public' : ''} ${(selected) ? 'selected' : ''}`}
                 onClick={() => onClick && onClick(winelist)}>
                <div className={'winelist-item-image-container'}>
                    <figure
                        className={'winelist-item-image'}
                        style={{
                            backgroundImage: `url(${circleImage
                                ? user.imageUrl
                                : winelist.largeImageUrl})`
                        }}
                    />

                    <div className={`winelist-item--bar ${winelist.isPublished ? 'public' : ''}`}>
                        {(isOwner && !winelist.isPublished) && (
                            <figure className={'winelist-item--bar--fav-icon'} title={'Listan är privat'}>
                                <PadLockIcon color={(!circleImage) ? '#fff' : undefined}/>
                            </figure>
                        )}

                        {(!isOwner && isFollowing) && (
                            <figure className={'winelist-item--bar--fav-icon'}>
                                {/* <BookmarkIconOn color={'#FF691E'}/> */}
                                <SaveIconInv color={(!circleImage) ? '#fff' : undefined}/>
                            </figure>
                        )}

                        <div className={`winelist-item--bar--winecount`}>
                            {winelist.wineCount || 0}
                            <MiniBottleIcon color={(!circleImage) ? '#fff' : undefined}/>
                        </div>
                    </div>
                </div>

                <div className={'winelist-item-content'}>
                    <div className={'winelist-item-subtitle listitem-subtitle'}>
                        {
                            (isOwner)
                                ? (winelist.modified) ? formatDate(winelist.modified) : ''
                                : `${user.fullName}${(winelist.modified) ? ', ' + formatDate(winelist.modified) : ''}`
                        }
                    </div>
                    <div className={'winelist-item-title listitem-title'}>
                        <LinesEllipsis text={winelist.name} maxLine={2}/>
                    </div>
                </div>

                <div className={`winelist-item-caret`}>
                    <Chevron color={(selected) ? '#fff' : undefined}/>
                </div>
            </div>
        );
    }
}

export default WineListItemView;
