import { BaseUrlService } from './BaseService';

export interface FileUploadResponse {
    id: string;
    contentType: string;
    url: string;
    width?: number;
    height?: number;
}

class FileService extends BaseUrlService {
    upload(data: FormData, onProgress?: (progress: number) => void): Promise<FileUploadResponse> {
        return this.futch('/', data, onProgress);
    }

    remove(id: string): Promise<boolean> {
        return this.r('delete', `/${id}`);
    }
}

export default new FileService('/files');