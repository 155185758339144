export interface EmailMessage {
    subject: string;
    message: string;
}

class ShareService {

    facebook(url: string = window.location.href) {
        window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(url),
            'Share',
            'width=500,height=500');
    }

    email(emailMessage: EmailMessage, url: string = window.location.href) {
        const body = emailMessage.message += '%0A%0A' + encodeURI(url);
        window.location.href = `mailto:?subject=${encodeURI(emailMessage.subject)}&body=${body}`;
    }

    clipboard(url: string = window.location.href) {
        const el = document.createElement('textarea');
        el.value = encodeURI(url);
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}

export const shareService = new ShareService();