import * as React from 'react';
import { AccountModalContext, AccountModalScreens } from './AccountModalContext';
import Modal from '../components/modal/Modal';
import LoginView from './LoginView';
import '../styles/AccountModal.scss';
import EnterKeyHandler from '../EnterKeyHandler';
import SignupView from './SignupView';
import ForgotPasswordView from './ForgotPasswordView';
import VerifyEmailView from './VerifyEmailView';
import ReactGA from 'react-ga';

interface AccountModalProps {
    mode: AccountModalScreens;
    data?: any;
    visible: boolean;
    onCancel: () => void;
}

interface State {
    view: AccountModalScreens;
    data?: any;
}

class AccountModal extends React.Component<AccountModalProps, State> {
    static defaultProps: Partial<AccountModalProps> = {
        mode: 'login',
        visible: false
    };

    constructor(props: AccountModalProps) {
        super(props);

        this.state = {
            view: props.mode,
            data: props.data
        };
    }

    componentDidUpdate(prevProps: Readonly<AccountModalProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.state.view !== this.props.mode) {
            this.setState({
                view: this.props.mode,
                data: this.props.data
            });
        }

        if (this.props.visible && !prevProps.visible) {
            ReactGA.modalview('/account/' + this.props.mode);
        }
    }

    render() {
        const {view, data} = this.state;
        const {visible, onCancel} = this.props;

        let screenToShow: any = null;

        switch (view) {
            case 'login':
                screenToShow = <LoginView {...data}/>;
                break;
            case 'signup':
                screenToShow = <SignupView {...data}/>;
                break;
            case 'resetpassword':
                screenToShow = <ForgotPasswordView {...data}/>;
                break;
            case 'verifyemail':
                screenToShow = <VerifyEmailView {...data}/>;
                break;
            default:
                screenToShow = null;
                break;
        }

        return (
            <Modal isOpen={visible}>
                <div className={'account-modal'}>
                    <span className={'account-modal--close'}
                          role={'button'}
                          tabIndex={0}
                          onClick={onCancel}
                          onKeyPress={(e) => EnterKeyHandler(e, onCancel)}>
                        Stäng
                    </span>
                    <AccountModalContext.Provider value={{
                        onShowScreen: (screen, data) => this.setState({view: screen, data})
                    }}>
                        {screenToShow}
                    </AccountModalContext.Provider>
                </div>
            </Modal>
        );
    }
}

export default AccountModal;
