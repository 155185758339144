import * as React from 'react';
import { FormGroup, Input, Label, TabPane } from 'reactstrap';
import WineList from '../../models/WineList';
import { TabNames } from './AddEditWineListModal';
import '../../styles/TabInfoText.scss';

type Props = {
    winelist: WineList;
    onUpdate: (winelist: WineList) => void;
};

export const TabInfoText = ({winelist, onUpdate}: Props) => {

    const defaultName = 'Namnlös';
    return (
        <TabPane tabId={TabNames.INFO_TEXT} className={'tab--info-text'}>
            <FormGroup>
                <Label for="name">{'Listans namn'}</Label>
                <Input type="text" name="name" id="name" placeholder="Namn"
                       value={winelist!.name! || ''}
                       onFocus={(e) => {
                           if (e.target.value === defaultName) {
                                winelist.name = '';
                                // this.setState({winelist : w});
                                onUpdate(winelist);
                           }
                       }}

                       onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                winelist.name = defaultName;
                                // this.setState({winelist : w});
                                onUpdate(winelist);
                            }
                        }}

                       onChange={(e) => {
                           const w = winelist;
                           w.name = e.target.value;
                           // this.setState({winelist : w});
                           onUpdate(w);
                       }}/>
            </FormGroup>
            <FormGroup>
                <Label for="description">Beskrivning av listan</Label>
                <Input type="textarea" name="description" id="description" placeholder="Beskrivning"
                       value={winelist!.description! || ''}
                       onChange={(e) => {
                           const w = winelist;
                           w.description = e.target.value.substr(0, 160);
                           onUpdate(w);
                           // this.setState({winelist : w});
                       }}/>
            </FormGroup>
            <p>{`Max 160 tecken. Just nu: ${(winelist && winelist.description && winelist.description.length) || 0} `}</p>
        </TabPane>
    );
};