export default function resultOrNull<T>(action: () => Promise<T>): Promise<T | null> {
    return new Promise<T | null>(async (resolve) => {
        try {
            resolve(await action());
        } catch (_error) {
            resolve(null);
        }
    });
}

export function resultOrUndefined<T>(action: () => Promise<T>): Promise<T | undefined> {
    return new Promise<T | undefined>(async (resolve) => {
        try {
            resolve(await action());
        } catch (_error) {
            resolve(undefined);
        }
    });
}