import {
    Any,
    JsonConvert,
    JsonConverter,
    JsonCustomConvert,
    JsonObject,
    JsonProperty,
    ValueCheckingMode
} from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';
import { Ownable } from './Ownable';
import DateConverter from './DateConverter';
import SystembolagetStore from './SystembolagetStore';
import { buildWebPath } from '../vinkompassen-config';
import { createProfileLink } from '../Routes';

export type UserRoles = 'administrator' | 'publiclist' | 'profile' | 'theme' | 'wineclub';
export const userAdminRole = 'administrator';
export const userPublicListRole = 'publiclist';
export const userProfileRole = 'profile';
export const userThemeRole = 'theme';
export const userWineClubRole = 'wineclub';

@JsonObject
export default class User extends Assignable<User> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('email', String, true)
    email?: string = undefined;

    @JsonProperty('firstName', String, true)
    firstName?: string = undefined;

    @JsonProperty('lastName', String, true)
    lastName?: string = undefined;

    @JsonProperty('fullName', String, true)
    fullName?: string = undefined;

    @JsonProperty('company', Any, true)
    company?: string = undefined;

    @JsonProperty('active', Boolean, true)
    active?: boolean = true;

    @JsonProperty('emailVerified', Boolean, true)
    emailVerified?: boolean = true;

    @JsonProperty('created', DateConverter, true)
    created?: Date = undefined;

    @JsonProperty('lastLogin', DateConverter, true)
    lastLogin?: Date = undefined;

    @JsonProperty('lastUpdate', DateConverter, true)
    lastUpdate?: Date = undefined;

    @JsonProperty('imageUrl', Any, true)
    imageUrl?: string = undefined;

    @JsonProperty('imageId', Any, true)
    imageId?: string = undefined;

    @JsonProperty('largeImageUrl', Any, true)
    largeImageUrl?: string = undefined;

    @JsonProperty('largeImageId', Any, true)
    largeImageId?: string = undefined;

    @JsonProperty('description', Any, true)
    description?: string = undefined;

    @JsonProperty('followers', Any, true)
    followers?: number = 0;

    @JsonProperty('publicLists', Any, true)
    publicLists?: number = 0;

    @JsonProperty('websiteUrl', String, true)
    websiteUrl?: string = undefined;

    @JsonProperty('password', String, true)
    password?: string = undefined;

    @JsonProperty('favoriteStores', [String], true)
    favoriteStores?: string[] = [];

    @JsonProperty('isPrivate', Boolean, true)
    isPrivate?: boolean = false;

    get initials(): string {
        if (this.firstName && this.lastName) {
            return this.firstName.substr(0, 1).toLocaleUpperCase() +
                this.lastName.substr(0, 1).toLocaleUpperCase();
        }
        if (this.fullName) {
            return this.fullName.split(' ').map(x => x[0]).join('');
        }

        return '';
    }

    get isAdministrator(): boolean {
        return this.isRole(userAdminRole);
    }

    @JsonProperty('roles', [String], true)
    roles?: string[] = [];

    isFavoriteStore(store: SystembolagetStore): boolean {
        return (this.favoriteStores || []).indexOf(store.id!) !== -1;
    }

    isRole(role: UserRoles): boolean {
        return hasRole(this, role);
    }

    addRole(role: UserRoles): void {
        if (!this.isRole(role)) {
            if (this.roles) {
                this.roles.push(role);
            } else {
                this.roles = [role];
            }
        }
    }

    removeRole(role: UserRoles): void {
        let roleSet = new Set(this.roles || []);
        roleSet.delete(role);
        this.roles = Array.from(roleSet.values());
    }

    static fromJson(data: any): User {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, User) as User;
    }

    shareUrl(): string {
        return buildWebPath(createProfileLink(this));
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

    isOwner(user: User): boolean {
        return this.id === user.id;
    }

    get isProfile(): boolean {
        return this.isRole(userProfileRole);
    }

    get isTheme(): boolean {
        return this.isRole(userThemeRole);
    }

    get isWineClub(): boolean {
        return this.isRole(userWineClubRole);
    }

    get isListCreator(): boolean {
        return this.isRole(userPublicListRole);
    }
}

export class Users {
    [key: string]: User;
}

@JsonConverter
export class UsersConverter implements JsonCustomConvert<Users> {
    serialize(users: Users): any {
        return users;
    }

    deserialize(users: any): Users {
        let jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        const items = new Users();
        for (const key in users) {
            if (users.hasOwnProperty(key)) {
                items[key] = jsonConvert.deserializeObject(users[key], User);
            }
        }

        return items;
    }
}

export function hasRole(user: User, role: UserRoles): boolean {
    return (user.roles && user.roles.indexOf(role) !== -1) || false;
}