import * as React from 'react';
import '../../../styles/PairingsIcon.scss';

export function showWinePairing(pairing: string): boolean {
    return [
        'Buffémat',
        'Fågel',
        'Fläsk',
        'Lamm',
        'Nöt',
        'Vilt',
        'Fisk',
        'Skaldjur',
        'Grönt',
        'Asiatisk',
        'Kryddig',
        'Ost',
        'Dessert',
        'Mingel'
    ].indexOf(pairing) !== -1;
}

export function showRecipePairing(pairing: string): boolean {
    return [
        'Buffémat',
        'Fågel',
        'Fläsk',
        'Lamm',
        'Nöt',
        'Vilt',
        'Fisk',
        'Skaldjur',
        'Grönt',
        'Asiatiskt',
        'Kryddig',
        'Ost',
        'Dessert',
        'Aperitif'
    ].indexOf(pairing) !== -1;
}

export function pairingIconImage(pairing: string): string | undefined {
    switch (pairing) {
        case 'Fågel':
            return require('./images/fowl-icon.svg');
        case 'Fläsk':
            return require('./images/pork-icon.svg');
        case 'Lamm':
            return require('./images/lamb-icon.svg');
        case 'Nöt':
            return require('./images/beef-icon.svg');
        case 'Vilt':
            return require('./images/game-icon.svg');
        case 'Fisk':
        case 'Fet fisk':
            return require('./images/fish-icon.svg');
        case 'Skaldjur':
            return require('./images/shellfish-icon.svg');
        case 'Vego':
        case 'Grönt':
            return require('./images/vego-icon.svg');
        case 'Grill':
        case 'Grillat':
        case 'Buffémat':
            return require('./images/buffet-icon.svg');
        case 'Asiatiskt':
        case 'Asiatisk':
            return require('./images/asian-icon.svg');
        case 'Kryddstarkt':
        case 'Kryddstark':
        case 'Kryddig':
        case 'Kryddigt':
            return require('./images/spicy-icon.svg');
        case 'Mild ost':
        case 'Ost':
            return require('./images/cheese-icon.svg');
        case 'Dessert':
            return require('./images/dessert-icon.svg');
        case 'Aperitif':
            return require('./images/aperitif-icon.svg');
        case 'Mingel':
        case 'Sällskap':
            return require('./images/mingle-icon.svg');

        default:
            return undefined;
    }
}

type Props = {
    pairing: string;
};
export const PairingsIcon = (prop: Props) => {
    const {pairing} = prop;

    return (
        <div className={'icon-pairings'}>
            <figure className={'icon-pairings--container'}>
                <img className={'icon-pairings--image'}
                     src={pairingIconImage(pairing)}
                     alt={pairing}/>
            </figure>
            <span className={'icon-pairings--label'}>{pairing}</span>
        </div>
    );
};
