import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Routes from './Routes';
import AuthUserContext from './AuthUserContext';
import users from './services/UserService';
import User from './models/User';
import withAuthentication, { AuthenticationProps } from './WithAuthentication';

type AuthCondition = (appUser: User | null) => boolean;

export const isAuthenticated: AuthCondition = (user) => !!user;

const withAuthorization = (condition: AuthCondition) => (Component: React.ComponentType<any & AuthenticationProps>) => {
    class WithAuthorization extends React.Component<RouteComponentProps, any> {
        async componentDidMount() {
            let hasAccess = false;
            try {
                try {
                    const appUser = await users.current();
                    hasAccess = condition(appUser);
                } catch (error) {
                    hasAccess = condition(null);
                }
            } catch (error) {
                hasAccess = condition(null);
            }

            if (!hasAccess) {
                this.props.history.push(Routes.UNAUTHORIZED);
            }
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser => authUser && (
                        <Component {...this.props} authUser={authUser.user}/>
                    )}
                </AuthUserContext.Consumer>
            );
        }
    }

    return withRouter(withAuthentication(WithAuthorization));
}

export default withAuthorization;
