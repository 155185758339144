import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Routes from './Routes';
import withAuthentication, { AuthenticationProps } from './WithAuthentication';
import HamburgerMenu from './components/hamburger-menu/HamburgerMenu';

interface Props extends RouteComponentProps, AuthenticationProps {
    path:string
}

interface State {
    isMenuOpen: boolean;
}

class Navigation extends React.Component<Props, State> {
    
    
    constructor(props: Props) {
        super(props);
        this.state = {
            isMenuOpen: false
        };
    }

    isActive = (routes: string[]): boolean => {
        const {location} = this.props;
        if (routes.length === 1 && routes[0] === Routes.HOME) {
            return location.pathname === routes[0];
        }

        return routes.findIndex(route => location.pathname.startsWith(route)) !== -1;
    }

    toggleMenu = () => {
        this.setState(prevState => ({isMenuOpen: !prevState.isMenuOpen}));
    }

    render() {
        // const {authUser} = this.props;
        const {isMenuOpen} = this.state;

        // Fishy bug! If the siblings for navigations has the same tag names, react seems to break it by
        // making duplicates of the same tag. Therefore this is a <nav> and the account nav is a <span>
        return (
            <React.Fragment>
                <HamburgerMenu onClick={() => this.toggleMenu()} isActive={isMenuOpen}/>
                <nav key={'main-navigation'}
                     className={`App-nav App-nav-main ${(isMenuOpen) ? 'open' : ''}`}
                     onClick={() => this.toggleMenu()}>
                    <Link  to={Routes.HOME} className={this.isActive([Routes.HOME]) ? 'active' : ''}>Start</Link>
                    {/* <Link to={Routes.WINE_SEARCH} className={this.isActive([Routes.WINE_SEARCH]) ? 'active' : ''}>Sök
                        vin</Link> */}
                    <Link to={Routes.MEMBERS}
                          className={this.isActive([Routes.MEMBERS]) ? 'active' : ''}>Medlemmar</Link>
                    <Link to={Routes.WINELIST_INDEX}
                          className={this.isActive([Routes.WINELIST_INDEX, Routes.THEMES_INDEX]) ? 'active' : ''}>Vinlistor</Link>
                    <Link to={Routes.MYVINOS_START}
                          className={this.isActive([Routes.MYVINOS_START]) ? 'active' : ''}>Mitt Vinkompassen</Link>
                    {/*{(authUser && authUser.isListCreator) &&*/}
                    {/*<Link to={Routes.PUBLIC}*/}
                    {/*      className={this.isActive([Routes.PUBLIC]) ? 'active' : ''}>Publikt</Link>*/}
                    {/*}*/}
                </nav>
            </React.Fragment>
        );
    }
}

export default withRouter(withAuthentication(Navigation));
