import * as React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { LayoutProps } from '../LayoutHelpers';
import { Col, Container, Row } from 'reactstrap';
import Button from '../components/buttons/Button';
import { ListIcon, ProfileIcon, ThemeIcon } from '../components/icons/Icons';
import Routes from '../Routes';
import { AuthenticationProps } from '../WithAuthentication';
import AsideButtonSpacer from '../components/spacers/AsideButtonSpacer';
import AccountAndAppSign from '../components/account-and-app-sign/AccountAndAppSign';
import StandaloneSearchBox from '../search/StandaloneSearchBox';
import { scrollToContent } from '../helpers/ScrollToContent';

interface Props extends LayoutProps<any>, RouteComponentProps, AuthenticationProps {

}

class WinelistsLayout extends React.PureComponent<Props> {
    onNavigate = (route: string) => {
        const {history} = this.props;

        scrollToContent('winelists-content');

        history.push(route);

    }

    render() {
        const {component: Component, location, history, authUser, ...rest} = this.props;
        const pathname = location.pathname;

        return (
            <Route {...rest} render={matchProps => (
                <Container className={'winelist-layout'}>
                    <Row>
                        {/* Aside */}
                        <Col
                            className={'aside'}
                            tag={'aside'}
                            xl={{size: 3, offset: 1}} 
                            lg={{size: 4, offset: 0}} 
                            md={{size: 5, offset: 0}}
                            sm={{size: 6, order: 1}}
                            xs={{order: 2}}>

                            {/* <StandaloneSearchBox/> */}
                            <div className={'aside--box col-background'}>
                                <h3>Profiler</h3>
                                <Button
                                    theme={(pathname === Routes.WINELIST_INDEX) ? 'orange' : 'white'}
                                    textAlign={'left'}
                                    icon={<ListIcon/>}
                                    useCaret={true}
                                    label={'Alla listor från profiler'}
                                    onClick={() => this.onNavigate(Routes.WINELIST_INDEX)}/>

                                <AsideButtonSpacer/>

                                <Button
                                    theme={(pathname === Routes.WINELIST_PROFILES) ? 'orange' : 'white'}
                                    textAlign={'left'}
                                    icon={<ProfileIcon/>}
                                    useCaret={true}
                                    label={'Alla profiler'}
                                    onClick={() => this.onNavigate(Routes.WINELIST_PROFILES)}/>

                            </div>

                            <div className={'aside--box col-background'}>
                                <h3>Teman</h3>
                                <Button
                                    theme={(pathname === Routes.THEMES_LISTS) ? 'orange' : 'white'}
                                    textAlign={'left'}
                                    icon={<ListIcon/>}
                                    useCaret={true}
                                    label={'Alla temalistor'}
                                    onClick={() => this.onNavigate(Routes.THEMES_LISTS)}/>

                                <AsideButtonSpacer/>

                                <Button
                                    theme={(pathname === Routes.THEMES_INDEX) ? 'orange' : 'white'}
                                    textAlign={'left'}
                                    icon={<ThemeIcon/>}
                                    useCaret={true}
                                    label={'Alla teman'}
                                    onClick={() => this.onNavigate(Routes.THEMES_INDEX)}/>

                            </div>
                            <AsideButtonSpacer space={30}/>
                            <AccountAndAppSign />
                        </Col>
                        {/* main content */}
                        <Col xl={{size: 6, offset: 1}} 
                            lg={{size: 6, offset: 1}} 
                            md={{size: 7, offset: 0}}
                            sm={{size: 6, offset: 0, order: 2}}
                            xs={{order: 1}}
                            id={'winelists-content'}>
                            <Component {...matchProps} {...rest} />
                        </Col>
                    </Row>
                </Container>
            )}/>
        );
    }
}

export default withRouter<Props, React.ComponentType<Props>>(WinelistsLayout);
